import { MOVEMENT_TYPES, useCreateExpenseMovement } from '@expane/data'
import { convertUnitValueToServer } from '@expane/logic/product'
import { Button, CloseButton, Dialog, Modal, Textarea } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'
import {
  MovementDialogsProps,
  ProductDto,
} from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'
import { WriteOffMovementInfo } from 'widgets/MovementCreateDialogs/WriteOffMovementDialog/WriteOffMovementInfo'
import { WriteOffMovementProductsList } from 'widgets/MovementCreateDialogs/WriteOffMovementDialog/WriteOffMovementProductsList'

export interface WriteOffMovementProps {
  control: Control<WriteOffMovementFormValues>
}

export interface WriteOffMovementFormValues {
  fromStorageId: number
  movementNumber: string
  description: string
  products: ProductDto[]
  inventoryId?: number
}

export const WriteOffMovementDialog: FC<MovementDialogsProps> = observer(
  ({
    closeParentDialog,
    closeDialog,
    productsForDefaultFormValues,
    storageId,
    movementNumber,
    inventoryId,
  }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()

    const { control, handleSubmit, formState } = useForm<WriteOffMovementFormValues>({
      defaultValues: {
        fromStorageId: storageId,
        products: productsForDefaultFormValues,
        movementNumber: movementNumber.toString(),
      },
    })

    const { confirmPopup, closePopups } = useShowPopupOnDirtyFormClose(formState, closeDialog)

    const [openSnackBar] = useSnackbar()

    const { mutateAsync: createWriteOffMovement } = useCreateExpenseMovement()

    const mutateMovement: SubmitHandler<WriteOffMovementFormValues> = async data => {
      if (!branchId) {
        openSnackBar(t('submitError'), 'error', 3000)
        closeDialog()
        if (closeParentDialog) closeParentDialog()

        return
      }

      const { fromStorageId, products, movementNumber, description } = data

      try {
        await createWriteOffMovement({
          type: MOVEMENT_TYPES.writeOff.id,
          fromStorageId,
          fulfilled: true,
          movementProducts: {
            data: products.map(p => ({
              productId: p.id,
              price: p.costPrice,
              quantity: convertUnitValueToServer(p.quantity, p.unit),
            })),
          },
          number: movementNumber,
          description,
          inventoryId,
          branchId,
        })

        openSnackBar(t('writeOff.success'), 'success', 3000)
      } catch (e) {
        openSnackBar(t('submitError'), 'error', 3000)
        reportError(new Error('Error while write-off'), 'error', { data })
      }

      closeDialog()
      if (closeParentDialog) closeParentDialog()
    }

    return (
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting) handleSubmit(mutateMovement)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('writeOff.name')}</Dialog.Title>

          <Dialog.Body className="w-192">
            <WriteOffMovementInfo control={control} />
            <WriteOffMovementProductsList control={control} />

            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <Textarea
                  containerClassName="mt-2"
                  rows={2}
                  label={t('note')}
                  placeholder={t('placeholders.writeOffMovementReason')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={handleSubmit(mutateMovement)}
              disabled={formState.isSubmitting}
              spinner={formState.isSubmitting}
            >
              {t('writeOff.checkout')}
            </Button>
            <CloseButton onClick={closePopups} />
          </Dialog.Footer>
        </Dialog>
        {confirmPopup}
      </Modal>
    )
  },
)
