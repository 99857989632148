import { SelectDropDownItem } from '@expane/ui'
import {
  ServerTimeOffReasonEmployeeGroupInsertInput,
  ServerTimeOffReasonInsertInput,
  ServerTimeOffReasonType,
  ServerTimeOffReasonUpdates,
} from '@expane/data'

export interface TimeOffReasonItemInForm {
  id?: number
  name: string
  employeeGroups: SelectDropDownItem[]
  editable: boolean
  updatable: boolean
}

export interface TimeOffReasonFormValues {
  items: TimeOffReasonItemInForm[]
}

export const generateDefaultTimeOffReasonFormValues = (
  items: ServerTimeOffReasonType[],
): TimeOffReasonItemInForm[] => {
  const defaultItems: TimeOffReasonItemInForm[] = []

  for (const item of items) {
    defaultItems.push({
      id: item.id,
      name: item.name,
      employeeGroups: item.timeOffReasonEmployeeGroups.map(timeOffReasonEmployeeGroup => ({
        id: timeOffReasonEmployeeGroup.employeeGroupId,
        name: timeOffReasonEmployeeGroup.employeeGroup.name,
      })),
      editable: false,
      updatable: false,
    })
  }

  return defaultItems
}

export const generateTimeOffReasonsDataForMutation = (
  data: TimeOffReasonFormValues,
  areEditTimeOffReasonsEmployeeGroupsEnabled: boolean,
) => {
  const timeOffReasonInsertInputs: ServerTimeOffReasonInsertInput[] = []
  const timeOffReasonEmployeeGroupInsertInputs: ServerTimeOffReasonEmployeeGroupInsertInput[] = []
  const timeOffReasonUpdates: ServerTimeOffReasonUpdates[] = []
  const timeOffReasonIdsForDeleteEmployeeGroups: number[] = []

  for (const item of data.items) {
    if (!item.id) {
      timeOffReasonInsertInputs.push({
        name: item.name,
        ...(areEditTimeOffReasonsEmployeeGroupsEnabled
          ? {
              timeOffReasonEmployeeGroups: {
                data: item.employeeGroups.map(employeeGroup => ({
                  employeeGroupId: employeeGroup.id,
                })),
              },
            }
          : {}),
      })

      continue
    }

    if (item.updatable) {
      if (areEditTimeOffReasonsEmployeeGroupsEnabled) {
        timeOffReasonIdsForDeleteEmployeeGroups.push(item.id)
        timeOffReasonEmployeeGroupInsertInputs.push(
          ...item.employeeGroups.map(employeeGroup => ({
            employeeGroupId: employeeGroup.id,
            timeOffReasonId: item.id,
          })),
        )
      }

      timeOffReasonUpdates.push({
        where: { id: { _eq: item.id } },
        _set: {
          name: item.name,
        },
      })
    }
  }

  return {
    timeOffReasonInsertInputs,
    timeOffReasonEmployeeGroupInsertInputs,
    timeOffReasonUpdates,
    timeOffReasonIdsForDeleteEmployeeGroups,
    includeMutateTimeOffReasonEmployeeGroups: areEditTimeOffReasonsEmployeeGroupsEnabled,
  }
}
