import { ServerAccountType, useGetBranchDefaultAccountId } from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { checkWithdrawPossibility } from '@expane/logic/form'
import {
  calcArrivalMovementTotalPrice,
  calcTotalMovementProductsCostPrice,
} from '@expane/logic/movement'
import { Input, InputLabel, NumberInput, SelectDropdown, Textarea } from '@expane/ui'
import { FC } from 'react'
import { Controller, UseFormResetField, UseFormSetValue, useWatch } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import {
  ArrivalMovementFormValues,
  ArrivalMovementProps,
} from 'widgets/MovementCreateDialogs/ArrivalMovementDialog'
import { Checkbox } from '@expane/ui'
import { PercentIcon } from 'ui/Icons'
import { MAX_PERCENT_DISCOUNT } from '@expane/logic/utils'

interface MovementPaymentProps extends ArrivalMovementProps {
  accounts: ServerAccountType[]
  setValue: UseFormSetValue<ArrivalMovementFormValues>
  resetField: UseFormResetField<ArrivalMovementFormValues>
}

export const ArrivalMovementPayment: FC<PropsWithBranchId<MovementPaymentProps>> = ({
  control,
  accounts,
  branchId,
  setValue,
  resetField,
}) => {
  const { t } = useTranslation()

  const { data: defaultAccountId } = useGetBranchDefaultAccountId(branchId)

  const convertToMoney = useConvertNumberToMoneyCode({ branchId })

  const products = useWatch({ control, name: 'products' })
  const discount = useWatch({ control, name: 'discount' })
  const transportCosts = useWatch({ control, name: 'transportCosts' })
  const watchedAccountId = useWatch({ control, name: 'fromAccountId' })

  const totalPrice = calcArrivalMovementTotalPrice({
    amount: calcTotalMovementProductsCostPrice(products),
    discount,
  })

  const paymentNow = useWatch({ control, name: 'payImmediately' })

  return (
    <div className="flex mt-2">
      <Controller
        control={control}
        name="description"
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <Textarea
            label={t('comment.title')}
            containerClassName="mr-3 w-1/3"
            rows={2}
            placeholder={t('placeholders.productGroupDescription')}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <div className="w-2/3">
        <div className="flex items-center justify-end">
          <div className="w-1/3 mr-2">
            <InputLabel label={t('transactionNames.transportCosts')} />
            <Controller
              control={control}
              name="transportCosts"
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  onChange={value => onChange(Number(value))}
                  value={value.toString()}
                  errorMessage={{
                    text: '',
                    isShown: false,
                    reserveIndent: true,
                  }}
                />
              )}
            />
          </div>

          <div className="w-1/3 mr-2">
            <InputLabel label={t('discount')} />

            <Controller
              name="discount"
              control={control}
              rules={{
                validate: value => (value ? Number(value) <= MAX_PERCENT_DISCOUNT : true),
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <NumberInput
                  Icon={PercentIcon}
                  value={value}
                  onChange={onChange}
                  errorMessage={{
                    isShown: Boolean(error),
                    text: t('maxDiscountError'),
                    reserveIndent: true,
                  }}
                />
              )}
            />
          </div>

          <div className="w-1/3">
            <Input
              disabled
              label={t('totalAmount')}
              value={convertToMoney(totalPrice + (Number(transportCosts) || 0))}
              errorMessage={{
                text: '',
                isShown: false,
                reserveIndent: true,
              }}
              readOnly
            />
          </div>
        </div>

        <div className="flex items-end justify-end">
          <div className="mr-auto mb-4">
            <Controller
              control={control}
              name="fulfilled"
              render={({ field: { value, onChange } }) => (
                <Checkbox label={t('arrival.fulfilled')} onChange={onChange} checked={value} />
              )}
            />
            <Controller
              control={control}
              name="payImmediately"
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label={t('payImmediately')}
                  onChange={e => {
                    const isChecked = e.target.checked

                    // если счёт по умолчанию без привязки к РРО то используем его,
                    // иначе первый в списке
                    const accountToSet =
                      accounts.find(account => account.id === defaultAccountId)?.id ??
                      accounts[0]?.id

                    onChange(isChecked)
                    if (isChecked) setValue('fromAccountId', accountToSet)
                    else resetField('fromAccountId', { defaultValue: undefined })
                  }}
                  checked={value}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name="fromAccountId"
            rules={{
              required: paymentNow,
              validate: value => {
                if (!value || !paymentNow) return true

                const total = totalPrice + (Number(transportCosts) ?? 0)

                return checkWithdrawPossibility({
                  currentAccount: getCurrentAccount(accounts, value),
                  value: total,
                })
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectDropdown
                className="w-1/3 pl-1"
                items={accounts}
                label={t('account.name')}
                required={paymentNow}
                onSelectChange={onChange}
                value={value}
                disabled={!paymentNow}
                errorMessage={{ isShown: Boolean(error), text: getMessageByError(error?.type, t) }}
              />
            )}
          />
          <Controller
            control={control}
            name="amount"
            rules={{
              required: paymentNow,
              validate: value => {
                if (Number(value) <= 0 && paymentNow) return false

                return checkWithdrawPossibility({
                  currentAccount: getCurrentAccount(accounts, watchedAccountId),
                  value: Number(value),
                })
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberInput
                label={t('amount')}
                required={paymentNow}
                value={value}
                onChange={onChange}
                containerClassName="ml-2 w-1/3"
                disabled={!paymentNow}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

const getMessageByError = (errorType: string | undefined, t: TFunction) => {
  if (errorType === 'validate') return t('formError.insufficientFunds')
  if (errorType === 'required') return t('formError.required')
}
