import { useFetchMyBusinesses } from '@expane/data'
import { useFetchPlans } from '@expane/data/src/gql/plan'
import { currencyItems } from '@expane/logic/currency'
import { ModuleSettingsForm } from '@expane/logic/modules'
import { checkValidPhone } from '@expane/logic/phone'
import { DEFAULT_TIMEZONE, getTimezoneById, getTimezoneByName } from '@expane/date'
import { useWebPersistedState } from '@expane/web-logic/useWebPersistedState'
import { Input, Paper, SelectDropdown } from '@expane/ui'
import { showPhoneErrorMessage } from 'logic/form'
import { observer } from 'mobx-react-lite'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage'
import {
  labelStyle,
  MAIN_INFO_STORAGE_KEY,
  MODULES_STORAGE_KEY,
  stepStyle,
} from 'pages/CreateBusinessPage/logic'
import { useOpenPlansDialog } from 'pages/CreateBusinessPage/QuickSteps/MainInfoStep/PlansDialog'
import React, { FC, useEffect } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoChevronBackSharp } from 'react-icons/io5'
import { firebase } from 'services/firebase'
import { store } from 'store'
import { PhoneInput } from 'ui/PhoneInput'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { TimezonesSelect } from 'widgets/TimezonesSelect'
import { BillingInfo } from './BillingInfo'
import { PromoCode } from './PromoCode'
import { MainInfoFormValues } from '@expane/logic/business'
import { PLACEHOLDERS } from '@expane/logic/form'

export const MainInfoStep: FC = observer(() => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const { t } = useTranslation()

  const { storage, updateStorage, clearPersistedStorage } =
    useWebPersistedState<MainInfoFormValues>(MAIN_INFO_STORAGE_KEY, reportError)

  const { clearPersistedStorage: clearPersistedModulesStorage } =
    useWebPersistedState<ModuleSettingsForm>(MODULES_STORAGE_KEY, reportError)

  const {
    handleSubmit,
    control,
    setValue,
    // TODO: get error inside of Controller's render func
    formState: { errors },
  } = useForm<MainInfoFormValues>({
    defaultValues: getDefaultValues(storage),
  })

  const { data: myBusinesses } = useFetchMyBusinesses(store.me.isAuthorised)
  const { data: plans } = useFetchPlans()

  const { openPlansDialog, plansDialog } = useOpenPlansDialog(control)

  // For saving data in storage
  const watchedBusinessName = useWatch({ control, name: 'businessName' })
  const watchedCurrencyId = useWatch({ control, name: 'currencyId' })
  const watchedFirstName = useWatch({ control, name: 'ownerFirstName' })
  const watchedLastName = useWatch({ control, name: 'ownerLastName' })
  const watchedOwnerPhone = useWatch({ control, name: 'ownerPhone' })
  const watchedPhoto = useWatch({ control, name: 'photo' })
  const watchedPlanId = useWatch({ control, name: 'planId' })
  const watchedTimezoneId = useWatch({ control, name: 'timezoneId' })
  const watchedPromoCode = useWatch({ control, name: 'promoCode' })
  const watchedBranchPhone = useWatch({ control, name: 'branchPhone' })
  const watchedBranchAddress = useWatch({ control, name: 'branchAddress' })

  useEffect(() => {
    updateStorage({
      businessName: watchedBusinessName,
      currencyId: watchedCurrencyId,
      ownerFirstName: watchedFirstName,
      ownerLastName: watchedLastName,
      ownerPhone: watchedOwnerPhone,
      photo: watchedPhoto,
      planId: watchedPlanId,
      timezoneId: watchedTimezoneId,
      branchPhone: watchedBranchPhone,
      branchAddress: watchedBranchAddress,
    })
  }, [
    updateStorage,
    watchedBranchAddress,
    watchedBranchPhone,
    watchedBusinessName,
    watchedCurrencyId,
    watchedFirstName,
    watchedLastName,
    watchedOwnerPhone,
    watchedPhoto,
    watchedPlanId,
    watchedTimezoneId,
  ])

  // TODO: Move to event handler
  useEffect(() => {
    if (myBusinesses?.find(business => business.ownerId === store.me.employeeId)) {
      clearPersistedStorage()
      clearPersistedModulesStorage()
      proceedToNextStep({ twice: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clearPersistedModulesStorage,
    clearPersistedStorage,
    myBusinesses,
    proceedToNextStep,
    store.me.employeeId,
  ])

  const currentPlan = plans?.find(({ id }) => id === watchedPlanId)

  const submitUpdateStorageBeforeNextStep: SubmitHandler<MainInfoFormValues> = async data => {
    updateStorage(data)
    proceedToNextStep()
  }

  return (
    <div className={'flex items-end relative'}>
      <Paper className={stepStyle + ' h-144 w-160'}>
        <>
          <p className={labelStyle}>{t('createBusiness.businessOwner')}</p>

          <div className="flex gap-2">
            <Controller
              control={control}
              name="ownerFirstName"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('firstName')}
                  placeholder={t('placeholders.firstName')}
                  required
                  errorMessage={{
                    isShown: Boolean(errors.ownerFirstName),
                    text: t('formError.required'),
                  }}
                  containerClassName="w-1/3"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="ownerLastName"
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('lastName')}
                  placeholder={t('placeholders.lastName')}
                  containerClassName="w-1/3"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              name="ownerPhone"
              control={control}
              rules={{ validate: value => (value ? checkValidPhone(value) : true) }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  containerClassName="w-1/3"
                  label={t('phone')}
                  placeholder={PLACEHOLDERS.phone}
                  errorMessage={{
                    isShown: Boolean(error),
                    text: showPhoneErrorMessage(error?.type ?? '', t),
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <div className="flex -mt-1">
            <div className="w-2/3 mr-2">
              <p className={labelStyle}>{t('menu.business.title')}</p>

              <Controller
                control={control}
                name="businessName"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    containerClassName="w-full"
                    label={t('name')}
                    placeholder={t('placeholders.businessName')}
                    errorMessage={{
                      isShown: Boolean(errors.businessName),
                      text: t('formError.required'),
                    }}
                    required
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div className="w-1/3 mx-auto -mb-4">
              <Controller
                name="photo"
                control={control}
                render={({ field: { onChange } }) => (
                  <EditablePhoto onChange={onChange} containerClassName="flex justify-center" />
                )}
              />
            </div>
          </div>

          <p className={labelStyle}>{t('branch.name')}</p>

          <div className="flex gap-2">
            <Controller
              control={control}
              name="currencyId"
              rules={{ required: true }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <SelectDropdown
                  label={t('currency')}
                  value={value}
                  onSelectChange={onChange}
                  items={currencyItems}
                  className="w-1/3"
                  required
                  errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                />
              )}
            />

            <Controller
              control={control}
              name="timezoneId"
              rules={{
                required: true,
              }}
              render={({ field: { value, onChange } }) => (
                <TimezonesSelect
                  value={value}
                  onSelectChange={onChange}
                  containerClassName="grow"
                  className="w-full"
                  errorMessage={{
                    isShown: Boolean(errors.timezoneId),
                    text: t('formError.required'),
                    reserveIndent: true,
                  }}
                  required
                />
              )}
            />
          </div>

          <div className="flex gap-2">
            <Controller
              control={control}
              name="branchPhone"
              rules={{ validate: value => (value ? checkValidPhone(value) : true) }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <PhoneInput
                  containerClassName="w-1/3"
                  label={t('branchPhone')}
                  placeholder={PLACEHOLDERS.phone}
                  errorMessage={{
                    isShown: Boolean(error),
                    text: showPhoneErrorMessage(error?.type ?? '', t),
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name="branchAddress"
              render={({ field: { value, onChange } }) => (
                <Input
                  containerClassName="grow"
                  label={t('branchAddress')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <p className={labelStyle}>{t('billingInfo')}</p>
          <BillingInfo
            timezone={getTimezoneById(watchedTimezoneId)?.name ?? DEFAULT_TIMEZONE}
            plan={currentPlan}
            onClick={openPlansDialog}
            watchedPromoCode={watchedPromoCode}
          />

          <PromoCode
            onCheck={promoCode => setValue('promoCode', promoCode)}
            watchedPromoCode={watchedPromoCode}
          />
        </>
      </Paper>

      <button
        className="absolute bottom-0 left-0 -translate-x-full flex pr-4 items-center text-lg text-primary-500 hover:text-primary-700"
        onClick={firebase.signOut}
      >
        <IoChevronBackSharp size="1.2rem" className="mr-1" /> {t('signOut')}
      </button>

      <ProceedToNextStep onClick={handleSubmit(submitUpdateStorageBeforeNextStep)} />
      {plansDialog}
    </div>
  )
})

const DEFAULT_PLAN_ID = 3 // 3 - Unlimited

const getDefaultValues = (storage: MainInfoFormValues | undefined) => {
  const businessName = storage?.businessName ?? ''
  const ownerFirstName = storage?.ownerFirstName ?? ''
  const ownerLastName = storage?.ownerLastName ?? ''
  const ownerPhone = storage?.ownerPhone ?? ''
  const timezoneId = storage?.timezoneId ?? getTimezoneByName('Europe/Kyiv')?.id
  const planId = storage?.planId ?? DEFAULT_PLAN_ID
  const currencyId = storage?.currencyId
  const photo = storage?.photo
  const branchPhone = storage?.branchPhone ?? ''
  const branchAddress = storage?.branchAddress ?? ''

  return {
    businessName,
    ownerFirstName,
    ownerLastName,
    ownerPhone,
    timezoneId,
    planId,
    currencyId,
    photo,
    branchPhone,
    branchAddress,
  }
}
