import { FC } from 'react'
import { CloseButton, CommonPlaceholderDialogProps, Dialog, Spinner } from '@expane/ui'
import { SaveButton } from 'widgets/Buttons'
import { useTranslation } from 'react-i18next'

export const TimeOffReasonDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Dialog.Title>{t('timeOffReason.name')}</Dialog.Title>

      <Dialog.Body className="h-68">
        <div className="w-200">
          <Spinner expandCentered />
        </div>
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton disabled />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
