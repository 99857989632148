import {
  ExtendedEmployee,
  ServerLocationWithServiceIdsType,
  useFetchBookingsOfDate,
  useFetchCurrentBranchTimezone,
  WeeklySchedule,
} from '@expane/data'
import {
  addHours,
  createCurrentDate,
  DEFAULT_TIMEZONE,
  formatDayMonth,
  getTimeFromDate,
  isDateBefore,
  isSameDay,
  isToday as getIsToday,
  setTimeForDateByFloat,
} from '@expane/date'
import { checkDayOff, defineDayTiming, getEmployeeTimeOffByDay } from '@expane/logic/calendar'
import { permissions } from '@expane/logic/permission'
import { transformPersonName } from '@expane/logic/utils'
import { SpinnerSmall, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import i18next from 'i18next'
import { ContextMenuItem } from 'logic/hooks/useContextMenu'
import { useDebounce } from 'logic/hooks/useDebounce'
import { TAILWIND_TO_REM_RATIO } from 'logic/ui'
import { observer } from 'mobx-react-lite'
import { CalendarTimeOffCard } from 'pages/BookingsPage/BookingsCalendar/TimeOffCard'
import { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import {
  CalendarColumn,
  CalendarTitleCell,
  CELL_HEIGHT,
  SMALL_CELL_HEIGHT,
  TALL_TITLE_CELL_HEIGHT,
  TITLE_CELL_HEIGHT,
} from 'ui/Calendar'
import { useOpenBookingDialog } from 'widgets/BookingDialog'
import { useOpenBookingMultiServiceDialog } from 'widgets/BookingMultiServicesDialog'
import { CalendarCellWithPlanBillingCheck } from 'widgets/CalendarCellWithPlanBillingCheck'
import { useOpenGroupBookingDialog } from 'widgets/GroupBookingDialog'
import { useOpenTimeOffDialog } from 'widgets/TimeOffDialog'
import { BookingCard } from '../BookingCard'
import { CalendarTimeLine } from '../CalendarTimeComponents'
import { GhostBookingCard } from '../GhostBookingCard'
import {
  CardsType,
  getCellWarningMessage,
  getWarningsForCell,
  GetWarningsForCellArgs,
  markPositionForCards,
} from '../logic'
import { UnitedBookingUpdateCard } from '../UnitedBookingUpdateCard'
import {
  BOOKING_DIALOG_CONTEXT_MENU_ID,
  filterBookingsForEmployeeColumn,
  getCalendarColumnClassName,
  getCellClassName,
  getTopPositionForBooking,
  GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID,
  MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID,
  TIMEOFF_DIALOG_CONTEXT_MENU_ID,
} from './logic'

interface EmployeeColumnProps {
  employee: ExtendedEmployee
  employees: ExtendedEmployee[]
  locationsFromFilter: ServerLocationWithServiceIdsType[]
  branchSchedule: WeeklySchedule | undefined
  hours: number[]
  date: Date
  last: boolean
  columnIndex: number
  onTitleClick?: (id: number) => void
  isWeekly?: boolean
  shrinked: boolean
  rightBorder?: boolean
  grouped?: boolean
  boundaryElement: HTMLDivElement | undefined
  contextMenuItems: ContextMenuItem[]
}

export const EmployeeColumn = observer<EmployeeColumnProps>(
  ({
    contextMenuItems,
    hours,
    date,
    locationsFromFilter,
    employee,
    branchSchedule,
    last,
    employees,
    columnIndex,
    onTitleClick,
    isWeekly = false,
    shrinked,
    rightBorder,
    grouped,
    boundaryElement,
  }) => {
    const columnRef = useRef<HTMLDivElement>(null)
    const [columnWidth, setColumnWidth] = useState(0)
    useLayoutEffect(() => {
      const newColumnWidth = columnRef.current?.offsetWidth ?? columnWidth
      if (newColumnWidth !== columnWidth) setColumnWidth(newColumnWidth)
    }, [columnWidth])

    const { t } = useTranslation()

    const cellHeight = shrinked ? SMALL_CELL_HEIGHT : CELL_HEIGHT
    const titleCellHeight = shrinked ? TALL_TITLE_CELL_HEIGHT : TITLE_CELL_HEIGHT

    const {
      multiBooking: { firstBooking, pushBooking, additionalBookings, removeBooking },
      bookingSettings: { employeeColumnWidth },
      unitedBookingUpdate: { bookingUpdateData, isUnitedBookingUpdateMode },
    } = store
    const bookingUpdateDataWithThisEmployee = bookingUpdateData.filter(
      data => data.employeeId === employee.id && isSameDay(data.startDate, date),
    )

    const [isHover, setIsHover] = useState(false)
    const debouncedIsHover = useDebounce<boolean>(isHover, 75)

    const isMultiBookingMode = firstBooking !== null
    const isFirstBookingInThisEmployee =
      isMultiBookingMode && firstBooking.employeeId === employee.id
    const firstBookingTopInCells = firstBooking
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        getTopPositionForBooking(getTimeFromDate(firstBooking.startDate!), hours[0])
      : 0
    const firstBookingTopPositionInRem =
      (firstBookingTopInCells * cellHeight + TITLE_CELL_HEIGHT) / TAILWIND_TO_REM_RATIO
    const firstBookingHeightInRem = firstBooking
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (cellHeight / 2) * (firstBooking.duration! / 60)
      : 0
    const multiBookingsInThisColumn = additionalBookings.filter(
      booking => booking.employeeId === employee.id && isSameDay(booking.date, date),
    )

    const branchDayTiming = defineDayTiming(branchSchedule, date)
    const locale = i18next.language

    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: myPermissions } = useFetchMyPermissions()
    const isEditingAllowed =
      (myPermissions?.includes(permissions.booking.set) ||
        myPermissions?.includes(permissions.booking.setOwn)) ??
      false
    const canCreateInPast = myPermissions?.includes(permissions.booking.editPast)

    const { data: bookings, isFetching } = useFetchBookingsOfDate(date, timezone, branchId)
    const bookingsByEmployee = filterBookingsForEmployeeColumn({
      bookings,
      locations: locationsFromFilter,
      employeeId: employee.id,
      dayTiming: branchDayTiming,
    })

    const isDayOff = checkDayOff(employee, date, branchDayTiming)

    const timeOffs = getEmployeeTimeOffByDay(employee.timeoffs, date, branchDayTiming)

    const markedBookings = markPositionForCards({
      bookings: bookingsByEmployee ?? [],
      timeOffs,
      unitedBookingUpdateData: bookingUpdateDataWithThisEmployee,
    })

    const { openCreateBookingDialog, openEditBookingDialog, bookingDialog } = useOpenBookingDialog()
    const { openEditGroupBookingDialog, groupBookingDialog, openCreateGroupBookingDialog } =
      useOpenGroupBookingDialog()
    const { openCreateTimeOffDialog, openEditTimeOffDialog, timeOffDialog } = useOpenTimeOffDialog()
    const {
      openCreateBookingMultiServicesDialog,
      openEditBookingMultiServicesDialog,
      bookingMultiServicesDialog,
    } = useOpenBookingMultiServiceDialog()

    const isToday = getIsToday(date, timezone)
    const { showConfirmation, confirmationModal } = useShowConfirmationPopup()
    const [openSnackbar] = useSnackbar()

    const labelClassName = 'truncate max-w-full' + (shrinked ? ' text-xs' : '')

    const hideColumn = !isWeekly && isDayOff && !bookingsByEmployee?.length && !grouped

    const className = getCalendarColumnClassName({
      weekly: isWeekly,
      shrinked,
      columnWidth: employeeColumnWidth,
      isHover: debouncedIsHover,
      last,
    })

    const handleOpenDialog = (id: number, branchWarning: boolean, cellDate: Date): void => {
      if (isUnitedBookingUpdateMode) return

      if (id === TIMEOFF_DIALOG_CONTEXT_MENU_ID) {
        if (isDayOff) {
          openSnackbar(t('timeOff.dayOffError'), 'error', 3000)
          return
        }

        openCreateTimeOffDialog({
          initialEmployeeId: employee.id,
          initialStartDate: cellDate,
          initialEndDate: addHours(cellDate, 1),
        })
        return
      }

      if (branchWarning) {
        openSnackbar(t('cellWarning.branch'), 'error', 3000)
        return
      }

      if (!canCreateInPast && isDateBefore(cellDate, createCurrentDate(timezone))) {
        openSnackbar(t('bookingValidation.notAllowedCreatePast'), 'error')
        return
      }

      if (id === BOOKING_DIALOG_CONTEXT_MENU_ID) {
        if (isMultiBookingMode) {
          pushBooking({ date: cellDate, employeeId: employee.id })
        }
        if (!isMultiBookingMode) {
          openCreateBookingDialog({
            locationId: undefined,
            employeeId: employee.id,
            startDate: cellDate,
          })
        }
      }

      if (id === GROUP_BOOKING_DIALOG_CONTEXT_MENU_ID) {
        openCreateGroupBookingDialog({
          startDate: cellDate,
          employeeId: employee.id,
          locationId: undefined,
        })
      }

      if (id === MULTI_SERVICE_BOOKING_DIALOG_CONTEXT_MENU_ID) {
        openCreateBookingMultiServicesDialog({
          startDate: cellDate,
          employeeId: employee.id,
          locationId: undefined,
        })
      }
    }

    return hideColumn ? null : (
      <CalendarColumn
        ref={columnRef}
        className={className}
        onMouseOver={() => {
          if (shrinked) setIsHover(true)
        }}
        onMouseOut={() => {
          if (shrinked) setIsHover(false)
        }}
      >
        <div>
          {hours.map(hour => {
            const cellDate = setTimeForDateByFloat(hour, date)
            const dto: GetWarningsForCellArgs = {
              locations: locationsFromFilter,
              employees: [employee],
              cellDate,
              branchSchedule,
              bookings: bookings ?? [],
              isEditingAllowed,
            }

            const cellWarnings = getWarningsForCell(dto)
            const cellWarningMessage = getCellWarningMessage(cellWarnings, t)

            const cellClassName = getCellClassName(
              isEditingAllowed,
              cellWarningMessage,
              isWeekly,
              isToday,
            )

            const branchError = Boolean(cellWarnings.branch)

            return (
              <CalendarCellWithPlanBillingCheck
                key={hour}
                small={shrinked}
                className={cellClassName}
                lastColumn={last}
                disabled={!isEditingAllowed}
                onClick={() =>
                  handleOpenDialog(BOOKING_DIALOG_CONTEXT_MENU_ID, branchError, cellDate)
                }
                onLongPress={() =>
                  handleOpenDialog(TIMEOFF_DIALOG_CONTEXT_MENU_ID, branchError, cellDate)
                }
                contextMenuItems={contextMenuItems}
                onContextMenuItemClick={id => handleOpenDialog(id, branchError, cellDate)}
              >
                {Number.isInteger(hour) && columnIndex % 2 === 1 && (
                  <div className="absolute left-1 top-0 text-calendar-time-color font-medium text-lg">
                    {hour}:00
                  </div>
                )}
                {/* Because of relative sizing text affects the outer layout */}
                <div className="absolute inset-0 flex-centered">
                  <span
                    className={`text-center invisible group-hover:visible${
                      isEditingAllowed ? '' : ' cursor-default'
                    }`}
                  >
                    {cellWarningMessage}
                  </span>
                </div>
              </CalendarCellWithPlanBillingCheck>
            )
          })}
        </div>
        <div>
          {markedBookings?.map(card => {
            if (card.cardsType === CardsType.unitedBookingUpdate)
              return (
                <UnitedBookingUpdateCard
                  key={card.cardsType + card.id}
                  columnRefWidth={columnWidth}
                  shrinked={shrinked}
                  bookingData={card}
                  dayTiming={branchDayTiming}
                  firstCellTime={hours[0]}
                  cellHeight={cellHeight}
                  titleCellHeight={titleCellHeight}
                  employeeIsSelected={isWeekly}
                  columnIndex={columnIndex}
                  calendarType="employees"
                  employees={employees}
                  showConfirmation={showConfirmation}
                />
              )

            return card.cardsType === CardsType.booking ? (
              <BookingCard
                timezone={timezone ?? DEFAULT_TIMEZONE}
                columnRefWidth={columnWidth}
                key={card.cardsType + card.id}
                shrinked={shrinked}
                booking={card}
                calendarType="employees"
                dayTiming={branchDayTiming}
                firstCellTime={hours[0]}
                cellHeight={cellHeight}
                titleCellHeight={titleCellHeight}
                columnIndex={columnIndex}
                employees={employees}
                employeeIsSelected={isWeekly}
                showConfirmation={showConfirmation}
                onClick={() => {
                  if (card.unitedBooking?.id)
                    openEditBookingMultiServicesDialog(card.unitedBooking.id)
                  else {
                    if (!card.isGroupBooking) openEditBookingDialog(card.id)
                    else openEditGroupBookingDialog(card.id)
                  }
                }}
                boundaryElement={boundaryElement}
              />
            ) : (
              <CalendarTimeOffCard
                key={card.cardsType + card.id}
                columnDate={date}
                timeOff={card}
                dayTiming={branchDayTiming}
                firstCellTime={hours[0]}
                cellHeight={cellHeight}
                titleCellHeight={titleCellHeight}
                onClick={() => {
                  openEditTimeOffDialog(card.id)
                }}
              />
            )
          })}
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {isFirstBookingInThisEmployee && isSameDay(firstBooking!.startDate!, date) && (
            <GhostBookingCard
              topPositionInRem={firstBookingTopPositionInRem}
              heightInRem={firstBookingHeightInRem}
              initial
            />
          )}
          {multiBookingsInThisColumn.map(booking => {
            const topInCells = getTopPositionForBooking(getTimeFromDate(booking.date), hours[0])
            const topPositionInRem =
              (topInCells * cellHeight + TITLE_CELL_HEIGHT) / TAILWIND_TO_REM_RATIO

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const heightInRem = (cellHeight / 2) * (firstBooking!.duration! / 60)
            return (
              <GhostBookingCard
                key={booking.date.getTime()}
                topPositionInRem={topPositionInRem}
                heightInRem={heightInRem}
                onClick={() => removeBooking(booking.specialId)}
              />
            )
          })}
        </div>
        {isToday && (
          <CalendarTimeLine
            cellHeight={cellHeight}
            calendarStartHour={hours[0]}
            tall={shrinked && !isWeekly}
            // Ugly vertical desync with the time column
            className="mt-[1px]"
          />
        )}
        <CalendarTitleCell
          grouped={grouped}
          rightBorder={rightBorder}
          last={last}
          onClick={onTitleClick ? () => onTitleClick(employee.id) : undefined}
          height={shrinked && !isWeekly ? 'large' : undefined}
        >
          {!isWeekly && employee.photo && (
            <img
              className="h-7 w-7 rounded-full m-1"
              src={employee.photo}
              alt={`${t('photo.name')} ${transformPersonName(employee)}`}
            />
          )}
          {isWeekly ? (
            <p className="truncate max-w-full">
              {date.toLocaleDateString(locale, {
                weekday: 'long',
              })}{' '}
              {formatDayMonth(date)}
            </p>
          ) : (
            <>
              {!employee.photo && <p className={labelClassName}>{employee.firstName}</p>}
              <p className={labelClassName + ' ml-1'}>{employee.lastName}</p>
            </>
          )}
          {isFetching && <SpinnerSmall className="absolute right-2" />}
        </CalendarTitleCell>
        {bookingDialog}
        {groupBookingDialog}
        {confirmationModal}
        {timeOffDialog}
        {bookingMultiServicesDialog}
      </CalendarColumn>
    )
  },
)
