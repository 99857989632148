import { useFetchBranchById, useMutateDynamicDates } from '@expane/data'
import { createCurrentDate, getWeekDayNumber, fromZonedTime } from '@expane/date'
import { permissions } from '@expane/logic/permission'
import { Page, useShowConfirmationPopup } from '@expane/ui'
import { DateTimePicker, useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import {
  ArchiveTimeOffsButton,
  CreateTimeOffsButton,
  ScheduleTemplateSettingsNavigationButton,
} from 'pages/SchedulesPage/buttons'
import { SchedulePageCalendar } from 'pages/SchedulesPage/ScheduleCalendar'
import { FC, useState } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { store } from 'store'
import { ButtonWithBillingCheck } from 'widgets/Buttons'

export interface EmployeesScheduleDynamicDateType {
  date: Date
  employeeId: number
  employeeScheduleId: number
}

export interface EmployeesScheduleDynamicDatesForm {
  dynamicDates: Array<EmployeesScheduleDynamicDateType>
}

export const SchedulePage: FC = () => {
  const branchId = store.branch.branchId
  const { data: branch } = useFetchBranchById(branchId)
  const branchSchedule = branch?.schedule
  const timezone = branch?.timezone

  const { data: myPermissions } = useFetchMyPermissions()

  const [pickedDate, setPickedDate] = useState(createCurrentDate(branch?.timezone))

  const { mutateAsync: createDynamicDates } = useMutateDynamicDates()

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { control, handleSubmit, reset } = useForm<EmployeesScheduleDynamicDatesForm>({
    defaultValues: { dynamicDates: [] },
  })

  const dynamicDates = useWatch({ control, name: 'dynamicDates' })
  const dynamicDatesQuantity = dynamicDates.length

  const mutateDynamic: SubmitHandler<EmployeesScheduleDynamicDatesForm> = async ({
    dynamicDates,
  }) => {
    if (!branchSchedule || !timezone) return

    const dynamicScheduleDates = dynamicDates
      .map(dynamicDate => {
        const branchTiming =
          branchSchedule.data[getWeekDayNumber(fromZonedTime(dynamicDate.date, timezone))]

        if (branchTiming)
          return {
            timingStart: branchTiming[0],
            timingEnd: branchTiming[1],
            date: fromZonedTime(dynamicDate.date, timezone),
            generatedId: undefined,
            employeeScheduleId: dynamicDate.employeeScheduleId,
          }
        else return null
      })
      .filter(date => date !== null)

    const result = await createDynamicDates({
      dynamicScheduleDates,
      // мы только добавляем даты
      dynamicDateIdsToDelete: [],
    })

    if (result?.insertDynamicScheduleDates?.affected_rows) {
      openSnackbar(t('schedule.successfullyReassigned'), 'success')
      reset()
    } else openSnackbar(t('submitError'), 'error')
  }

  const handleAddDynamicDates = () => {
    showConfirmation({
      title: t('schedule.addingDays'),
      description: t('schedule.addingDaysConfirmation'),
      onConfirm: handleSubmit(mutateDynamic),
    })
  }

  const isEditingTimeOffAllowed = myPermissions?.includes(permissions.timeOff.set)

  if (!branchSchedule || !branch) return null

  return (
    <Page>
      <div className="flex mb-2">
        <DateTimePicker
          timezone={branch.timezone}
          type="week"
          className="w-60 mr-2"
          onChange={setPickedDate}
          value={pickedDate}
          nextPreviousButtons={true}
        />

        <ScheduleTemplateSettingsNavigationButton />

        {dynamicDatesQuantity > 0 && (
          <ButtonWithBillingCheck
            onClick={handleAddDynamicDates}
            className="ml-2"
            Icon={IoCheckmarkSharp}
          >
            {t('schedule.addDays')}:<span className="ml-2">{dynamicDatesQuantity}</span>
          </ButtonWithBillingCheck>
        )}

        {isEditingTimeOffAllowed && <CreateTimeOffsButton className="ml-2" />}
        {isEditingTimeOffAllowed && <ArchiveTimeOffsButton className="ml-2" />}
      </div>

      <div className="pb-4">
        <SchedulePageCalendar pickedDate={pickedDate} control={control} />
      </div>

      {confirmationModal}
    </Page>
  )
}
