import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { EMPLOYEE_SETTINGS_QUERY_KEY } from './queryKeys'
import { EmployeeConnections, EmployeeNotificationSettings } from './logic'

export function useUpdateMyEmployeeLanguage() {
  return useMutation(
    (dto: { id: number; lang: string }) => {
      return request(
        gql`
          mutation ($id: Int!, $lang: String!) {
            updateEmployeeSettingsById(pk_columns: { id: $id }, _set: { lang: $lang }) {
              lang
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
    },
  )
}

export function useUpdateEmployeeNotificationSettings() {
  return useMutation(
    async (dto: { id: number; notificationSettings: EmployeeNotificationSettings }) => {
      const result = await request<{ updateClientSettingsById?: { id: number | null } }>(
        gql`
          mutation ($id: Int!, $notificationSettings: jsonb!) {
            updateEmployeeSettingsById(
              pk_columns: { id: $id }
              _set: { notificationSettings: $notificationSettings }
            ) {
              id
            }
          }
        `,
        dto,
      )
      return Boolean(result?.updateClientSettingsById?.id)
    },
    {
      onSuccess: () => queryClient.invalidateQueries([EMPLOYEE_SETTINGS_QUERY_KEY]),
    },
  )
}

export function useUpdateEmployeeConnections() {
  return useMutation(
    async (dto: { id: number; preferredConnections: EmployeeConnections }) => {
      const result = await request<{ updateEmployeeSettingsById?: { id: number | null } }>(
        gql`
          mutation ($id: Int!, $preferredConnections: jsonb!) {
            updateEmployeeSettingsById(
              pk_columns: { id: $id }
              _set: { preferredConnections: $preferredConnections }
            ) {
              id
            }
          }
        `,
        dto,
      )
      return Boolean(result?.updateEmployeeSettingsById?.id)
    },
    {
      onSuccess: () => queryClient.invalidateQueries([EMPLOYEE_SETTINGS_QUERY_KEY]),
    },
  )
}

export function useEnableMyEmployeePushNotifications() {
  return useMutation(
    async (dto: { employeeId: number; expoPushToken: string; platform: string }) => {
      const result = await request<{
        updateMultipleEmployeeSettings?: { affectedRows: number | null }
        insertPushNotificationSettings?: { affectedRows: number | null }
      }>(
        gql`
          mutation ($employeeId: Int!, $expoPushToken: String!, $platform: String!) {
            updateMultipleEmployeeSettings(
              _append: { preferredConnections: { expo: true } }
              where: { employeeId: { _eq: $employeeId } }
            ) {
              affectedRows: affected_rows
            }

            insertPushNotificationSettings(
              objects: [{ expoPushToken: $expoPushToken, platform: $platform }]
              on_conflict: {
                constraint: pushNotificationSettings_email_expoPushToken_platform_role_key
                update_columns: []
              }
            ) {
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
      return Boolean(result?.updateMultipleEmployeeSettings?.affectedRows)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(EMPLOYEE_SETTINGS_QUERY_KEY),
        })
      },
    },
  )
}
