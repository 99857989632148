import {
  useFetchSchedules,
  useUpdateSchedule,
  WeeklySchedule as WeeklyScheduleType,
} from '@expane/data'
import { formatScheduleTemplateWeeklyData, ScheduleTemplateFormData } from '@expane/logic/schedule'
import { Paper } from '@expane/ui'
import { ProceedToNextStep, useCreateBusinessSteps } from 'pages/CreateBusinessPage'
import { stepStyle } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { useSnackbar } from '@expane/widgets'
import { WeeklySchedule } from 'widgets/ScheduleDialog/WeeklySchedule'

export const ScheduleStep: FC = () => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const { t } = useTranslation()

  const { handleSubmit, control, setValue, getValues } = useForm<ScheduleTemplateFormData>()

  const [openSnackBar] = useSnackbar()

  const { data: schedules } = useFetchSchedules()
  const { mutateAsync: updateSchedule } = useUpdateSchedule()

  const submit = handleSubmit(async data => {
    try {
      if (schedules) {
        await updateSchedule({
          id: schedules[0].id,
          scheduleSetInput: {
            name: schedules[0].name,
            data: formatScheduleTemplateWeeklyData(data),
            type: 1,
          },
        })
        proceedToNextStep()
      }
    } catch (error) {
      reportError(error as Error)
      openSnackBar(t('submitError'), 'error', 3000)
    }
  })

  return (
    <div className={'flex items-end'}>
      <Paper className={stepStyle + ' pb-2 h-138 w-160'}>
        {schedules && (
          <WeeklySchedule
            timezone={undefined}
            control={control}
            setValue={setValue}
            getValues={getValues}
            schedule={schedules[0] as WeeklyScheduleType}
            // При створенні бізнеса це і є розклад філії
            branchSchedule={schedules[0] as WeeklyScheduleType}
          />
        )}
      </Paper>

      <ProceedToNextStep onClick={submit} />
    </div>
  )
}
