import {
  ClientPhones,
  ServerAccountType,
  ServerClientBriefType,
  TRANSACTION_TYPES,
  useCreateTransactionFromAccount,
  useFetchAccounts,
  useFetchClientBalance,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useGetBranchDefaultAccountId,
} from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { addPhonesToClients } from '@expane/logic/client'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { checkOnlyPositiveAmount, checkWithdrawPossibility, PLACEHOLDERS } from '@expane/logic/form'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  Textarea,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoArrowUndoCircleOutline } from 'react-icons/io5'
import { store } from 'store'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { OnClientAccount } from 'widgets/OperationsWithClientAccount/OnClientAccount'
import { ButtonWithBillingCheck } from 'widgets/Buttons'
import { useAccountsWithoutCheckbox } from '@expane/logic/payment/checkbox'
import { findById } from '@expane/logic/utils'

type CashOutFromClientAccountFormValues = {
  amount: string
  fromAccountId: number
  clientId: number | null
  comment: string
}

interface CashOutFromClientAccountDialogProps {
  clientId?: number
  closeDialog: () => void
}

interface CashOutFromClientAccountDialogLogicProps extends CashOutFromClientAccountDialogProps {
  clients: ServerClientBriefType[]
  clientsPhones: ClientPhones[]
  accounts: ServerAccountType[]
  defaultAccountId: number
  branchId: number
}

const CashOutFromClientAccountDialog: FC<CashOutFromClientAccountDialogProps> = observer(
  ({ clientId, closeDialog }) => {
    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)

    const { data: clients, isLoading: isLoadingClients } = useFetchClientsBriefs(branchId, timezone)
    const { data: clientsPhones, isLoading: isLoadingClientsPhones } = useFetchClientsPhones()
    const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)
    const { data: defaultAccountId, isLoading: isLoadingDefaultAccountId } =
      useGetBranchDefaultAccountId(branchId)

    const isLoading =
      isLoadingAccounts || isLoadingDefaultAccountId || isLoadingClients || isLoadingClientsPhones
    const isNoData = !accounts || !clients || !defaultAccountId || !clientsPhones || !branchId

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (isLoading) return <CashOutFromClientAccountPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <CashOutFromClientAccountDialogLogic
        closeDialog={closeDialog}
        clients={clients}
        clientsPhones={clientsPhones}
        clientId={clientId ?? clients[0]?.id}
        defaultAccountId={defaultAccountId}
        accounts={accounts}
        branchId={branchId}
      />
    )
  },
)

const CashOutFromClientAccountDialogLogic: FC<CashOutFromClientAccountDialogLogicProps> = ({
  clientId,
  closeDialog,
  clients,
  clientsPhones,
  defaultAccountId,
  accounts,
  branchId,
}) => {
  const { accountsWithoutCheckbox } = useAccountsWithoutCheckbox(accounts)

  const { formState, control, handleSubmit } = useForm<CashOutFromClientAccountFormValues>({
    defaultValues: {
      amount: '',
      clientId,
      fromAccountId: findById(defaultAccountId, accountsWithoutCheckbox)?.id,
    },
  })

  const watchedClientId = useWatch({
    control,
    name: 'clientId',
    defaultValue: clientId,
  })
  const watchedFromAccountId = useWatch({
    control,
    name: 'fromAccountId',
    defaultValue: defaultAccountId,
  })
  const { t } = useTranslation()
  const convertToMoneyCode = useConvertNumberToMoneyCode({ branchId })

  const { data: clientBalance, isFetching } = useFetchClientBalance(watchedClientId, branchId)

  const { mutateAsync: insertTransactionFromAccount } = useCreateTransactionFromAccount()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const mutateAccounts: SubmitHandler<CashOutFromClientAccountFormValues> = async data => {
    const { amount, clientId, fromAccountId, comment } = data

    try {
      await insertTransactionFromAccount({
        type: TRANSACTION_TYPES.cashOut.id,
        amount: Number(amount),
        clientId,
        fromAccountId,
        comment,
        branchId,
      })

      const client = clients?.find(client => client.id === clientId)
      openSnackbar(
        `${convertToMoneyCode(Number(amount))} ${t('transaction.cashedOutFromAccount')} ${
          client?.lastName
        } ${client?.firstName}`,
        'success',
      )
    } catch (error) {
      if (isRestrictionError(error)) openSnackbar(t('planRestriction'), 'error')
      else if (isPlanError(error)) openSnackbar(t('planInfo.noPlan'), 'error')
      else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const clientsWithPhones = addPhonesToClients(clients, clientsPhones)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!(formState.isSubmitting || !clientBalance || isFetching || !formState.isDirty))
            handleSubmit(mutateAccounts)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('transactionNames.cashOut')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              name="clientId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <ClientSelectDropdown
                  value={value}
                  clients={clientsWithPhones}
                  onChange={onChange}
                  errorMessage={{
                    isShown: Boolean(formState.errors.clientId),
                    text: t('formError.required'),
                  }}
                  required
                />
              )}
            />

            <OnClientAccount
              clientBalance={clientBalance}
              isFetching={isFetching}
              branchId={branchId}
            />

            <div className="mt-3 flex">
              <Controller
                name="amount"
                control={control}
                rules={{
                  required: true,
                  validate: {
                    checkClientBalance: value => {
                      if (!clientBalance) return false
                      return Number(value) <= clientBalance
                    },
                    checkWithdrawPossibility: value =>
                      checkWithdrawPossibility({
                        currentAccount: getCurrentAccount(
                          accountsWithoutCheckbox,
                          watchedFromAccountId,
                        ),
                        value: Number(value),
                      }),
                    checkOnlyPositiveAmount,
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    type="number"
                    label={t('amount')}
                    placeholder={PLACEHOLDERS.servicePrice}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={getErrorMessage(formState.errors.amount?.type, t)}
                    containerClassName="w-1/2 pr-2"
                    autoFocus
                  />
                )}
              />

              <Controller
                name="fromAccountId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    value={value}
                    label={t('fromAccount')}
                    placeholder={t('placeholders.defaultSelect')}
                    items={accountsWithoutCheckbox}
                    noDataMessage={t('noSuchAccount')}
                    onSelectChange={onChange}
                    popupHeight="small"
                    errorMessage={{
                      isShown: Boolean(formState.errors.fromAccountId),
                      text: t('formError.required'),
                    }}
                    required
                    className="grow"
                  />
                )}
              />
            </div>

            <Controller
              name="comment"
              control={control}
              defaultValue={''}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  value={value}
                  label={t('comment.title')}
                  placeholder={t('placeholders.cashOutDescription')}
                  onChange={onChange}
                  rows={2}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              onClick={handleSubmit(mutateAccounts)}
              disabled={
                formState.isSubmitting || !clientBalance || isFetching || !formState.isDirty
              }
              spinner={formState.isSubmitting}
              Icon={IoArrowUndoCircleOutline}
            >
              {t('transaction.cashOutFromAccount')}
            </Button>

            <CloseButton
              onClick={closePopups}
              disabled={formState.isSubmitting}
              className="mr-auto"
            />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
    </>
  )
}

const CashOutFromClientAccountPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('transactionNames.cashOut')}</Dialog.Title>

        <Dialog.Body>
          <PlaceholderInput label={t('client.name')} className="pb-4" />

          <OnClientAccount clientBalance={undefined} isFetching={true} branchId={undefined} />

          <div className="mt-3 flex mb-4">
            <PlaceholderInput label={t('amount')} className="w-1/2 pr-2" />
            <PlaceholderInput label={t('fromAccount')} className="grow" />
          </div>

          <PlaceholderTextarea label={t('comment.title')} rows={2} />
        </Dialog.Body>

        <Dialog.Footer>
          <ButtonWithBillingCheck disabled Icon={IoArrowUndoCircleOutline}>
            {t('transaction.cashOutFromAccount')}
          </ButtonWithBillingCheck>

          <CloseButton onClick={closeDialog} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenCashOutFromClientAccountDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const clientId = useRef<number | undefined>()
  const onCloseDialog = useRef<() => void>()

  const openCashOutFromClientAccountDialog = (id?: number, onClose?: () => void) => {
    clientId.current = id
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const cashOutFromClientAccountDialog = isOpen ? (
    <CashOutFromClientAccountDialog closeDialog={closeDialog} clientId={clientId.current} />
  ) : null

  return { openCashOutFromClientAccountDialog, cashOutFromClientAccountDialog }
}

const getErrorMessage = (type: string | undefined, t) => {
  if (type === 'checkWithdrawPossibility') return t('formError.insufficientFunds')
  if (type === 'checkClientBalance') return t('formError.amountMoreThanClientsAccount')
  if (type === 'required') return t('formError.required')
  if (type === 'checkOnlyPositiveAmount') return t('formError.invalidValue')
}
