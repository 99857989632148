import { firebase } from 'services/firebase'
import { initAuthentication } from './auth'
import { initSentry } from '@expane/web-logic/sentry'
import { config } from 'config'

export function initApp() {
  initSentry({
    dsn: 'https://0979b6f46d8142b792edbf87ca876dc6@o706144.ingest.sentry.io/5778269',
    environment: config.ENV,
    release: config.VERSION,
  })

  firebase.init()

  initAuthentication()

  firebase.subscribeToServerStatus()
}

export function reloadApp() {
  window.location.reload()
}
