import { gql } from 'graphql-request'
import { reportGqlError, request, useQuery } from '../../api'
import { employeeSettingsFragment } from './employeeSettings.fragments'
import { EMPLOYEE_SETTINGS_QUERY_KEY } from './queryKeys'
import { EmployeeSettingsType } from './logic'

export function useFetchEmployeeSettingsByEmployeeId(employeeId: number | null) {
  return useQuery(
    [EMPLOYEE_SETTINGS_QUERY_KEY],
    async () => {
      const result = await request<{
        employeeSettings: EmployeeSettingsType[]
      }>(
        gql`
          ${employeeSettingsFragment}
          query ($employeeId: Int!) {
            employeeSettings(where: { employeeId: { _eq: $employeeId } }) {
              ...employeeSettingsType
            }
          }
        `,
        {
          employeeId,
        },
      )

      if (result?.employeeSettings) return result.employeeSettings[0]

      return undefined
    },
    {
      queryName: 'useFetchMyEmployeeSettings',
      onError: reportGqlError,
      enabled: Boolean(employeeId),
    },
  )
}
