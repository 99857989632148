import {
  Auth,
  AuthCredential,
  AuthProvider,
  getAuth as fbGetAuth,
  GoogleAuthProvider,
  IdTokenResult,
  isSignInWithEmailLink as fbIsSignInWithEmailLink,
  NextOrObserver,
  OAuthProvider,
  sendSignInLinkToEmail,
  signInWithCredential as fbSignInWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink as fbSignInWithEmailLink,
  signInWithPopup as fbSignInWithPopup,
  signOut as fbSignOut,
  User,
} from 'firebase/auth'
import { logEvent } from 'firebase/analytics'
import { sleep } from '@expane/logic/utils'
import type { SendAuthEmailFunc, XHasuraClaims } from '@expane/logic/auth/types'
import {
  EMAIL_FOR_SIGN_IN_STORAGE_KEY,
  WAIT_FOR_AUTH_CLAIMS_SETTINGS,
} from '@expane/logic/auth/constants'

let googleAuthProvider: GoogleAuthProvider | undefined
let appleAuthProvider: OAuthProvider | undefined

const getAuth = (): Auth => fbGetAuth()

const getCurrentUser = (): User | null => getAuth()?.currentUser || null

const reloadUser = async () => {
  await getAuth()?.currentUser?.reload()
}

const sendAuthEmail: SendAuthEmailFunc = async (email, url, options) => {
  const auth = getAuth()

  auth.languageCode = options.languageCode
  options.setLocalStorageItem(EMAIL_FOR_SIGN_IN_STORAGE_KEY, email)
  return await sendSignInLinkToEmail(auth, email, {
    url: url,
    handleCodeInApp: true,
    ...(options.applicationId
      ? {
          iOS: { bundleId: options.applicationId },
          android: {
            packageName: options.applicationId,
            installApp: true,
          },
          dynamicLinkDomain: options.dynamicLinkDomain,
        }
      : {}),
  })
}

const signOut = () => fbSignOut(getAuth())

const getAuthToken = async (forceRefresh?: boolean) => {
  if (!getCurrentUser()) {
    throw new Error('Trying to get auth token, while not being authenticated')
  }

  return await getCurrentUser()?.getIdToken(forceRefresh)
}

const getAuthTokenResult = async (forceRefresh?: boolean): Promise<IdTokenResult | undefined> => {
  if (!getCurrentUser()) {
    throw new Error('Trying to get auth token, while not being authenticated')
  }

  return await getCurrentUser()?.getIdTokenResult(forceRefresh)
}

const getXHasuraClaims = async (): Promise<XHasuraClaims | string | undefined> => {
  const token = await getAuthTokenResult()
  return token?.claims?.['https://hasura.io/jwt/claims'] as XHasuraClaims | undefined | string
}

const signInWithPassword = (email: string, password: string) =>
  signInWithEmailAndPassword(getAuth(), email, password)

const signInWithEmailLink = (email: string, url: string) =>
  fbSignInWithEmailLink(getAuth(), email, url)

const isSignInWithEmailLink = (emailLink: string) => fbIsSignInWithEmailLink(getAuth(), emailLink)

const waitForAuthClaims = async () => {
  for (let i = 0; i < WAIT_FOR_AUTH_CLAIMS_SETTINGS.steps; i++) {
    const tokenData = await getAuth().currentUser?.getIdTokenResult(true)

    if (tokenData?.claims?.['https://hasura.io/jwt/claims']) return

    await sleep(WAIT_FOR_AUTH_CLAIMS_SETTINGS.timeStep)
  }
}

const signInWithCredential = async (credential: AuthCredential) =>
  fbSignInWithCredential(getAuth(), credential)

// Поки що в мобілі використовується - в майбутньому можливо є сенс перенести на useEffect
let unsubscribeAuthStateChange: (() => void) | undefined
function subscribeAuthStateChange(listener: NextOrObserver<User>) {
  unsubscribeAuthStateChange?.()
  unsubscribeAuthStateChange = getAuth().onAuthStateChanged(listener)
}

const signInWithPopup = (provider: AuthProvider) => fbSignInWithPopup(getAuth(), provider)

const getGoogleAuthProvider = () => {
  if (!googleAuthProvider) {
    googleAuthProvider = new GoogleAuthProvider()
    googleAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.email')
  }

  return googleAuthProvider
}

const getAppleAuthProvider = () => {
  if (!appleAuthProvider) appleAuthProvider = new OAuthProvider('apple.com')

  return appleAuthProvider
}

export const firebase = {
  GoogleAuthProvider,
  getAppleAuthProvider,
  getAuth,
  getAuthToken,
  getAuthTokenResult,
  getCurrentUser,
  getGoogleAuthProvider,
  getXHasuraClaims,
  isSignInWithEmailLink,
  logEvent,
  reloadUser,
  sendAuthEmail,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPassword,
  signInWithPopup,
  signOut,
  subscribeAuthStateChange,
  waitForAuthClaims,
}
