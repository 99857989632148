import { gql } from 'graphql-request'

export const paymentProviderFragment = gql`
  fragment paymentProviderType on paymentProvider {
    id
    name
    type
    account
    secret
    branchId
    createdAt
  }
`
