import {
  ServerLocationWithServiceIdsType,
  useFetchBranchById,
  useFetchEmployeesGroups,
  useFetchExtendedEmployees,
  useFetchLocations,
} from '@expane/data'
import {
  addMonths,
  createCurrentDate,
  DEFAULT_TIMEZONE,
  fromZonedTime,
  isSameDay,
  startOfDay,
  subMonths,
} from '@expane/date'
import { permissions } from '@expane/logic/permission'
import { useWebPersistedState } from '@expane/web-logic/useWebPersistedState'
import { Spinner } from '@expane/ui'
import { CalendarView, DatePicker, DateTimePicker, QuickDateButtons } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import {
  AddWorkingDayToEmployeesButton,
  useOpenAddWorkingDayToEmployeesDialog,
} from 'pages/BookingsPage/AddEmployeeWorkingDayDialog'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPeopleCircleOutline, IoPersonOutline } from 'react-icons/io5'
import { reportError } from 'services/api'
import { store } from 'store'
import { CogIcon } from 'ui/Icons'
import { MultiSelect } from 'ui/MultiSelect'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { convertEmployeeGroupsToTreeItems } from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { useOpenBookingCardSettingsDialog } from 'widgets/BookingCalendarSettingsDialog'
import { useOpenBookingMultiServiceDialog } from 'widgets/BookingMultiServicesDialog'
import { useBookingsSelectionDialog } from 'widgets/BookingsSelectionDialog'
import { ButtonWithBillingCheck, QuickSaleButton } from 'widgets/Buttons'
import { useOpenGroupBookingDialog } from 'widgets/GroupBookingDialog'
import { useMultiBookingDialog } from 'widgets/MultiBookingDialog'
import { useQuickSaleDialog } from 'widgets/QuickSaleDialog'
import { BookingPresetSelect } from './BookingPresetSelect'
import { BookingsCalendar } from './BookingsCalendar'
import { CalendarTypeSelect } from './CalendarTypeSelect'
import { EmployeeSelect } from './EmployeeSelect'
import {
  filterEmployeeGroupsWhoHaveServices,
  getFullInfoForEmployees,
  getFullInfoForLocations,
  getSelectedEmployeeLocalStorageKey,
} from './logic'
import { MultiBookingBar } from './MultiBookingBar'
import { NoDataPlaceholder } from './NoDataPlaceholder'
import { UnitedBookingBar } from './UnitedBookingBar'

export const BookingsPage: FC = observer(() => {
  const {
    bookingSettings: { isCalendarAlwaysOpen },
    multiBooking: { isMultiBookingMode },
    unitedBookingUpdate: { isUnitedBookingUpdateMode, bookingUpdateData },
  } = store
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { t } = useTranslation()
  const { data: branch, isLoading: isLoadingBranch } = useFetchBranchById(branchId)
  const timezone = branch?.timezone

  const { storage: currentCalendarType, updateStorage: setCurrentCalendarType } =
    useWebPersistedState<BookingCalendarType>(
      'calendar/currentCalendarType',
      reportError,
      'employees',
    )
  const { storage: selectedEmployeeId, updateStorage: setSelectedEmployeeId } =
    useWebPersistedState<number>(getSelectedEmployeeLocalStorageKey(branchId), reportError)
  const [selectedEmployees, setSelectedEmployees] = useState<TreeMenuItem[]>([])
  const [selectedLocations, setSelectedLocations] = useState<ServerLocationWithServiceIdsType[]>([])
  const { storage: columnsAreShrinked, updateStorage: setColumnsAreShrinked } =
    useWebPersistedState('calendar/columnsShrinked', reportError, false)

  const dateForUnitedBookingUpdate = bookingUpdateData[0]?.startDate as Date | undefined

  const [presetId, setPresetId] = useState<number | null>(null)
  const [activeStartDate, setActiveStartDate] = useState(() => createCurrentDate(timezone))
  const [view, setView] = useState<CalendarView>('month')
  const [pickedDate, setPickedDate] = useState(() => createCurrentDate(timezone))
  useEffect(() => {
    if (!isUnitedBookingUpdateMode || !dateForUnitedBookingUpdate) return
    if (!isSameDay(pickedDate, dateForUnitedBookingUpdate))
      setPickedDate(startOfDay(dateForUnitedBookingUpdate))
  }, [isUnitedBookingUpdateMode, dateForUnitedBookingUpdate, pickedDate])

  const selectEmployee = (id: number | undefined) => {
    if (id !== undefined && columnsAreShrinked === true) setColumnsAreShrinked(false)

    setSelectedEmployeeId(id)
  }

  const { data: locations, isLoading: isLocationsLoading } = useFetchLocations(branchId)
  const thereAreLocations = locations !== undefined && locations?.length > 0
  const { data: myPermissions } = useFetchMyPermissions()

  // Нам нужна информация о выбранных сотрудниках
  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)
  const selectedEmployeesWithoutGroups = extractItemsFromFolders(selectedEmployees)
  const employeesForCalendar =
    employees?.filter(employee =>
      selectedEmployeesWithoutGroups.find(selectedEmployee => selectedEmployee.id === employee.id),
    ) ?? []

  const { data: employeeGroups, isLoading: isEmployeeGroupsLoading } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )

  const filteredEmployeeGroups = employeeGroups
    ? filterEmployeeGroupsWhoHaveServices(employeeGroups)
    : undefined
  const thereAreEmployees = filteredEmployeeGroups ? filteredEmployeeGroups.length > 0 : false
  const treeItems = filteredEmployeeGroups
    ? convertEmployeeGroupsToTreeItems(filteredEmployeeGroups)
    : []

  const { openBookingCardSettingsDialog, bookingCardSettingsDialog } =
    useOpenBookingCardSettingsDialog({
      columnsAreShrinked,
      setColumnsAreShrinked,
    })

  const { openQuickSaleDialog, quickSaleDialog } = useQuickSaleDialog()
  const { bookingSelectionDialog, openBookingsSelectionDialog } = useBookingsSelectionDialog()
  const { openCreateGroupBookingDialog, groupBookingDialog } = useOpenGroupBookingDialog()
  const { openMultiBookingDialog, multiBookingDialog } = useMultiBookingDialog()
  const { openMultiServicesCreateDialogFromCalendar, bookingMultiServicesDialog } =
    useOpenBookingMultiServiceDialog()
  const { addWorkingDayToEmployeesDialog, openAddWorkingDayToEmployeesDialog } =
    useOpenAddWorkingDayToEmployeesDialog()

  const resetPreset = () => {
    if (presetId !== null) setPresetId(null)
  }

  const isBookingEditingAllowed = myPermissions?.includes(permissions.booking.set)
  const isBookingsSelectionDialogAllowed =
    myPermissions?.includes(permissions.transaction.set) &&
    myPermissions?.includes(permissions.booking.get)
  const isQuickSellAllowed = myPermissions?.includes(permissions.transaction.set)
  const isDynamicDateCreationAllowed = myPermissions?.includes(permissions.schedule.set)

  const isWeeklyView = currentCalendarType === 'employees' && selectedEmployeeId

  if (isLoadingBranch) return <Spinner expandCentered />

  return (
    <>
      <div className="pl-4 pr-3 sticky left-0 flex justify-between items-center">
        <div className="flex flex-wrap mr-2">
          {isQuickSellAllowed && (
            <QuickSaleButton
              className="mr-2 w-28 mt-2"
              twoLines
              onClick={() => openQuickSaleDialog()}
              disabled={isMultiBookingMode || isUnitedBookingUpdateMode}
            />
          )}
          {isBookingsSelectionDialogAllowed && (
            <ButtonWithBillingCheck
              className="mr-2 w-28 mt-2"
              type="outline"
              twoLines
              onClick={() => openBookingsSelectionDialog({ date: pickedDate })}
              disabled={isMultiBookingMode || isUnitedBookingUpdateMode}
              Icon={IoPersonOutline}
            >
              {t('selectionOfBookings')}
            </ButtonWithBillingCheck>
          )}
          {isBookingEditingAllowed && (
            <ButtonWithBillingCheck
              className="mr-2 w-28 mt-2"
              type="outline"
              twoLines
              onClick={() =>
                openCreateGroupBookingDialog({
                  startDate: timezone ? fromZonedTime(pickedDate, timezone) : pickedDate,
                  employeeId: undefined,
                  locationId: undefined,
                })
              }
              disabled={isMultiBookingMode || isUnitedBookingUpdateMode}
              Icon={IoPeopleCircleOutline}
            >
              {t('groupBooking.name')}
            </ButtonWithBillingCheck>
          )}
          {isCalendarAlwaysOpen ? null : (
            <DateTimePicker
              timezone={timezone ?? DEFAULT_TIMEZONE}
              value={pickedDate}
              onChange={setPickedDate}
              className="mr-2 mt-2 transition-all w-60"
              type={isWeeklyView ? 'week' : 'date'}
              disabled={isUnitedBookingUpdateMode}
              nextPreviousButtons
              showQuickButtons
            />
          )}
          <CalendarTypeSelect
            calendarType={currentCalendarType}
            setCalendarType={id => {
              if (id !== currentCalendarType) {
                resetPreset()
                if (id === 'locations') {
                  selectEmployee(undefined)
                } else if (id === 'employees') {
                  setSelectedEmployees([])
                }
                setCurrentCalendarType(id)
              }
            }}
          />
          {currentCalendarType === 'locations' ? (
            <TreeSelect
              type="MultiPickMode"
              items={treeItems}
              onSelected={value => {
                resetPreset()
                setSelectedEmployees(value)
              }}
              selected={selectedEmployees}
              className="w-52 mt-2"
              placeholder={t('filter.employee')}
              displayedItems={1}
              isFilter
            />
          ) : (
            <EmployeeSelect
              employeeId={selectedEmployeeId}
              setEmployeeId={value => {
                resetPreset()
                selectEmployee(value)
              }}
            />
          )}
          <MultiSelect
            items={locations ?? []}
            onItemSelect={value => {
              resetPreset()
              setSelectedLocations(value as ServerLocationWithServiceIdsType[])
            }}
            selectedItems={selectedLocations}
            placeholder={t('filter.location')}
            className="w-48 mt-2 ml-2"
            displayedItems={1}
            isFilter
          />
          <BookingPresetSelect
            value={presetId}
            onChange={setPresetId}
            className="self-center ml-2 mt-2"
            calendarType={currentCalendarType}
            setCalendarType={setCurrentCalendarType}
            employeeId={selectedEmployeeId}
            setEmployeeId={selectEmployee}
            employees={selectedEmployees.map(employee => ({
              id: employee.id,
              isFolder: employee.isFolder ?? false,
            }))}
            setEmployees={presetEmployees => {
              setSelectedEmployees(getFullInfoForEmployees(presetEmployees, treeItems))
            }}
            locations={selectedLocations.map(({ id }) => ({ id }))}
            setLocations={presetLocations => {
              setSelectedLocations(getFullInfoForLocations(presetLocations, locations ?? []))
            }}
            columnsAreShrinked={columnsAreShrinked}
            setColumnsAreShrinked={setColumnsAreShrinked}
          />
          {isDynamicDateCreationAllowed && currentCalendarType === 'employees' && (
            <AddWorkingDayToEmployeesButton
              onClick={() =>
                openAddWorkingDayToEmployeesDialog({
                  date: pickedDate,
                })
              }
              className="self-center ml-2 mt-2"
            />
          )}
        </div>
        <button
          onClick={() => openBookingCardSettingsDialog()}
          className="transition-colors h-5 text-primary-500 hover:text-primary-700 mt-4 self-start"
        >
          <CogIcon size="1.5rem" />
        </button>
      </div>

      <div className="my-2 isolate mx-4 flex-1 flex min-w-max">
        <NoDataPlaceholder
          calendarType={currentCalendarType}
          isEmployeesLoading={isEmployeeGroupsLoading}
          isLocationsLoading={isLocationsLoading}
          thereAreEmployees={thereAreEmployees}
          thereAreLocations={thereAreLocations}
          myPermissions={myPermissions}
        />
        {isCalendarAlwaysOpen && thereAreLocations && timezone !== undefined && (
          <div className="sticky bg-gray-50 dark:bg-gray-700 z-10 left-0 border-calendar-cell border-t h-full">
            <div className="sticky top-2.5">
              <DatePicker
                timezone={timezone}
                disabled={isUnitedBookingUpdateMode}
                value={pickedDate}
                onChange={setPickedDate}
                activeStartDate={activeStartDate}
                setActiveStartDate={setActiveStartDate}
                view={view}
                setView={setView}
                type={currentCalendarType === 'employees' && selectedEmployeeId ? 'week' : 'date'}
                className="mb-3"
              />
              <DatePicker
                timezone={timezone}
                disabled={isUnitedBookingUpdateMode}
                className="sticky top-0 border-t border-calendar-cell"
                value={pickedDate}
                onChange={setPickedDate}
                activeStartDate={addMonths(activeStartDate, 1)}
                setActiveStartDate={date => setActiveStartDate(subMonths(date as Date, 1))}
                view={view}
                setView={setView}
                type={currentCalendarType === 'employees' && selectedEmployeeId ? 'week' : 'date'}
              />
              <QuickDateButtons
                timezone={timezone}
                value={pickedDate}
                onChange={setPickedDate}
                activeStartDate={activeStartDate}
                setActiveStartDate={setActiveStartDate}
                view={view}
                setView={setView}
                type={currentCalendarType === 'employees' && selectedEmployeeId ? 'week' : 'date'}
                className="border-b"
                disabled={isUnitedBookingUpdateMode}
              />
            </div>
          </div>
        )}
        {thereAreLocations && (currentCalendarType === 'locations' || thereAreEmployees) && (
          <BookingsCalendar
            type={currentCalendarType}
            date={pickedDate}
            selectedEmployeeId={selectedEmployeeId}
            selectedEmployees={employeesForCalendar}
            selectedLocations={selectedLocations}
            onTitleClick={selectEmployee}
            columnsAreShrinked={columnsAreShrinked}
            setColumnsAreShrinked={shrinked => {
              resetPreset()
              setColumnsAreShrinked(shrinked)
            }}
          />
        )}

        {bookingSelectionDialog}
        {bookingCardSettingsDialog}
        {quickSaleDialog}
        {groupBookingDialog}
        {multiBookingDialog}
        {addWorkingDayToEmployeesDialog}
      </div>
      {bookingMultiServicesDialog}
      {isUnitedBookingUpdateMode && (
        <UnitedBookingBar
          openMultiServicesCreateDialogFromCalendar={openMultiServicesCreateDialogFromCalendar}
        />
      )}
      {isMultiBookingMode && <MultiBookingBar onSubmit={openMultiBookingDialog} />}
    </>
  )
})

export type BookingCalendarType = 'locations' | 'employees'
