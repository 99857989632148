import { DateTimePicker } from '@expane/widgets'
import { FC, useRef } from 'react'
import {
  Button,
  CloseButton,
  Dialog,
  InputLabel,
  Modal,
  NumberInput,
  RadioGroupButtons,
  SelectDropdown,
  usePopupOpenState,
} from '@expane/ui'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { translateData } from 'logic/utils'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { useFetchCurrentBranchTimezone } from '@expane/data'
import { SaveButton } from 'widgets/Buttons'
import { IoArrowUndoOutline } from 'react-icons/io5'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import {
  showIntegerNumberFieldErrorMessage,
  showRecurringIntervalFieldErrorMessage,
} from 'logic/form'
import {
  validateMaxDayInterval,
  validateMaxMonthInterval,
  validateMaxWeekInterval,
  validateValueIsIntegerNumber,
} from '@expane/logic/form'
import { getWeekDay } from '@expane/logic/calendar'

import {
  END_TYPE,
  endTypes,
  getShortWeekdays,
  INTERVAL_TYPE,
  intervalTypes,
  RecurringType,
  RecurringWeekDay,
} from '@expane/logic/recurringBookings'
import { addYears, getHours, getMinutes, isDateSameDayOrAfter, set } from '@expane/date'

const RecurringDialog: FC<{
  onSave: (recurring: RecurringType | null) => void
  recurring: RecurringType | null
  startDate: Date
  closeDialog: () => void
}> = ({ onSave, recurring, startDate, closeDialog }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { control, setValue, formState, reset, handleSubmit } = useForm<RecurringType>({
    defaultValues: {
      interval: recurring?.interval ?? '',
      intervalType: recurring?.intervalType ?? null,
      intervalWeekDays: recurring?.intervalWeekDays ?? null,
      endType: recurring?.endAfterQtyRepeat ? endTypes[1].id : endTypes[0].id,
      endDate: recurring?.endDate ?? null,
      endAfterQtyRepeat: recurring?.endAfterQtyRepeat ?? '',
    },
  })

  const { t } = useTranslation()

  const watchedEndType = useWatch({ control, name: 'endType' })
  const watchedIntervalType = useWatch({ control, name: 'intervalType' })
  const isWatchedIntervalTypeWeek = watchedIntervalType === INTERVAL_TYPE.week

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const handleOnSave: SubmitHandler<RecurringType> = async data => {
    onSave(data)
    closeDialog()
  }
  const onCancel = () => {
    reset()
    onSave(null)
    closeDialog()
  }

  if (!timezone) return null

  return (
    <Modal close={closePopups} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('recurringBookings.title')}</Dialog.Title>
        <Dialog.Body className={'w-128'}>
          <div>
            <div className={'w-full flex justify-between items-center gap-2'}>
              <InputLabel
                label={t('recurringBookings.repeatWithIntervals')}
                className={'w-1/2 mb-4'}
              />

              <div className={'w-1/2 flex gap-2'}>
                <Controller
                  control={control}
                  name="interval"
                  rules={{
                    required: true,
                    validate: {
                      checkValueIsIntegerNumber: value =>
                        validateValueIsIntegerNumber(value?.toString() ?? ''),
                      checkMaxDayInterval: value => {
                        if (value && watchedIntervalType === INTERVAL_TYPE.day)
                          return validateMaxDayInterval(value)
                      },
                      checkMaxWeekInterval: value => {
                        if (value && watchedIntervalType === INTERVAL_TYPE.week)
                          return validateMaxWeekInterval(value)
                      },
                      checkMaxMonthInterval: value => {
                        if (value && watchedIntervalType === INTERVAL_TYPE.week)
                          return validateMaxMonthInterval(value)
                      },
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <NumberInput
                      value={value}
                      onChange={onChange}
                      containerClassName="w-2/5"
                      errorMessage={{
                        isShown: Boolean(error),
                        text: showRecurringIntervalFieldErrorMessage(error?.type ?? '', t),
                        reserveIndent: false,
                      }}
                      autoFocus
                      allowDecimals={false}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="intervalType"
                  rules={{ required: true }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SelectDropdown
                      items={translateData(intervalTypes, t)}
                      required
                      className="flex-1"
                      onSelectChange={item => {
                        if (item === INTERVAL_TYPE.week) {
                          const days = getShortWeekdays(startDate)
                          // id это порядковый номер в неделе
                          const day = days.find(day => day.id === getWeekDay(startDate))

                          if (day) setValue('intervalWeekDays', [day])
                        }

                        onChange(item)
                      }}
                      value={value}
                      errorMessage={{
                        isShown: Boolean(error),
                        text: t('formError.required'),
                      }}
                      isClearable
                    />
                  )}
                />
              </div>
            </div>

            {isWatchedIntervalTypeWeek ? (
              <Controller
                control={control}
                name="intervalWeekDays"
                render={({ field: { onChange, value } }) => (
                  <DaysOfTheWeekPicker selected={value} onChange={onChange} startDate={startDate} />
                )}
              />
            ) : null}

            <InputLabel label={t('recurringBookings.end')} className={'mt-4'} />

            <div className={'flex items-center justify-between mt-4 gap-2'}>
              <Controller
                control={control}
                name="endType"
                render={({ field: { onChange, value } }) => (
                  <RadioGroupButtons
                    options={translateData(Object.values(endTypes), t)}
                    value={endTypes.find(type => type.id === value)}
                    onChange={option => {
                      if (option.id === END_TYPE.date) setValue('endAfterQtyRepeat', '')
                      else setValue('endDate', null)

                      onChange(option.id)
                    }}
                    className={'w-1/2'}
                  />
                )}
              />

              {!watchedEndType || watchedEndType === END_TYPE.date ? (
                <Controller
                  control={control}
                  name="endDate"
                  rules={{
                    required: true,
                    validate: {
                      checkMaxDate: value => {
                        if (value) {
                          return isDateSameDayOrAfter(addYears(startDate, 1), value)
                        }

                        return false
                      },
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DateTimePicker
                      timezone={timezone}
                      required
                      value={value ?? undefined}
                      type="date"
                      onChange={date =>
                        onChange(
                          set(date, {
                            hours: getHours(startDate),
                            minutes: getMinutes(startDate),
                          }),
                        )
                      }
                      className="w-1/2"
                      errorMessage={{
                        isShown: Boolean(error),
                        text: showRecurringIntervalFieldErrorMessage(error?.type ?? '', t),
                        reserveIndent: false,
                      }}
                    />
                  )}
                />
              ) : (
                <div className="flex items-center w-1/2 gap-2">
                  <Controller
                    control={control}
                    name="endAfterQtyRepeat"
                    rules={{
                      required: true,
                      validate: {
                        checkValueIsIntegerNumber: value =>
                          validateValueIsIntegerNumber(value?.toString() ?? ''),
                      },
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <NumberInput
                        value={value}
                        onChange={onChange}
                        containerClassName="w-2/5"
                        errorMessage={{
                          isShown: Boolean(error),
                          text: showIntegerNumberFieldErrorMessage(error?.type ?? '', t),
                          reserveIndent: false,
                        }}
                        autoFocus
                        allowDecimals={false}
                      />
                    )}
                  />

                  <InputLabel label={t('recurringBookings.repeats')} />
                </div>
              )}
            </div>
          </div>
        </Dialog.Body>

        <Dialog.Footer>
          <SaveButton onClick={handleSubmit(handleOnSave)} />

          {recurring ? (
            <Button type="danger" onClick={onCancel} Icon={IoArrowUndoOutline}>
              {t('recurringBookings.cancel')}
            </Button>
          ) : null}

          <CloseButton onClick={closePopups} className="mr-auto" />
        </Dialog.Footer>
      </Dialog>
      {confirmPopup}
    </Modal>
  )
}

const DaysOfTheWeekPicker: FC<{
  selected: RecurringWeekDay[] | null
  onChange: (selected: RecurringWeekDay[] | null) => void
  startDate: Date
}> = ({ selected, onChange, startDate }) => {
  const days = getShortWeekdays(startDate)

  const handleSelectDays = (item: RecurringWeekDay) => {
    const isDaySelected = selected?.find(selectedDay => item?.id === selectedDay.id)

    if (isDaySelected)
      onChange(
        selected?.filter((selectedDay: RecurringWeekDay) => selectedDay.id !== item?.id) ?? null,
      )
    else {
      if (selected !== null) onChange([...selected, item])
      else onChange([item])
    }
  }

  return (
    <div className={'flex gap-2'}>
      {days.map((day, index) => {
        const isDaySelected = selected?.find(prev => day?.id === prev.id)

        return (
          <button key={index} onClick={() => handleSelectDays(day)}>
            <p
              className={`${
                isDaySelected ? 'bg-table-header text-table-title-color' : 'text-label-color'
              } font-medium text-sm rounded-full w-6 h-6 flex-centered flex-col`}
            >
              {day.shortDay}
            </p>
          </button>
        )
      })}
    </div>
  )
}

export const useOpenRecurringDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()
  const onSaveDialog = useRef<(data: RecurringType | null) => void>()
  const recurringData = useRef<RecurringType | null>()
  const bookingStartDate = useRef<Date | null>()

  const openRecurringDialog = (
    onSave: (data: RecurringType | null) => void,
    recurring: RecurringType | null,
    startDate: Date,
    onClose?: () => void,
  ) => {
    onCloseDialog.current = onClose
    onSaveDialog.current = onSave
    recurringData.current = recurring
    bookingStartDate.current = startDate
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const recurringDialog =
    isOpen && onSaveDialog.current && bookingStartDate.current ? (
      <RecurringDialog
        onSave={onSaveDialog.current}
        closeDialog={closeDialog}
        recurring={recurringData.current ?? null}
        startDate={bookingStartDate.current}
      />
    ) : null

  return { openRecurringDialog, recurringDialog }
}
