import {
  TRANSACTION_TYPES,
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
  useFetchProductsForConsumables,
} from '@expane/data'
import { countProductAmountsInTransactions } from '@expane/logic/booking'
import { Consumable } from '@expane/logic/payment/booking'
import { permissions } from '@expane/logic/permission'
import { useGetUnitsNameForConsumable } from '@expane/logic/product'
import {
  AddButtonHeaderCell,
  DeleteButtonCell,
  InputLabel,
  NumberInput,
  SelectDropdown,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useGetConsumableAmount, useGetConsumablesTotalAmount } from 'logic/consumable'
import { customSelectDropDownProductSearchFilterFunction } from 'logic/product'
import { FC } from 'react'
import {
  Control,
  Controller,
  FieldError,
  useFieldArray,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { store } from 'store'
import { BookingDialogFormValues } from 'widgets/BookingDialog/index'
import { OnCreateProductFunc, useOpenProductDialog } from 'widgets/ProductDialog'

interface ConsumablesBlockProps {
  bookingId: number | undefined
  control: Control<BookingDialogFormValues>
  disabled: boolean
}

export const ConsumablesBlock: FC<ConsumablesBlockProps> = ({ control, disabled, bookingId }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: booking } = useFetchBookingById(bookingId, timezone, branchId)
  const { data: myPermissions } = useFetchMyPermissions()

  const { openCreateDialog: openCreateProductDialog, dialog: productDialog } =
    useOpenProductDialog()

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'consumables',
  })

  const { errors } = useFormState({ control, name: 'consumables' })

  const handleAppend = () =>
    append({ productId: undefined, bookingProductId: undefined, quantity: DEFAULT_QUANTITY })

  const watchedConsumables = useWatch({ control, name: 'consumables' })

  const getUnitName = useGetUnitsNameForConsumable(branchId)
  const getProductAmount = useGetConsumableAmount()
  const getTotalAmount = useGetConsumablesTotalAmount()

  const paidProductAmounts = booking
    ? countProductAmountsInTransactions(
        booking.transactions.filter(
          transaction =>
            transaction.type === TRANSACTION_TYPES.payment.id ||
            transaction.type === TRANSACTION_TYPES.paymentInCredit.id,
        ),
      )
    : {}

  const refundedProductAmounts = booking
    ? countProductAmountsInTransactions(
        booking.transactions.filter(
          transaction => transaction.type === TRANSACTION_TYPES.refund.id,
        ),
      )
    : {}

  const isProductCreateAllowed = myPermissions?.includes(permissions.product.set)

  const getDisabledChangeConsumableWhenPaid = (consumable: Consumable) =>
    disabled ||
    (consumable.productId === undefined
      ? false
      : (paidProductAmounts[consumable.productId] ?? 0) -
          (refundedProductAmounts[consumable.productId] ?? 0) >
        0)

  const onCreate: OnCreateProductFunc = (id, name, forSale) => {
    if (forSale) {
      return
    } else {
      const index = watchedConsumables.findIndex(consumable => consumable.productId === undefined)
      remove(index)
      append({ productId: id, bookingProductId: undefined, quantity: DEFAULT_QUANTITY })
    }
  }

  return (
    <div className="mt-2 flex flex-col h-60">
      {fields.length ? (
        <>
          <InputLabel
            label={t('consumables')}
            onPlusClick={
              isProductCreateAllowed ? () => openCreateProductDialog(onCreate) : undefined
            }
          />
          <TableContainer tableClassName="w-full table-fixed">
            <TableHeader>
              <tr>
                <TableHeaderCell className="w-36 text-left">{t('title')}</TableHeaderCell>
                <TableHeaderCell className="w-16">{t('qty')}</TableHeaderCell>
                <TableHeaderCell className="w-8 text-right">{t('unit.shortName')}</TableHeaderCell>
                <TableHeaderCell className="w-16 text-right">{t('price')}</TableHeaderCell>
                <AddButtonHeaderCell onClick={handleAppend} disabled={disabled} />
              </tr>
            </TableHeader>
            <TableBody>
              {fields.map((consumable, index) => (
                <TableRow
                  key={consumable.id}
                  className="text-sm"
                  errorMessage={{
                    isShown: Boolean(errors.consumables?.[index]?.quantity),
                    text: getProductQuantityError(errors.consumables?.[index]?.quantity, t),
                  }}
                >
                  <TableCell>
                    <ProductSelect
                      control={control}
                      index={index}
                      disabled={getDisabledChangeConsumableWhenPaid(consumable)}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`consumables.${index}.quantity` as 'consumables.0.quantity'}
                      rules={{
                        required: true,
                        validate: {
                          biggerThanZero: value => Number(value) > 0,
                          paid: value => {
                            if (!consumable.productId) return true

                            return (
                              Number(value) >=
                              (paidProductAmounts[consumable.productId] ?? 0) -
                                (refundedProductAmounts[consumable.productId] ?? 0)
                            )
                          },
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <NumberInput
                          value={value}
                          onChange={onChange}
                          height="small"
                          disabled={disabled}
                          allowDecimals={false}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="text-right">
                    {t(getUnitName(watchedConsumables[index]?.productId))}
                  </TableCell>
                  <TableCell className="text-right">
                    {getProductAmount(
                      watchedConsumables[index]?.productId,
                      watchedConsumables[index]?.quantity,
                      watchedConsumables[index]?.price,
                    )}
                  </TableCell>
                  <DeleteButtonCell
                    className="w-6"
                    onClick={() => remove(index)}
                    disabled={getDisabledChangeConsumableWhenPaid(consumable)}
                  />
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <div className="p-2 text-right text-sm text-gray-500">
            {t('total')}: {getTotalAmount(watchedConsumables)}
          </div>
        </>
      ) : (
        !disabled && (
          <button
            onClick={handleAppend}
            className="ml-auto text-gray-500 hover:text-primary-400 mt-2 flex items-center"
          >
            <IoAddCircleOutline size="1.3rem" className="mr-1" />
            <p>{t('addConsumable')}</p>
          </button>
        )
      )}
      {productDialog}
    </div>
  )
}

type ProductSelectProps = {
  control: Control<BookingDialogFormValues>
  index: number
  disabled: boolean
}

const ProductSelect: FC<ProductSelectProps> = ({ control, index, disabled }) => {
  const branchId = store.branch.branchId
  const { data: products } = useFetchProductsForConsumables(branchId)

  const watchedConsumables = useWatch({ control, name: 'consumables' })

  return (
    <Controller
      control={control}
      rules={{ required: true }}
      name={`consumables.${index}.productId` as 'consumables.0.productId'}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const filteredItems = products?.filter(
          product =>
            product.id === value ||
            watchedConsumables.every(consumable => consumable.productId !== product.id),
        )

        return (
          <SelectDropdown
            value={value}
            onSelectChange={onChange}
            items={filteredItems}
            errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
            height="small"
            disabled={disabled}
            customSearchFilterFunction={customSelectDropDownProductSearchFilterFunction}
          />
        )
      }}
    />
  )
}

const DEFAULT_QUANTITY = '1'

const getProductQuantityError = (error: FieldError | undefined, t: TFunction) => {
  if (error?.type === 'required') return t('formError.required')
  if (error?.type === 'biggerThanZero') return t('formError.invalidValue')
  if (error?.type === 'paid') return t('formError.lessThanPaid')

  return ' '
}
