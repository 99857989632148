import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { firebase } from '../firebase'

export const initSentry = ({
  dsn,
  release,
  environment,
}: {
  dsn: string
  environment: string
  release: string
}) => {
  Sentry.init({
    dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    environment,
    release,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.75,
  })
}

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const reportError =
  (isLocalRun: boolean) =>
  (e: unknown, level: Sentry.SeverityLevel = 'error', extra?: Record<string, unknown>) => {
    if (isLocalRun) {
      // eslint-disable-next-line no-console
      console.error(e, level, extra)
    } else {
      const firebaseUser = firebase.getCurrentUser()
      const claims = firebaseUser ? firebase.getXHasuraClaims() : {}

      const user = firebaseUser ? { id: firebaseUser.uid } : undefined
      Sentry.withScope(scope => {
        scope.setTags(claims)
        Sentry.captureException(e, { extra, level, user })
      })
    }
  }

export const reportGqlError = (isLocalRun: boolean) => (e: unknown, variables?: unknown) => {
  if (e instanceof Object && Object.hasOwn(e, 'response')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const textError = JSON.stringify(e.response, null, 2)
    return reportError(isLocalRun)(new Error(textError), 'error', {
      variables: JSON.stringify(variables, null, 2),
    })
  } else {
    reportError(isLocalRun)(new Error('Unknown GqlError'), 'warning', {
      unknownError: JSON.stringify(e, null, 2),
      variables: JSON.stringify(variables, null, 2),
    })
  }
}
