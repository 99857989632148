import { queryClient, reportError, reportGqlError, request, useMutation, useQuery } from '../../api'
import {
  ServerCardTemplate,
  ServerCardTemplateBriefType,
  ServerCardTemplateInsertInput,
  ServerCardTemplateMutationResponse,
  ServerCardTemplateServiceInsertInput,
  ServerCardTemplateServiceMutationResponse,
  ServerCardTemplateSetInput,
  ServerCardTemplateType,
  ServerSubscriptionType,
} from '../../generated/graphql-types'
import { CARD_TEMPLATES_KEY } from './queryKeys'
import { BRANCHES_QUERY_KEY } from '../branch/queryKeys'
import { gql } from 'graphql-request'
import {
  cardTemplateBriefFragment,
  cardTemplateFragment,
} from '../cardTemplate/cardTemplate.fragments'
import { SubscriptionInfo } from '../card'
import { parseDatesInCardGqlResponse } from '../card/logic'
import { DEFAULT_TIMEZONE } from '@expane/date'
import { ARCHIVED_CARD_TEMPLATES_QUERY_KEY, CARDS_BRIEFS_QUERY_KEY } from '../card/queryKeys'

export const CARD_TYPES = {
  subscription: 1,
  giftCard: 2,
}

export type CardTemplateWithSubscriptionInfoType = Omit<ServerCardTemplateType, 'cards'> & {
  cards: Array<{
    id: number
    activatedAt?: Date | undefined
    canceledDate?: Date | undefined
    createdAt: Date
    subscriptionInfo: SubscriptionInfo
    cardPeriod: number
  }>
}

export interface SubscriptionType extends ServerSubscriptionType {
  subscriptionInfo: SubscriptionInfo
  type: 1 // CARD_TYPES.subscription
}

function useFetchCardTemplates(timezone: string | undefined, branchId: number | undefined) {
  return useQuery<ServerCardTemplateType[]>(
    [CARD_TEMPLATES_KEY, BRANCHES_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${cardTemplateFragment}
          query ($branchId: Int!) {
            cardTemplates(
              order_by: { createdAt: desc }
              where: {
                archived: { _is_null: true }
                _or: [{ branchId: { _eq: $branchId } }, { availableToAllBranches: { _eq: true } }]
              }
            ) {
              ...cardTemplateType
            }
          }
        `,
        { branchId },
      )
      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates.map(cardTemplate => ({
          ...cardTemplate,
          cards: cardTemplate.cards.map(card =>
            parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE),
          ),
        }))
      }

      reportError(new Error('cards is not an array'), 'error')
      return []
    },
    {
      queryName: 'useFetchCardTemplates',
      onError: reportGqlError,
      onSuccess: cards =>
        cards.forEach(card => queryClient.setQueryData([CARD_TEMPLATES_KEY, card.id], card)),
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

function useFetchCardTemplatesBriefs(branchId: number | undefined) {
  return useQuery<ServerCardTemplateBriefType[]>(
    [CARDS_BRIEFS_QUERY_KEY, CARD_TEMPLATES_KEY, BRANCHES_QUERY_KEY, { branchId }],

    async () => {
      const result = await request(
        gql`
          ${cardTemplateBriefFragment}
          query ($branchId: Int!) {
            cardTemplates(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
            ) {
              ...cardTemplateBriefType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates
      } else {
        reportError(new Error('cards brief is not an array'), 'error')
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchCardTemplatesBriefs',
      onError: reportGqlError,
    },
  )
}

function useFetchArchivedCardTemplates(branchId: number | undefined) {
  return useQuery<ServerCardTemplateType[]>(
    [ARCHIVED_CARD_TEMPLATES_QUERY_KEY, BRANCHES_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${cardTemplateFragment}
          query ($branchId: Int!) {
            cardTemplates(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }
            ) {
              ...cardTemplateType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.cardTemplates)) {
        return result.cardTemplates
      } else {
        reportError(new Error('archived cards is not an array'), 'error')
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchArchivedCardTemplates',
      onError: reportGqlError,
      onSuccess: cards =>
        cards.forEach(card =>
          queryClient.setQueryData([ARCHIVED_CARD_TEMPLATES_QUERY_KEY, card.id], card),
        ),
    },
  )
}

export function useFetchSubscriptions(timezone: string | undefined, branchId: number | undefined) {
  const cards = useFetchCardTemplates(timezone, branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.subscription) }
}

export function useFetchSubscriptionsBriefs(branchId: number | undefined) {
  const cards = useFetchCardTemplatesBriefs(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.subscription) }
}

export function useFetchArchivedSubscriptions(branchId: number | undefined) {
  const cards = useFetchArchivedCardTemplates(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.subscription) }
}

export function useFetchGiftCards(timezone: string | undefined, branchId: number | undefined) {
  const cards = useFetchCardTemplates(timezone, branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.giftCard) }
}

export function useFetchGiftCardsBriefs(branchId: number | undefined) {
  const cards = useFetchCardTemplatesBriefs(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.giftCard) }
}

export function useFetchArchivedGiftCards(branchId: number | undefined) {
  const cards = useFetchArchivedCardTemplates(branchId)

  return { ...cards, data: cards.data?.filter(card => card.type === CARD_TYPES.giftCard) }
}

export function useFetchCardTemplateById(id: number | undefined, timezone: string | undefined) {
  return useQuery<CardTemplateWithSubscriptionInfoType | undefined>(
    [CARD_TEMPLATES_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${cardTemplateFragment}
          query ($id: Int!) {
            cardTemplateById(id: $id) {
              ...cardTemplateType
            }
          }
        `,
        { id },
      )

      if (result?.cardTemplateById) {
        return {
          ...result.cardTemplateById,
          cards: result.cardTemplateById.cards.map(card =>
            parseDatesInCardGqlResponse(card, timezone ?? DEFAULT_TIMEZONE),
          ),
        }
      } else {
        reportError(new Error('cardById does not exist'), 'warning', {
          id,
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchCardById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}

async function requestCreateCardTemplate(
  cardTemplateInsertInput: ServerCardTemplateInsertInput,
): Promise<{ insertCardTemplate?: { id?: number } }> {
  return request(
    gql`
      mutation ($cardTemplateInsertInput: cardTemplate_insert_input!) {
        insertCardTemplate(object: $cardTemplateInsertInput) {
          id
        }
      }
    `,
    { cardTemplateInsertInput },
  )
}

export function useCreateSubscriptionTemplate() {
  const createCard = (
    cardCardTemplateInsertInput: ServerCardTemplateInsertInput &
      Required<
        Pick<
          ServerCardTemplateInsertInput,
          'name' | 'cardPeriod' | 'price' | 'cardTemplateServices' | 'availableToAllBranches'
        >
      >,
  ) => requestCreateCardTemplate({ ...cardCardTemplateInsertInput, type: CARD_TYPES.subscription })

  return useMutation(createCard, {
    onSuccess: () => queryClient.invalidateQueries([CARD_TEMPLATES_KEY]),
    onError: reportGqlError,
  })
}

export function useCreateGiftCardTemplate() {
  const createCard = (
    cardCardTemplateInsertInput: Required<
      Pick<ServerCardTemplateInsertInput, 'name' | 'cardPeriod' | 'price' | 'branchId'>
    >,
  ) => requestCreateCardTemplate({ ...cardCardTemplateInsertInput, type: CARD_TYPES.giftCard })

  return useMutation(createCard, {
    onSuccess: () => queryClient.invalidateQueries([CARD_TEMPLATES_KEY]),
    onError: reportGqlError,
  })
}

async function requestUpdateCardTemplate(dto: {
  id: number
  cardTemplateSetInput: ServerCardTemplateSetInput
  cardTemplateServiceInsertInputs?: ServerCardTemplateServiceInsertInput[]
}): Promise<{
  updateCardTemplateById?: ServerCardTemplate
  deleteCardServices?: ServerCardTemplateServiceMutationResponse
  insertCardServices?: ServerCardTemplateServiceMutationResponse
}> {
  return request(
    gql`
      mutation (
        $id: Int!
        $cardTemplateSetInput: cardTemplate_set_input!
        $cardTemplateServiceInsertInputs: [cardTemplateService_insert_input!]!
        $includeUpdateCardServices: Boolean!
      ) {
        updateCardTemplateById(pk_columns: { id: $id }, _set: $cardTemplateSetInput) {
          id
          name
          price
          cardPeriod
        }
        deleteCardTemplateServices(where: { cardTemplateId: { _eq: $id } })
          @include(if: $includeUpdateCardServices) {
          affected_rows
        }
        insertCardTemplateServices(objects: $cardTemplateServiceInsertInputs)
          @include(if: $includeUpdateCardServices) {
          returning {
            serviceId
            servicePrice
            serviceQuantity
          }
        }
      }
    `,
    {
      ...dto,
      cardTemplateServiceInsertInputs: dto.cardTemplateServiceInsertInputs ?? [],
      includeUpdateCardServices: Boolean(dto.cardTemplateServiceInsertInputs),
    },
  )
}

export function useUpdateSubscriptionTemplate() {
  const updateCard = (dto: {
    id: number
    cardTemplateSetInput: ServerCardTemplateSetInput
    cardTemplateServiceInsertInputs: ServerCardTemplateServiceInsertInput[]
  }) => requestUpdateCardTemplate(dto)

  return useMutation(updateCard, {
    onSuccess: () => queryClient.invalidateQueries([CARD_TEMPLATES_KEY]),
    onError: reportGqlError,
  })
}

export function useUpdateCardTemplateName() {
  return useMutation(
    async (dto: {
      id: number
      name: string
    }): Promise<{ updateCardTemplatesName: ServerCardTemplateMutationResponse }> => {
      return request(
        gql`
          mutation ($id: Int!, $name: String!) {
            updateCardTemplatesName(where: { id: { _eq: $id } }, _set: { name: $name }) {
              affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([CARD_TEMPLATES_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateGiftCardTemplate() {
  const updateCard = (dto: { id: number; cardTemplateSetInput: ServerCardTemplateSetInput }) =>
    requestUpdateCardTemplate(dto)

  return useMutation(updateCard, {
    onSuccess: () => queryClient.invalidateQueries([CARD_TEMPLATES_KEY]),
    onError: reportGqlError,
  })
}

export function useArchiveCard() {
  return useMutation(
    async (dto: {
      id: number
      archived: boolean
    }): Promise<{ updateCardTemplateById: { id: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $archived: timestamptz) {
            updateCardTemplateById(pk_columns: { id: $id }, _set: { archived: $archived }) {
              id
            }
          }
        `,
        { id: dto.id, archived: dto.archived ? new Date().toISOString() : null },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CARD_TEMPLATES_KEY])
        queryClient.invalidateQueries([ARCHIVED_CARD_TEMPLATES_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
