import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerTimeOffReasonEmployeeGroupInsertInput,
  ServerTimeOffReasonInsertInput,
  ServerTimeOffReasonUpdates,
} from '../../generated/graphql-types'
import { TIME_OFF_REASONS_QUERY_KEY } from './queryKeys'

export const useCreateTimeOffReason = () => {
  return useMutation(
    (
      timeOffReasonInsertInput: ServerTimeOffReasonInsertInput,
    ): Promise<{ insertTimeOffReason: { id: number } }> => {
      return request(
        gql`
          mutation ($timeOffReasonInsertInput: timeOffReason_insert_input!) {
            insertTimeOffReason(object: $timeOffReasonInsertInput) {
              id
            }
          }
        `,
        { timeOffReasonInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([TIME_OFF_REASONS_QUERY_KEY]),
    },
  )
}

export function useMutateTimeOffReasons() {
  return useMutation(
    (dto: {
      timeOffReasonInsertInputs: ServerTimeOffReasonInsertInput[]
      timeOffReasonEmployeeGroupInsertInputs: ServerTimeOffReasonEmployeeGroupInsertInput[]
      timeOffReasonUpdates: ServerTimeOffReasonUpdates[]
      timeOffReasonIdsForDeleteEmployeeGroups: number[]
      includeMutateTimeOffReasonEmployeeGroups: boolean
    }): Promise<{
      deleteTimeOffReasonEmployeeGroups?: { affectedRows: number }
      insertTimeOffReasonEmployeeGroups?: { affectedRows: number }
      insertTimeOffReasons: { affectedRows: number }
      updateManyTimeOffReasons: { affectedRows: number }[] | { affectedRows: number }
    }> => {
      return request(
        gql`
          mutation (
            $timeOffReasonIdsForDeleteEmployeeGroups: [Int!]!
            $includeMutateTimeOffReasonEmployeeGroups: Boolean!
            $timeOffReasonEmployeeGroupInsertInputs: [timeOffReasonEmployeeGroup_insert_input!]!
            $timeOffReasonInsertInputs: [timeOffReason_insert_input!]!
            $timeOffReasonUpdates: [timeOffReason_updates!]!
          ) {
            deleteTimeOffReasonEmployeeGroups(
              where: { timeOffReasonId: { _in: $timeOffReasonIdsForDeleteEmployeeGroups } }
            ) @include(if: $includeMutateTimeOffReasonEmployeeGroups) {
              affectedRows: affected_rows
            }
            insertTimeOffReasonEmployeeGroups(objects: $timeOffReasonEmployeeGroupInsertInputs)
              @include(if: $includeMutateTimeOffReasonEmployeeGroups) {
              affectedRows: affected_rows
            }
            insertTimeOffReasons(objects: $timeOffReasonInsertInputs) {
              affectedRows: affected_rows
            }
            updateManyTimeOffReasons(updates: $timeOffReasonUpdates) {
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([TIME_OFF_REASONS_QUERY_KEY]),
    },
  )
}
