import {
  useFetchBranchById,
  useFetchBranches,
  useFetchEmployeeByIdExtended,
  useFetchEmployeesGroups,
  useFetchEmployeeTransactions,
} from '@expane/data'
import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { permissions } from '@expane/logic/permission'
import { transformPersonName } from '@expane/logic/utils'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { FieldValues, FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { HorizontalTabs } from 'ui/HorizontalTabs'
import { PlaceholderEditablePhoto, PlaceholderRangePicker } from 'ui/PlaceholderContent'
import { SaveButton } from 'widgets/Buttons'
import { HistoryTab } from 'widgets/EmployeeDialog/HistoryTab'
import { EmployeeDialogInfoTab } from 'widgets/EmployeeDialog/InfoTab'
import { SalarySettingsTab } from 'widgets/EmployeeDialog/SalarySettingsTab'
import { EmployeeDialogScheduleTab } from 'widgets/EmployeeDialog/ScheduleTab'
import { useServiceAndGroupsWithInterBranchServiceGroup } from '@expane/logic/service'

export interface OnCreateEmployeeDto {
  firstName: string
  lastName: string
  groupId: number
}

export const EmployeeDialog: FC<DialogProps<OnCreateEmployeeDto>> = observer(
  ({ id: employeeId, closeDialog, isCreate, onCreate }) => {
    const { t } = useTranslation()

    const tabs = employeeTabs.map(tab => ({ ...tab, label: t(tab.label) }))

    const branchId = store.branch.branchId
    const { data: currentBranch, isLoading: isLoadingCurrentBranch } = useFetchBranchById(branchId)
    const { data: branches, isLoading: isLoadingBranches } = useFetchBranches()
    const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
      currentBranch?.timezone,
      branchId,
    )
    const {
      services,
      serviceGroups,
      isLoading: isLoadingServices,
    } = useServiceAndGroupsWithInterBranchServiceGroup(t('interbranchService.groupName'), branchId)

    const { data: myPermissions } = useFetchMyPermissions()
    const { data: employeeById, isLoading: isLoadingEmployeeById } = useFetchEmployeeByIdExtended(
      employeeId,
      currentBranch?.timezone,
      branchId,
    )
    const { data: employeeTransactions, isLoading: isLoadingEmployeeTransactions } =
      useFetchEmployeeTransactions(employeeById?.id, currentBranch?.timezone, branchId)

    const { areManyEmployeesAllowed, isLoading: isLoadingCurrentMaxEmployeesInBusiness } =
      useAreManyEmployeesAllowed()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [tabFormState, setTabFormState] = useState<FormState<any> | null>(null)

    const [activeTabId, setActiveTabId] = useState(tabs[0].id)

    const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(tabFormState, closeDialog)

    const onSaveTabRef = useRef<OnSaveTab>(null)
    const handleDialogButtonSaveClick = () => onSaveTabRef.current?.()

    const isEditingAllowed =
      Boolean(myPermissions?.includes(permissions.employee.set)) && !employeeById?.archived

    // TODO: когда будет активным обновление контракта убрать activeTabId === 4
    const isSaveButtonDisabled =
      !(tabFormState?.isDirty ?? false) || tabFormState?.isSubmitting || activeTabId === 4

    const isLoading =
      (isLoadingEmployeeById && !isCreate) ||
      (isLoadingEmployeeTransactions && !isCreate) ||
      isLoadingCurrentBranch ||
      isLoadingEmployeeGroups ||
      isLoadingServices ||
      isLoadingBranches ||
      isLoadingCurrentMaxEmployeesInBusiness

    const isNoData =
      (!isCreate && !employeeById) ||
      !employeeGroups ||
      !services ||
      !serviceGroups ||
      !myPermissions ||
      !currentBranch ||
      !branches

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (isLoading) return <EmployeeDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    const disabledTabs: number[] = tabs
      .filter(tab => (tab.permission ? !myPermissions.includes(tab.permission) : false))
      .map(({ id }) => id)

    let dialogTitle = t('employee.name')
    if (!isCreate && employeeById && activeTabId !== 0)
      dialogTitle += ': ' + transformPersonName(employeeById)

    return (
      <>
        <Modal
          close={closePopups}
          confirm={() => {
            if (isEditingAllowed && !isSaveButtonDisabled) handleDialogButtonSaveClick()
          }}
          animation="onlyFadeOut"
        >
          <Dialog>
            <HorizontalTabs
              tabs={isCreate ? [] : tabs}
              activeTabId={activeTabId}
              disabledTabs={disabledTabs}
              setActiveTabId={id => {
                if (tabFormState?.isDirty) {
                  showConfirmation({
                    title: t('tabChange.name'),
                    description: t('tabChange.warning'),
                    onConfirm: () => {
                      onSaveTabRef.current = null
                      setTabFormState(null)
                      setActiveTabId(id)
                    },
                  })
                } else {
                  onSaveTabRef.current = null
                  setTabFormState(null)
                  setActiveTabId(id)
                }
              }}
              title={dialogTitle}
            />

            <Dialog.Body className="w-246 h-118">
              {activeTabId === 0 && (
                <EmployeeDialogInfoTab
                  timezone={currentBranch.timezone}
                  employeeById={employeeById}
                  employeeGroups={employeeGroups}
                  services={services}
                  serviceGroups={serviceGroups}
                  myPermissions={myPermissions}
                  currentBranch={currentBranch}
                  isCreate={isCreate}
                  onCreate={onCreate}
                  additionalProps={{ onSaveTabRef, tabFormState, setTabFormState, closeDialog }}
                  areAllowedManyEmployees={areManyEmployeesAllowed}
                />
              )}
              {employeeById && (
                <>
                  {activeTabId === 1 && (
                    <EmployeeDialogScheduleTab
                      employee={employeeById}
                      myPermissions={myPermissions}
                      isBusinessHasOneBranch={branches.length === 1}
                    />
                  )}
                  {activeTabId === 2 && <SalarySettingsTab employeeById={employeeById} />}
                  {activeTabId === 3 && (
                    <HistoryTab employeeTransactions={employeeTransactions} branchId={branchId} />
                  )}
                </>
              )}
            </Dialog.Body>

            <Dialog.Footer>
              {isEditingAllowed && (
                <SaveButton
                  onClick={handleDialogButtonSaveClick}
                  disabled={isSaveButtonDisabled}
                  spinner={tabFormState?.isSubmitting}
                  isCreate={isCreate}
                />
              )}

              <CloseButton onClick={closePopups} disabled={tabFormState?.isSubmitting ?? false} />
            </Dialog.Footer>
          </Dialog>
        </Modal>

        {confirmPopup}
        {confirmationModal}
      </>
    )
  },
)

const EmployeeDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('employee.name')} className="w-246 h-118" closeDialog={closeDialog}>
      <div className="flex justify-between">
        <div className="w-3/4">
          <div className="flex justify-between mb-4">
            <PlaceholderInput label={t('lastName')} className="pr-2 w-1/3" />
            <PlaceholderInput label={t('firstName')} className="w-1/3" />
            <PlaceholderInput label={t('middleName')} className="pl-2 w-1/3" />
          </div>

          <PlaceholderInput label={t('category')} className="w-1/2 mb-4" />
        </div>

        <PlaceholderEditablePhoto size="big" className="ml-6 grow flex-centered" />
      </div>

      <div className="flex w-full w-">
        <div className="flex w-3/4 pb-4">
          <PlaceholderInput label={t('phone')} className="w-1/3 mr-2" />
          <PlaceholderInput label={t('email')} className="w-1/3 mr-2" />
          <PlaceholderInput label={t('birthDate')} className="w-1/3" />
        </div>

        <PlaceholderRangePicker className="ml-6 grow flex-centered" />
      </div>

      <div className="flex w-3/4">
        <PlaceholderTextarea label={t('status')} className="w-1/2 mr-2" />
        <PlaceholderTextarea label={t('information')} className="w-1/2" />
      </div>
    </PlaceholderDialog>
  )
}

interface EmployeeTabs {
  id: number
  label: string
  permission?: string
}

export type OnSaveTab = (() => void) | null

export interface EmployeeTabAdditionalSavingProps<T extends FieldValues> {
  additionalProps: {
    onSaveTabRef: MutableRefObject<OnSaveTab>
    tabFormState: FormState<T> | null
    setTabFormState: (fS: FormState<T>) => void
    closeDialog: () => void
  }
}

interface AttachFunctionAndFormStateProps<T extends FieldValues>
  extends EmployeeTabAdditionalSavingProps<T> {
  calledFunction: () => void
  formState: FormState<T>
}

export function useAttachFunctionAndFormState<T extends FieldValues>({
  calledFunction,
  formState,
  additionalProps,
}: AttachFunctionAndFormStateProps<T>) {
  useEffect(() => {
    additionalProps.onSaveTabRef.current = calledFunction
  }, [additionalProps.onSaveTabRef, calledFunction])

  useEffect(() => {
    additionalProps.setTabFormState(formState)
  }, [additionalProps, formState, formState.isDirty, formState.isSubmitting])
}

const employeeTabs: Array<EmployeeTabs> = [
  {
    id: 0,
    label: 'information',
  },
  {
    id: 1,
    label: 'schedule.name',
  },
  {
    id: 2,
    label: 'salary.name',
  },
  {
    id: 3,
    label: 'history',
    permission: permissions.transaction.get,
  },
]
