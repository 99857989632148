import { TimeOffReasonFormValues, TimeOffReasonItemInForm } from 'widgets/TimeOffReasonDialog/logic'
import {
  DeleteButtonInTable,
  ErrorMessage,
  Input,
  SelectDropDownItem,
  stopEnterPropagation,
  TableCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { MultiSelect } from 'ui/MultiSelect'
import { Control, Controller, UseFieldArrayRemove } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkOutline } from 'react-icons/io5'

interface TimeOffReasonRowProps {
  control: Control<TimeOffReasonFormValues>
  index: number
  remove: UseFieldArrayRemove
  employeeGroups: SelectDropDownItem[]
  myEmployeeGroupId: number
  areEditTimeOffReasonEmployeeGroupsEnabled: boolean
}

export const TimeOffReasonRow: FC<TimeOffReasonRowProps> = ({
  control,
  index,
  remove,
  employeeGroups,
  myEmployeeGroupId,
  areEditTimeOffReasonEmployeeGroupsEnabled,
}) => {
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBlur = (value: TimeOffReasonItemInForm, onChange: (...event: any[]) => void) => {
    onChange({ ...value, editable: false, updatable: true })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFocus = (value: TimeOffReasonItemInForm, onChange: (...event: any[]) => void) => {
    onChange({ ...value, editable: true })
  }

  return (
    <Controller
      name={`items.${index}` as 'items.0'}
      rules={{
        validate: {
          required: value => Boolean(value.name.length),
          isMyEmployeeGroupIdIsInSelectedEmployeeGroups: value => {
            if (!areEditTimeOffReasonEmployeeGroupsEnabled) return true
            if (value.employeeGroups.length === 0) return true

            return value.employeeGroups.some(
              employeeGroup => employeeGroup.id === myEmployeeGroupId,
            )
          },
        },
      }}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <>
          <TableRow
            className="h-10"
            onClick={value.editable ? undefined : () => onFocus(value, onChange)}
          >
            <TableCell>
              {value.editable ? (
                <div className={`flex items-center gap-1 ${value.editable ? '' : 'hidden'}`}>
                  <Input
                    autoFocus
                    onKeyDown={e => {
                      if (e.key === 'Enter') onBlur(value, onChange)
                    }}
                    containerClassName="grow"
                    height="small"
                    value={value.name}
                    onChange={e => {
                      onChange({ ...value, name: e.target.value })
                    }}
                    onBlur={() => {
                      if (value.name.trim()) onBlur(value, onChange)
                    }}
                    errorMessage={{
                      isShown: Boolean(error) && error?.type === 'nameNonEmpty',
                      text: '',
                      reserveIndent: false,
                    }}
                  />

                  {value.id ? (
                    <>
                      <div className="px-2 flex-centered h-full">
                        <button
                          onClick={() => onBlur(value, onChange)}
                          className={`rounded-full text-gray-300 transition-color hover:text-primary-500 focus:ring-1 focus:text-primary-500 ring-primary-500 ${
                            Boolean(error) || !value.name.trim()
                              ? 'pointer-events-none'
                              : 'cursor-pointer'
                          }`}
                          onKeyDown={stopEnterPropagation}
                          disabled={Boolean(error) || !value.name.trim()}
                        >
                          <IoCheckmarkOutline size="1.4rem" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <DeleteButtonInTable onClick={() => remove(index)} />
                  )}
                </div>
              ) : (
                <div className={`pl-3 pr-2 ${value.editable ? 'hidden' : ''}`}>{value.name}</div>
              )}
            </TableCell>
            <TableCell>
              <div onClick={e => e.stopPropagation()}>
                <MultiSelect
                  disabled={!areEditTimeOffReasonEmployeeGroupsEnabled}
                  items={employeeGroups ?? []}
                  onItemSelect={values => {
                    onChange({ ...value, employeeGroups: values, updatable: true })
                  }}
                  selectedItems={value.employeeGroups}
                  dropdownInputPlaceholder={t('placeholders.defaultSelect')}
                  placeholder={t('choose')}
                />
              </div>
            </TableCell>
          </TableRow>
          {Boolean(error) && (
            <TableRow>
              <TableCell colSpan={2}>
                {error?.type === 'required' && (
                  <ErrorMessage errorMessage={{ isShown: true, text: t('formError.required') }} />
                )}
                {error?.type === 'isMyEmployeeGroupIdIsInSelectedEmployeeGroups' && (
                  <ErrorMessage
                    errorMessage={{ isShown: true, text: t('timeOffReason.myEmployeeGroupError') }}
                  />
                )}
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    />
  )
}
