import { ServerSupplierType, useFetchSupplierById } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { CloseButton, Dialog, Modal, useShowConfirmationPopup } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { FieldValues, FormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { HorizontalTabs } from 'ui/HorizontalTabs'
import { SaveButton } from 'widgets/Buttons'
import { SupplierBankDetailsTab } from 'widgets/SupplierDialog/BankDetailsTab'
import { SupplierInfoTab } from 'widgets/SupplierDialog/InfoTab'
import { SupplierDialogPlaceholder } from 'widgets/SupplierDialog/SupplierDialogPlaceholder'
import { SupplierEmployeesTab } from 'widgets/SupplierDialog/SupplierEmployeesTab'

export const SupplierDialog: FC<DialogProps> = ({
  id: supplierId,
  closeDialog,
  isCreate,
  onCreate,
}) => {
  const { data: supplierById, isLoading: isLoadingSupplierById } = useFetchSupplierById(supplierId)
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const isLoading = (!isCreate && isLoadingSupplierById) || isLoadingMyPermissions
  const isNoData = (!isCreate && !supplierById) || !myPermissions

  if (isLoading) return <SupplierDialogPlaceholder closeDialog={closeDialog} />
  else if (isNoData) return null

  return (
    <SupplierDialogLogic
      closeDialog={closeDialog}
      isCreate={isCreate}
      onCreate={onCreate}
      supplierById={supplierById}
      myPermissions={myPermissions}
    />
  )
}

interface SupplierDialogLogicProps extends Omit<DialogProps, 'id'> {
  supplierById: ServerSupplierType | undefined
  myPermissions: string[]
}
export const SupplierDialogLogic: FC<SupplierDialogLogicProps> = ({
  closeDialog,
  isCreate,
  onCreate,
  supplierById,
  myPermissions,
}) => {
  const { t } = useTranslation()

  const translatedTabs = supplierTabs.map(tab => ({ ...tab, label: t(tab.label) }))

  const [activeTabId, setActiveTabId] = useState(translatedTabs[0].id)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tabFormState, setTabFormState] = useState<FormState<any> | null>(null)

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const onSaveTabRef = useRef<OnSaveTab>(null)

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(tabFormState, closeDialog)

  const handleDialogButtonSaveClick = () => {
    onSaveTabRef.current?.()
  }

  const changeDialogTab = (id: number): void => {
    onSaveTabRef.current = null
    setTabFormState(null)
    setActiveTabId(id)
  }

  const handleDialogTabChanging = (id: number): void => {
    if (tabFormState?.isDirty) {
      showConfirmation({
        title: t('tabChange.name'),
        description: t('tabChange.warning'),
        onConfirm: () => changeDialogTab(id),
      })
    } else changeDialogTab(id)
  }

  if (!isCreate && supplierById === undefined) return null

  const isEditingAllowed = isCreate || myPermissions?.includes(permissions.supplier.set)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !tabFormState?.isSubmitting && tabFormState?.isDirty)
            handleDialogButtonSaveClick()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <HorizontalTabs
            tabs={isCreate ? [] : translatedTabs}
            activeTabId={activeTabId}
            setActiveTabId={handleDialogTabChanging}
            title={t('supplier.name')}
          />

          <Dialog.Body className="w-192 min-h-120">
            {activeTabId === translatedTabs[0].id && (
              <SupplierInfoTab
                supplierById={supplierById}
                isCreate={isCreate}
                additionalProps={{
                  onSaveTabRef,
                  tabFormState,
                  setTabFormState,
                  closeDialog,
                }}
                disabled={!isEditingAllowed}
                onCreate={onCreate}
              />
            )}
            {activeTabId === translatedTabs[1].id && (
              <SupplierEmployeesTab
                supplierById={supplierById}
                additionalProps={{
                  onSaveTabRef,
                  tabFormState,
                  setTabFormState,
                  closeDialog,
                }}
                disabled={!isEditingAllowed}
              />
            )}

            {activeTabId === translatedTabs[2].id && (
              <SupplierBankDetailsTab
                supplierById={supplierById}
                additionalProps={{
                  onSaveTabRef,
                  tabFormState,
                  setTabFormState,
                  closeDialog,
                }}
                disabled={!isEditingAllowed}
              />
            )}
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleDialogButtonSaveClick}
                disabled={tabFormState?.isSubmitting || !tabFormState?.isDirty}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} disabled={tabFormState?.isSubmitting ?? false} />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
      {confirmationModal}
    </>
  )
}

const supplierTabs: Array<{ id: number; label: string }> = [
  {
    id: 0,
    label: 'information',
  },
  {
    id: 1,
    label: 'contactPersons.name',
  },
  {
    id: 2,
    label: 'requisites.name',
  },
]

export type OnSaveTab = (() => void) | null

export interface SupplierTabAdditionalSavingProps<T extends FieldValues> {
  additionalProps: {
    onSaveTabRef: MutableRefObject<OnSaveTab>
    tabFormState: FormState<T> | null
    setTabFormState: (fS: FormState<T>) => void
    closeDialog: () => void
  }
}

interface AttachFunctionAndFormStateProps<T extends FieldValues>
  extends SupplierTabAdditionalSavingProps<T> {
  calledFunction: () => void
  formState: FormState<T>
}

export function useAttachFunctionAndFormState<T extends FieldValues>({
  calledFunction,
  formState,
  additionalProps,
}: AttachFunctionAndFormStateProps<T>) {
  useEffect(() => {
    additionalProps.onSaveTabRef.current = calledFunction
  }, [additionalProps.onSaveTabRef, calledFunction])

  useEffect(() => {
    if (formState.isDirty && additionalProps.tabFormState?.isDirty !== true)
      additionalProps.setTabFormState(formState)
  }, [additionalProps, formState])
}
