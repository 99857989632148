import {
  CloudFunctionResponseCodes,
  MutateExtendBillingAsyncFunction,
  ServerPlanType,
  SubscriptionPeriodType,
  useFetchBusinessTurnover,
  useFetchEmployeesCount,
  useFetchLastBillingInfo,
} from '@expane/data'
import { createCurrentDate, isFuture } from '@expane/date'
import {
  checkIfAreBillingWarnings,
  generateBillingConfirmationDescription,
  generateBillingWarningMessage,
} from '@expane/logic/billing'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'
import { permissions } from '@expane/logic/permission'
import { useShowConfirmationPopup, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { AUTO_WFP_LANGUAGE, mapI18NLanguageToWFP, runWayForPay } from 'services/wayforpay'
import { store } from 'store'
import { SUPPORT_EMAIL, SUPPORT_PHONE } from '@expane/logic/links'
import { useConvertNumberToMoneyCodeManually } from '@expane/logic/currency'
import { getPercentageRate } from '@expane/logic/utils'
import { IoCalendarNumberOutline, IoSettingsOutline } from 'react-icons/io5'

export const PlanCard: FC<{
  plan: ServerPlanType
  subscriptionPeriod: SubscriptionPeriodType
  timezone: string
  isWayForPayError: boolean
  mutateExtendBilling: MutateExtendBillingAsyncFunction
  mutateExtendBillingIsLoading: boolean
}> = observer(
  ({
    plan,
    subscriptionPeriod,
    timezone,
    isWayForPayError,
    mutateExtendBilling,
    mutateExtendBillingIsLoading,
  }) => {
    const {
      i18n: { language },
    } = useTranslation()

    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const [showSnackbar] = useSnackbar()

    const convertNumberToMoneyCodeManually = useConvertNumberToMoneyCodeManually()

    const { getFeatureFlag } = useFeatureFlags()
    const isEnableCheckBillingTurnover = getFeatureFlag(FEATURE_FLAGS.enableCheckBillingTurnover)

    const { data: billingInfo } = useFetchLastBillingInfo(timezone)

    const currentPlan =
      Boolean(billingInfo) && plan.id === billingInfo?.planId && isFuture(billingInfo.toDate)

    const { data: myEmployee } = useFetchMyEmployee(timezone, branchId)
    const { data: myPermissions } = useFetchMyPermissions()
    const { data: currentTurnover } = useFetchBusinessTurnover(isEnableCheckBillingTurnover)
    const { data: employeeCount } = useFetchEmployeesCount()

    const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

    const isChangeBillingPlanAllowed = Boolean(myPermissions?.includes(permissions.billingInfo.set))

    const warningMessage = generateBillingWarningMessage({
      plan,
      currentTurnover: currentTurnover ?? 0,
      employeeCount: employeeCount ?? 0,
      isChangeBillingPlanAllowed,
      isEnableCheckBillingTurnover,
    })

    const { warningModal, showWarningPopup } = useShowWarningPopup(t('warning'), t(warningMessage))

    const onClickWithWarningMessage = (onClick: () => void) => () => {
      const isWarningMessage = Boolean(warningMessage)

      if (isWarningMessage) showWarningPopup()
      else onClick()
    }

    const onApprove = () => {
      showSnackbar(t('successBilling'), 'success', 8000)
    }

    const onClick = () => {
      if (isWayForPayError) {
        showSnackbar(t('paymentService.notAvailable'), 'error')
        return
      }

      const { isEmployeeWarning, isTurnoverWarning } = checkIfAreBillingWarnings({
        plan,
        currentTurnover: currentTurnover ?? 0,
        employeeCount: employeeCount ?? 0,
        isEnableCheckBillingTurnover,
      })

      if (isTurnoverWarning || isEmployeeWarning)
        showConfirmation({
          title: t('planConfirmation.title'),
          description: generateBillingConfirmationDescription(
            {
              currentTurnover: currentTurnover ?? 0,
              employeeCount: employeeCount ?? 0,
              plan,
              isEnableCheckBillingTurnover,
            },
            t,
          ),
          onConfirm: submitPaymentPlan,
        })
      else submitPaymentPlan()
    }

    const submitPaymentPlan = async () => {
      if (!myEmployee || !timezone) {
        showSnackbar(t('submitError'), 'error')
        return
      }

      const fromDate = createCurrentDate(timezone)
      try {
        const result = await mutateExtendBilling({
          period: subscriptionPeriod.period,
          planId: plan.id,
        })
        if (result.extendBilling.code === CloudFunctionResponseCodes.maxEmployeeError)
          showSnackbar(t('plan.warnings.employees'), 'error')
        else if (result.extendBilling.code === CloudFunctionResponseCodes.insufficientPermissions)
          showSnackbar(t('plan.warnings.permissions'), 'error')
        else if (result.extendBilling.code === CloudFunctionResponseCodes.refundExceedsFee)
          showSnackbar(t('plan.warnings.refundExceedsFee'), 'error')
        else if (
          result.extendBilling.code === CloudFunctionResponseCodes.successful &&
          result.extendBilling.billingId &&
          result.extendBilling.value &&
          result.extendBilling.paidMonths &&
          result.extendBilling.planId === plan.id
        ) {
          const productName =
            'Expane, ' +
            t('plan.name') +
            ' "' +
            t('plan.' + plan.name) +
            '", ' +
            result.extendBilling.paidMonths +
            t('monthShort')

          // email завжди повинен бути якщо цей співробітник авторизований
          // phone підставляємо наш якщо нема у співробітника
          runWayForPay(
            {
              productName: [productName],
              productCount: ['1'],
              productPrice: [result.extendBilling.value.toString()],
              amount: result.extendBilling.value.toString(),
              orderReference: result.extendBilling.billingId.toString(),
              clientEmail: myEmployee.email || SUPPORT_EMAIL,
              clientFirstName: myEmployee.firstName,
              clientLastName: myEmployee.lastName,
              clientPhone: (myEmployee.phone || SUPPORT_PHONE).replace(/\+|\s+/g, ''),
              orderDate: fromDate.getTime().toString(),
              currency: 'UAH',
              language: mapI18NLanguageToWFP[language] ?? AUTO_WFP_LANGUAGE,
            },
            { onApprove },
          )
        } else {
          showSnackbar(t('submitError'), 'error')
        }
      } catch (e) {
        reportError(e)
        showSnackbar(t('submitError'), 'error')
      }
    }

    const total = convertNumberToMoneyCodeManually(plan.monthlyCost * subscriptionPeriod.months, {
      currency: 'UAH',
      displayCoins: false,
    })

    const bonusPercentage = getPercentageRate(
      plan.monthlyCost * (subscriptionPeriod.months + subscriptionPeriod.additionalMonths),
      plan.monthlyCost * subscriptionPeriod.months,
    )

    return (
      <>
        <div
          className={`w-1/3 flex-col flex-centered text-gray-500 dark:text-gray-300 text-center p-4 border rounded-md ${
            subscriptionPeriod.months === PERIOD_IN_MIDDLE && currentPlan
              ? 'outline outline-primary-500'
              : 'border-gray-400'
          }`}
        >
          <p className={'font-medium text-2xl mb-2'}>
            {subscriptionPeriod.months} {t('monthShort')}
            {`${
              subscriptionPeriod.additionalMonths !== 0
                ? `+ ${subscriptionPeriod.additionalMonths} ${t('monthShort')} 🎁`
                : ''
            }`}
          </p>

          <div className="flex font-medium justify-center items-center gap-2">
            {total}{' '}
            {Boolean(bonusPercentage) && (
              <div className="bg-white dark:bg-primary-500/20 border-primary-600 border rounded-2xl text-xs text-primary-600 py-1 px-2">
                {bonusPercentage}% {t('bonus')}
              </div>
            )}
          </div>

          {currentPlan ? (
            <button
              onClick={onClickWithWarningMessage(submitPaymentPlan)}
              disabled={mutateExtendBillingIsLoading}
              className={
                buttonStyle +
                'dark:bg-primary-500/20 dark:hover:bg-primary-500/30 border-primary-500 bg-primary-25 hover:bg-primary-50 text-primary-600'
              }
            >
              <IoCalendarNumberOutline size={'1.2rem'} />
              {t('extend')}
            </button>
          ) : (
            <button
              onClick={onClickWithWarningMessage(onClick)}
              disabled={mutateExtendBillingIsLoading}
              className={
                buttonStyle +
                'bg-gray-50 dark:bg-gray-500/20 dark:hover:bg-gray-500/50 dark:text-gray-300 border-gray-300 text-gray-600 hover:bg-gray-100'
              }
            >
              <IoSettingsOutline size={'1.2rem'} />
              {t('changeBillingPlan')}
            </button>
          )}
        </div>

        {confirmationModal}
        {warningModal}
      </>
    )
  },
)

const buttonStyle = 'flex-centered gap-1 w-full border font-medium p-2 rounded-md mt-4 '

const PERIOD_IN_MIDDLE = 6
