import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { SalaryItem } from '@expane/logic/salaryIssues/converting'
import {
  getClassNameForArrow,
  getPlacementStylesForArrow,
  modalsHTMLElement,
  Spinner,
  useShowWarningPopup,
} from '@expane/ui'
import { arrow, offset, Side, useFloating } from '@floating-ui/react-dom'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoCheckmark } from 'react-icons/io5'
import { TransactionDialog } from 'widgets/TransactionDialog'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'

interface SalaryPayListProps {
  salaryItems: Array<SalaryItem>
  isLoading: boolean
  total: number
}

export const SalaryPayList: FC<PropsWithBranchId<SalaryPayListProps>> = ({
  salaryItems,
  isLoading,
  branchId,
  total,
}) => {
  const dateFormatting = useDateFormatting()
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('salary.noPaymentForTheService'),
  )

  const { warningModal: warningGroupModal, showWarningPopup: showWarningGroupModal } =
    useShowWarningPopup(t('warning'), t('salary.noShowedTransactionPaymentsForTheService'))

  if (isLoading) return <Spinner expandCentered />

  return (
    <>
      <TableContainer className={'w-full'}>
        <TableHeader>
          <tr>
            <TableHeaderCell />
            <TableHeaderCell>{t('dateTitle')}</TableHeaderCell>
            <TableHeaderCell>{t('name')}</TableHeaderCell>
            <TableHeaderCell>{t('additionalInfo.short')}</TableHeaderCell>
            <TableHeaderCell className="text-right">{t('salary.name')}</TableHeaderCell>
            <TableHeaderCell className="text-right">{t('salary.paid')}</TableHeaderCell>
          </tr>
        </TableHeader>
        <TableBody>
          {salaryItems.map(salaryItem => (
            <TableRow
              key={salaryItem.id}
              onClick={() => {
                if (salaryItem.transactionId) editTransactionDialog(salaryItem.transactionId)
                // нет salaryItem.transactionId в случае когда это групповая услуга и у нас нет одной конкрентной id оплаты
                // возможно потом добавить логику сохранения массива транзакций
                else if (
                  salaryItem.type === 'service' &&
                  !salaryItem.transactionId &&
                  salaryItem.isGroupService
                )
                  showWarningGroupModal()
                else if (salaryItem.type === 'service' && !salaryItem.transactionId)
                  showWarningPopup()
                else return
              }}
            >
              <TableCell className={'w-16'}>
                {salaryItem.type === 'service' && salaryItem.isCountedByBackUpFormula && (
                  <SalaryPayListInfo />
                )}
              </TableCell>
              <TableCell className={'w-32'}>
                {salaryItem.type === 'salaryRate' || salaryItem.type === 'referralPercentage'
                  ? ''
                  : dateFormatting('shortDate', salaryItem.date)}
              </TableCell>
              <TableCell className={'w-64'}>
                {salaryItem.type === 'salary' ||
                salaryItem.type === 'salaryRate' ||
                salaryItem.type === 'premium'
                  ? t(salaryItem.name)
                  : salaryItem.name}
              </TableCell>
              <TableCell className={'w-48'}>{salaryItem.additionalInfo}</TableCell>
              <TableCell className="text-right w-52">
                {convertNumberToMoney(salaryItem.totalSum ?? 0)}
              </TableCell>
              <TableCell className="text-right w-36">
                {salaryItem.isSalaryPaid || salaryItem.type === 'salary' ? (
                  <IoCheckmark size="1rem" />
                ) : (
                  ' '
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableCell colSpan={2} />
          <TableCell>{t('total')}</TableCell>
          <TableCell />
          <TableCell className="text-right ">{convertNumberToMoney(total)}</TableCell>
          <TableCell />
        </TableFooter>
      </TableContainer>
      {warningModal}
      {warningGroupModal}
      {transactionDialog}
    </>
  )
}

export const SalaryPayListInfo: FC = () => {
  const { t } = useTranslation()

  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const {
    reference,
    floating,
    strategy,
    x,
    y,
    middlewareData: { arrow: arrowData },
    placement,
  } = useFloating({
    middleware: [
      offset(5),
      arrow({
        element: arrowRef,
      }),
    ],
  })
  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData)
  const arrowClassName = getClassNameForArrow(side)

  return (
    <>
      <div
        ref={reference}
        onClick={e => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        onMouseLeave={() => setIsOpen(false)}
        className="cursor-pointer"
      >
        <IoAlertCircleOutline />
      </div>

      {isOpen &&
        createPortal(
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={messageStyle + ' w-64'}
          >
            <div ref={arrowRef} style={arrowPlacementStyles} className={arrowClassName} />

            <p>{t('salary.isCountedByBackUpFormula')}</p>
          </div>,
          modalsHTMLElement,
        )}
    </>
  )
}

const messageStyle =
  'max-w-sm p-2 text-sm rounded text-gray-500 dark:text-gray-400 bg-block border border-gray-500 space-y-1'
