import {
  BusinessSettingsType,
  ServerBusinessWithOwnerIdType,
  useFetchBusinessSettings,
  useFetchMyBusinesses,
  useUpdateBusinessById,
  useUpdateBusinessSettingsById,
} from '@expane/data'
import { useUpdateFile } from '@expane/logic/file'
import { Checkbox, Input, Spinner } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { observer } from 'mobx-react-lite'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'
import { ApplyButton } from 'widgets/Buttons'
import { EditablePhoto } from 'widgets/EditablePhoto'

export interface BusinessSettingsForm {
  name: string
  photo?: string
  hideClientsDeposit: boolean
}

export const BusinessSettings: FC = observer(() => {
  const currentBusinessId = store.me.businessId

  const { data: businesses, isLoading: areBusinessesLoading } = useFetchMyBusinesses(
    store.me.isAuthorised,
  )

  const { data: businessSettings, isLoading: areBusinessSettingsLoading } =
    useFetchBusinessSettings()

  const myBusiness = businesses?.find(business => business.id === currentBusinessId)

  if (areBusinessSettingsLoading || areBusinessesLoading) return <Spinner expandCentered />
  if (!myBusiness || !businessSettings) return null

  return <BusinessSettingsLogic myBusiness={myBusiness} businessSettings={businessSettings} />
})

export const BusinessSettingsLogic: FC<{
  myBusiness: ServerBusinessWithOwnerIdType
  businessSettings: BusinessSettingsType
  onApply?: () => void
}> = ({ myBusiness, businessSettings, onApply }) => {
  const { t } = useTranslation()

  const [openSnackBar] = useSnackbar()

  const { mutateAsync: updateBusinessById } = useUpdateBusinessById()
  const { mutateAsync: updateBusinessSettings } = useUpdateBusinessSettingsById()
  const updatePhoto = useUpdateFile()

  const {
    formState: { dirtyFields, isDirty, isSubmitting },
    control,
    handleSubmit,
  } = useForm<BusinessSettingsForm>({
    defaultValues: {
      name: myBusiness.name,
      photo: myBusiness?.photo ?? '',
      hideClientsDeposit: businessSettings.hideClientsDeposit ?? false,
    },
  })

  const handleUpdatingBusiness: SubmitHandler<BusinessSettingsForm> = async data => {
    const { name, photo } = data

    try {
      const photoUrl = await updatePhoto({
        prevFile: myBusiness?.photo ?? undefined,
        file: photo,
      })

      await updateBusinessById({
        id: myBusiness.id,
        businessSetInput: {
          name,
          photo: photoUrl ?? null,
        },
      })

      if (dirtyFields.hideClientsDeposit) {
        await updateBusinessSettings({
          id: businessSettings.id,
          businessSettingsSetInput: {
            hideClientsDeposit: data.hideClientsDeposit,
          },
        })
      }

      openSnackBar(t('changesSaved'), 'success', 3000)
      onApply?.()
    } catch (e) {
      reportError(e as Error)
      openSnackBar(t('submitError'), 'error', 3000)
    }
  }

  return (
    <SettingsWrapper>
      <div className={'flex items-end'}>
        <div className="flex flex-col w-full pl-0 pb-0 pt-0">
          <div className={'flex items-center mb-4'}>
            <Controller
              name="photo"
              control={control}
              render={({ field: { onChange } }) => (
                <EditablePhoto
                  defaultPhoto={myBusiness?.photo ?? undefined}
                  onChange={onChange}
                  containerClassName="mr-4"
                />
              )}
            />

            <div>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.businessName')}
                    containerClassName="w-64"
                    required
                    errorMessage={{
                      isShown: Boolean(error),
                      text: t('formError.required'),
                      reserveIndent: false,
                    }}
                    {...field}
                  />
                )}
              />

              <Controller
                name="hideClientsDeposit"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    containerClassName="mt-3"
                    label={t('hideClientsDeposit')}
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
          <ApplyButton
            className="w-40 mt-2"
            disabled={!isDirty || isSubmitting}
            spinner={isSubmitting}
            onClick={handleSubmit(handleUpdatingBusiness)}
          />
        </div>
      </div>
    </SettingsWrapper>
  )
}
