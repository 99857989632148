import { gql } from 'graphql-request'

export const serviceFragment = gql`
  fragment serviceType on service {
    id
    name
    type
    picture
    groupId
    color
    serviceEmployees(where: { branchId: { _eq: $branchId } }) {
      employeeId
    }
    serviceLocations(where: { branchId: { _eq: $branchId } }) {
      locationId
    }
    defaultDuration
    price
    availableForClient
    availableToAllBranches
    serviceGroup {
      id
      name
    }
  }
`

export const serviceBriefWithProductFragment = gql`
  fragment serviceBriefWithProductType on service {
    id
    name
    defaultDuration
    price
    costPrice
    groupId
    color
    picture
    type
    serviceProducts(where: { branchId: { _eq: $branchId } }) {
      productId
      quantity
      product {
        name
      }
    }
  }
`

/**
 * Потрібно для завантаження всіх сервісів (для посилань онлайн-записів)
 */
export const serviceBriefWithEmployeeFragment = gql`
  fragment serviceBriefWithEmployeeType on service {
    id
    name
    picture
    type
    branchId
    serviceEmployees {
      employeeId
    }
  }
`

export const serviceBriefWithProductAndEmployeeFragment = gql`
  ${serviceBriefWithProductFragment}
  fragment serviceBriefWithProductAndEmployeeType on service {
    ...serviceBriefWithProductType
    serviceEmployees(
      where: { employee: { archived: { _is_null: true } }, branchId: { _eq: $branchId } }
    ) {
      employee {
        id
        firstName
        lastName
      }
    }
  }
`

export const extendedServiceFragment = gql`
  fragment extendedServiceType on service {
    defaultDuration
    description
    id
    name
    price
    costPrice
    picture
    groupId
    archived
    type
    availableToAllBranches
    serviceEmployees(
      where: { employee: { archived: { _is_null: true } }, branchId: { _eq: $branchId } }
    ) {
      id
      employee {
        id
        firstName
        lastName
        groupId
      }
    }
    serviceLocations(where: { branchId: { _eq: $branchId } }) {
      location {
        id
        name
      }
    }
    serviceProducts(where: { branchId: { _eq: $branchId } }) {
      id
      productId
      quantity
      product {
        id
        photo
        name
        price
        unit
        criticalQuantity
        groupId
      }
    }
    servicePermissions(where: { employee: { archived: { _is_null: true } } }) {
      employee {
        id
      }
    }
    # Нам нужно узнать используется ли услуга в не архивных абонементах
    cardTemplateServices(where: { cardTemplate: { archived: { _is_null: true } } }) {
      id
    }
  }
`

export const serviceByIdFragment = gql`
  ${extendedServiceFragment}
  fragment serviceByIdType on service {
    ...extendedServiceType
    servicePermissions(where: { employee: { archived: { _is_null: true } } }) {
      employee {
        firstName
        lastName
        id
        employeeGroup {
          id
        }
      }
    }
    archived
    color
    bookingServices {
      booking {
        locationId
        employeeId
      }
    }
    groupBookings {
      locationId
      employeeId
    }
    transactionsServices {
      id
    }
    availableForClient
  }
`

export const anonymouslyServiceFragment = gql`
  fragment anonymouslyServiceType on service {
    id
    name
    description
    type
    price
    defaultDuration
    picture
    groupId
    availableForClient
    serviceEmployees(
      where: { employee: { archived: { _is_null: true } }, branchId: { _eq: $branchId } }
    ) {
      employeeId
    }
    serviceLocations(where: { branchId: { _eq: $branchId } }) {
      locationId
    }
    serviceGroup {
      id
      name
    }
  }
`
