import { ExtendedEmployee, ServerEmployeeGroupType } from '@expane/data'
import { useAreManyEmployeesAllowed } from '@expane/logic/billing'
import { permissions } from '@expane/logic/permission'
import { getDoesEmployeeHaveSalarySetting } from '@expane/logic/salarySettings'
import { findById, transformPersonName } from '@expane/logic/utils'
import { EmptyPlaceholder, Page, Paper, Switch } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircle, IoReaderOutline } from 'react-icons/io5'
import { treeMenuClassName } from 'ui/styles'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { ButtonWithBillingCheck, CreateCategoryButton } from 'widgets/Buttons'
import { EmployeeDialog } from 'widgets/EmployeeDialog'
import { EmployeeGroupDialog } from 'widgets/EmployeeGroupDialog'
import { ArchiveListNavigationButton, ListNavigationButton } from 'widgets/NavigationButtons'
import { EmployeeQuickInfo } from './EmployeeQuickInfo'
import { PaperResizable } from 'ui/PaperResizable'

interface EmployeePageDataProps {
  employees: Omit<ExtendedEmployee, 'employeeSchedules'>[]
  employeeGroups: Omit<ServerEmployeeGroupType, 'employees'>[]
  myPermissions: string[]
  archive?: boolean
  type?: PageDataType
}

export const EmployeePageData: FC<EmployeePageDataProps> = ({
  employees,
  employeeGroups,
  myPermissions,
  archive = false,
  type = 'page',
}) => {
  const { t } = useTranslation()

  const { areManyEmployeesAllowed } = useAreManyEmployeesAllowed()

  const [hideEmptyFolders, setHideEmptyFolders] = useState(type === 'page')

  const {
    openEditDialog: openEmployeeEditDialog,
    openCreateDialog: openEmployeeCreateDialog,
    dialog: employeeDialog,
  } = useOpenDialog(EmployeeDialog)
  const {
    openEditDialog: openEmployeeGroupEditDialog,
    openCreateDialog: openEmployeeGroupCreateDialog,
    dialog: employeeGroupDialog,
  } = useOpenDialog(EmployeeGroupDialog)

  const [selectedItem, setSelectedItem] = useState<TreeMenuItem>()

  const isEditingAllowedEmployees = myPermissions.includes(permissions.employee.set)

  const employeesWithNameAndInactiveReason = employees.map(employee => ({
    ...employee,
    name: transformPersonName(employee),
    warningMessage: getDoesEmployeeHaveSalarySetting(employee)
      ? undefined
      : t('employeeSalaryAlert'),
  }))

  const treeItems = transformDataForTreeMenu(employeeGroups, employeesWithNameAndInactiveReason, {
    keepEmptyFolders: !hideEmptyFolders,
  })

  return (
    <Page isPadding={type === 'page'}>
      {isEditingAllowedEmployees && areManyEmployeesAllowed && (
        <div className="flex mb-2">
          {isEditingAllowedEmployees && !archive && (
            <>
              <ButtonWithBillingCheck
                onClick={() => openEmployeeCreateDialog()}
                className="mr-2"
                Icon={IoAddCircle}
              >
                {t('employee.name')}
              </ButtonWithBillingCheck>
              <CreateCategoryButton
                onClick={() => openEmployeeGroupCreateDialog()}
                className="mr-2"
              />
            </>
          )}

          {type !== 'createBusiness' && (
            <>
              {archive ? (
                <ListNavigationButton title={t('employees.name')} />
              ) : (
                <ArchiveListNavigationButton className="ml-auto" />
              )}
            </>
          )}
        </div>
      )}

      {employees.length === 0 && employeeGroups.length === 0 ? (
        <EmptyPlaceholder
          Icon={IoReaderOutline}
          text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.employee')}
        />
      ) : (
        <Paper className="h-full overflow-auto flex">
          <PaperResizable defaultWidth={type === 'page' ? '1/4' : '1/3'} withoutBorder>
            <div className={treeMenuClassName + ' h-full'}>
              {type === 'page' && areManyEmployeesAllowed ? (
                <Switch
                  onChange={setHideEmptyFolders}
                  checked={hideEmptyFolders}
                  label={t('hideEmptyGroups')}
                  containerClassName="mb-2 ml-1"
                />
              ) : null}
              <TreeMenu
                type="SinglePickMode"
                items={treeItems}
                selected={selectedItem}
                onSelected={item => setSelectedItem(onChangeSingleTreeMenu(item, selectedItem))}
                onEditFolder={folder => openEmployeeGroupEditDialog(folder.id)}
                onEditItem={item => openEmployeeEditDialog(item.id)}
                openSwitch
              />
            </div>
          </PaperResizable>

          {selectedItem && (
            <EmployeeQuickInfo
              employee={findById(selectedItem.id, employees)}
              editEmployee={openEmployeeEditDialog}
              employeeGroups={employeeGroups}
              myPermissions={myPermissions}
              key={selectedItem.id}
              type={type}
            />
          )}
        </Paper>
      )}

      {employeeDialog}
      {employeeGroupDialog}
    </Page>
  )
}
