import { ServerSalaryIssueType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { convertSalaryIssuesToReferralPercentageSalaryItems } from '@expane/logic/salaryIssues/converting'
import { getReferralPercentageSalaryIssues } from '@expane/logic/salaryIssues/filters'
import {
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableFooter,
  TableCell,
  EmptyPlaceholder,
  Hint,
  TableRow,
} from '@expane/ui'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmark, IoReaderOutline } from 'react-icons/io5'
import { TransactionDialog } from 'widgets/TransactionDialog'

interface ReferralPercentageListProps {
  salaryIssuesByEmployeeId: ServerSalaryIssueType[]
  total: number
}

export const ReferralPercentageList: FC<PropsWithBranchId<ReferralPercentageListProps>> = ({
  salaryIssuesByEmployeeId,
  branchId,
  total,
}) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)
  const dateFormatting = useDateFormatting()

  const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const referralPercentageSalaryItems = convertSalaryIssuesToReferralPercentageSalaryItems(
    getReferralPercentageSalaryIssues(salaryIssuesByEmployeeId),
  )

  if (!referralPercentageSalaryItems.length) {
    return (
      <EmptyPlaceholder Icon={IoReaderOutline} text={t('salary.noDataAboutReferralPercentage')} />
    )
  }

  return (
    <>
      <TableContainer className={'w-full'}>
        <TableHeader>
          <tr>
            <TableHeaderCell className={'w-28'}>{t('dateTitle')}</TableHeaderCell>
            <TableHeaderCell className={'w-56'}>{t('client.name')}</TableHeaderCell>
            <TableHeaderCell className={'w-56'}>{t('service.name')}</TableHeaderCell>
            <TableHeaderCell className="text-right w-36">
              {t('price')} <Hint>{t('salary.sellPriceHint')}</Hint>
            </TableHeaderCell>
            <TableHeaderCell className="text-right w-36">{t('salary.name')}</TableHeaderCell>
            <TableHeaderCell className="text-right w-36">{t('salary.paid')}</TableHeaderCell>
          </tr>
        </TableHeader>
        <TableBody>
          {referralPercentageSalaryItems.map(item => (
            <TableRow
              key={item.id}
              onClick={() => {
                if (item.transactionId) {
                  editTransactionDialog(item.transactionId)
                }
              }}
            >
              <TableCell>{dateFormatting('shortDate', item.date)}</TableCell>
              <TableCell>{item.client}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell className="text-right">{convertNumberToMoney(item.price)}</TableCell>
              <TableCell className="text-right">
                {convertNumberToMoney(item.totalSum ?? 0)}
              </TableCell>
              <TableCell className="text-right">
                {item.isSalaryPaid ? <IoCheckmark size="1rem" /> : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableCell />
          <TableCell colSpan={2}>{t('total')}</TableCell>
          <TableCell className="text-right">
            {convertNumberToMoney(
              referralPercentageSalaryItems.reduce((sum, item) => sum + item.price, 0),
            )}
          </TableCell>
          <TableCell className="text-right">{convertNumberToMoney(total)}</TableCell>
          <TableCell />
        </TableFooter>
      </TableContainer>
      {transactionDialog}
    </>
  )
}
