import { safeWebLocalStorage } from '@expane/web-logic/safeWebLocalStorage'
import { makeAutoObservable } from 'mobx'
import { reportError } from 'services/api'
import type { Store } from '.'

const generateBranchIdLocalStorageKey = (businessId: number) => `branch/${businessId}`

const getBranchIdFromLocalStorage = (businessId: number): number | undefined => {
  const key = generateBranchIdLocalStorageKey(businessId)

  const rawData = safeWebLocalStorage.getItem(key)
  if (rawData === null) {
    return undefined
  }

  try {
    return Number(rawData)
  } catch (error) {
    reportError(error)
    return undefined
  }
}

const saveBranchIdToLocalStorage = (branchId: number, businessId: number) => {
  const key = generateBranchIdLocalStorageKey(businessId)
  safeWebLocalStorage.setItem(key, JSON.stringify(branchId))
}

export class BranchStore {
  constructor(rootStore: Store) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  private currentBranchId: number | undefined
  private rootStore: Store

  get branchId(): number | undefined {
    if (!this.rootStore.me.isAuthorised) return undefined
    if (this.currentBranchId) return this.currentBranchId

    const businessId = this.rootStore.me.businessId
    if (!businessId) throw new Error('Business id is needed to get current branch!')

    return getBranchIdFromLocalStorage(businessId)
  }

  setBranchId = (branchId: number) => {
    this.currentBranchId = branchId

    const businessId = this.rootStore.me.businessId
    if (!businessId) throw new Error('Business id is needed to save current branch!')
    saveBranchIdToLocalStorage(branchId, businessId)
  }

  dropBranchId = () => {
    this.currentBranchId = undefined
    const businessId = this.rootStore.me.businessId
    if (!businessId) throw new Error('Business id is needed to save current branch!')
    const key = generateBranchIdLocalStorageKey(businessId)
    safeWebLocalStorage.removeItem(key)
  }
}
