import { gql } from 'graphql-request'

export const webinarFragment = gql`
  fragment webinarType on webinar {
    id
    title
    description
    speaker
    role
    startDate
    duration
    createdAt
    numberOfParticipants
    imageUrl
    recordingUrl
  }
`
