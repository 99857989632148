import { gql } from 'graphql-request'
import { ServerProductType } from '../../generated/graphql-types'
import { productFragment } from './product.fragments'
import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import {
  ARCHIVED_PRODUCTS_QUERY_KEY,
  PRODUCTS_FOR_CONSUMABLES_QUERY_KEY,
  PRODUCTS_FOR_SALE_QUERY_KEY,
  PRODUCTS_LEFTOVERS_QUERY_KEYS,
  PRODUCTS_QUERY_KEY,
} from './queryKeys'
import { MOVEMENTS_QUERY_KEY } from '../movement'

export function useFetchProducts(branchId: number | undefined | null) {
  return useQuery<ServerProductType[]>(
    [PRODUCTS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productFragment}
          query ($branchId: Int!) {
            products(
              order_by: { createdAt: desc }
              where: {
                branchId: { _eq: $branchId }
                archived: { _is_null: true }
                _or: [
                  { groupId: { _is_null: true } }
                  {
                    productGroup: {
                      archived: { _is_null: true }
                      _or: [
                        { parentId: { _is_null: true } }
                        {
                          parentGroup: {
                            archived: { _is_null: true }
                            _or: [
                              { parentId: { _is_null: true } }
                              { parentGroup: { archived: { _is_null: true } } }
                            ]
                          }
                        }
                      ]
                    }
                  }
                ]
              }
            ) {
              ...productType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.products)) {
        return result.products
      } else {
        reportError(new Error('products is not an array'), 'error', { result })
        return []
      }
    },
    {
      queryName: 'useFetchProducts',
      enabled: Boolean(branchId),
      onError: reportGqlError,
      onSuccess: products => {
        for (const product of products) {
          queryClient.setQueryData([PRODUCTS_QUERY_KEY, product.id], product)
        }

        queryClient.setQueryData(
          [PRODUCTS_FOR_SALE_QUERY_KEY, PRODUCTS_QUERY_KEY],
          products.filter(product => product.forSale === true),
        )
        queryClient.setQueryData(
          [PRODUCTS_QUERY_KEY, PRODUCTS_FOR_CONSUMABLES_QUERY_KEY, { branchId }],
          products.filter(product => product.forSale === false),
        )
      },
    },
  )
}

export function useFetchUngroupedProducts(branchId: number | undefined) {
  return useQuery<ServerProductType[]>(
    [PRODUCTS_QUERY_KEY, 'ungrouped', { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productFragment}
          query ($branchId: Int!) {
            products(
              order_by: { createdAt: desc }
              where: {
                branchId: { _eq: $branchId }
                archived: { _is_null: true }
                groupId: { _is_null: true }
              }
            ) {
              ...productType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.products)) return result?.products

      reportError(new Error('useFetchUngroupedProducts is not an a array'), 'error', { result })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchUngroupedProducts',
      onError: reportGqlError,
    },
  )
}

export function useFetchArchivedUngroupedProducts(branchId: number | undefined) {
  return useQuery<ServerProductType[]>(
    [ARCHIVED_PRODUCTS_QUERY_KEY, 'ungrouped', { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productFragment}
          query ($branchId: Int!) {
            products(
              order_by: { createdAt: desc }
              where: {
                branchId: { _eq: $branchId }
                archived: { _is_null: false }
                _or: [
                  { groupId: { _is_null: true } }
                  { productGroup: { archived: { _is_null: true } } }
                ]
              }
            ) {
              ...productType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.products)) return result?.products

      reportError(new Error('useFetchArchivedUngroupedProducts is not an a array'), 'error', {
        result,
      })
      return []
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchArchivedUngroupedProducts',
      onError: reportGqlError,
    },
  )
}

export function useFetchProductsForSale(branchId: number | undefined) {
  return useQuery<ServerProductType[]>(
    [PRODUCTS_FOR_SALE_QUERY_KEY, PRODUCTS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productFragment}
          query ($branchId: Int!) {
            products(
              order_by: { createdAt: desc }
              where: {
                branchId: { _eq: $branchId }
                forSale: { _eq: true }
                archived: { _is_null: true }
              }
            ) {
              ...productType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.products)) {
        return result.products
      } else {
        reportError(new Error('productsForSale is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchProductsForSale',
      onError: reportGqlError,
      onSuccess: products => {
        for (const product of products) {
          queryClient.setQueryData([PRODUCTS_QUERY_KEY, product.id], product)
        }
      },
    },
  )
}

export function useFetchProductsForConsumables(branchId: number | undefined) {
  return useQuery<ServerProductType[]>(
    [PRODUCTS_QUERY_KEY, PRODUCTS_FOR_CONSUMABLES_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${productFragment}
          query ($branchId: Int!) {
            products(
              order_by: { createdAt: desc }
              where: {
                branchId: { _eq: $branchId }
                forSale: { _eq: false }
                archived: { _is_null: true }
              }
            ) {
              ...productType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.products)) {
        return result.products
      } else {
        reportError(new Error('productsForConsumables is not an array'), 'error', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchProductsForConsumables',
      onError: reportGqlError,
      onSuccess: products => {
        for (const product of products) {
          queryClient.setQueryData([PRODUCTS_QUERY_KEY, product.id], product)
        }
      },
    },
  )
}

export function useFetchProductById(id: number | undefined, branchId: number | undefined) {
  return useQuery<ServerProductType>(
    [PRODUCTS_QUERY_KEY, id, branchId],
    async () => {
      const result = await request(
        gql`
          ${productFragment}
          query ($id: Int!, $branchId: Int!) {
            productById(id: $id) {
              ...productType
            }
          }
        `,
        { id, branchId },
      )

      if (result?.productById) {
        return result.productById
      } else {
        reportError(new Error('productById does not exist'), 'error', { id, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchProductById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(branchId),
    },
  )
}

export function useGetLeftovers(branchId: number | undefined, storageId?: number, toDate?: Date) {
  return useQuery<Record<number, number>>(
    [
      PRODUCTS_QUERY_KEY,
      MOVEMENTS_QUERY_KEY,
      PRODUCTS_LEFTOVERS_QUERY_KEYS,
      { branchId, storageId, toDate },
    ],
    async () => {
      const result = await request(
        gql`
          query ($branchId: Int!, $storageId: Int, $toDate: timestamptz) {
            getLeftovers(branchId: $branchId, storageId: $storageId, toDate: $toDate) {
              message
              productAmount
            }
          }
        `,
        { branchId, storageId, toDate },
      )

      if (result.getLeftovers.productAmount) return result.getLeftovers.productAmount
      else {
        reportError(new Error('leftovers is not an object'), 'error', { result })
        return {}
      }
    },
    {
      queryName: 'useGetLeftovers',
      enabled: Boolean(branchId),
      onError: reportGqlError,
    },
  )
}
