import { useFetchBusinessesToSwitch, useSetCurrentBusinessAsEmployee } from '@expane/data'
import { Button, CloseButton, Dialog, Modal, SelectDropdown, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { reloadApp } from 'logic/app'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'

interface Props {
  clientMode: boolean
  closeDialog: () => void
}

type Form = {
  businessId: number
}

const ChooseBusinessDialog: FC<Props> = observer(({ closeDialog, clientMode }) => {
  const { t } = useTranslation()

  const currentBusinessId = store.me.businessId ?? undefined

  const { control, handleSubmit, formState } = useForm<Form>({
    defaultValues: {
      businessId: !clientMode ? currentBusinessId : undefined,
    },
  })

  const [openSnackBar] = useSnackbar()

  const { mutateAsync: setCurrentBusiness } = useSetCurrentBusinessAsEmployee(reloadApp)
  const { data: businesses } = useFetchBusinessesToSwitch()
  const businessesAsEmployee = businesses?.filter(business => business.asEmployee)

  const changeCurrentBusiness = async ({ businessId }: Form) => {
    try {
      await setCurrentBusiness(businessId)
      openSnackBar(t('switchSuccess'), 'success', 3000)
    } catch (e) {
      reportError(e as Error, 'error', { businessId })
      openSnackBar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  return (
    <>
      <Modal
        close={closeDialog}
        confirm={() => {
          if (formState.isDirty) handleSubmit(changeCurrentBusiness)
        }}
      >
        <Dialog>
          <Dialog.Title>{t('chooseBusiness')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              name="businessId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectDropdown
                  label={t('business')}
                  items={businessesAsEmployee ?? []}
                  placeholder={t('placeholders.defaultSelect')}
                  required
                  noDataMessage={t('noSuchBusiness')}
                  onSelectChange={onChange}
                  value={value}
                  errorMessage={{
                    isShown: Boolean(formState.errors.businessId),
                    text: t('formError.required'),
                  }}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              onClick={handleSubmit(changeCurrentBusiness)}
              disabled={!formState.isDirty || formState.isSubmitting}
              spinner={formState.isSubmitting}
            >
              {t('switch')}
            </Button>

            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
        </Dialog>
      </Modal>
    </>
  )
})

export const useOpenChooseBusinessDialog = (clientMode = false) => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onCloseDialog = useRef<() => void>()

  const openChooseBusinessDialog = (onClose?: () => void) => {
    onCloseDialog.current = onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const chooseBusinessDialog = isOpen ? (
    <ChooseBusinessDialog clientMode={clientMode} closeDialog={closeDialog} />
  ) : null

  return { openChooseBusinessDialog, chooseBusinessDialog }
}
