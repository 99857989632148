import { MOVEMENT_TYPES, useCreateProductsMovement } from '@expane/data'
import { convertUnitValueToServer } from '@expane/logic/product'
import { Button, CloseButton, Dialog, Modal, Textarea } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'
import {
  MovementDialogsProps,
  ProductDto,
} from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'
import { ProductsMovementInfo } from 'widgets/MovementCreateDialogs/ProductsMovementDialog/ProductsMovementInfo'
import { ProductsMovementProductsList } from 'widgets/MovementCreateDialogs/ProductsMovementDialog/ProductsMovementProductsList'

export interface ProductsMovementProps {
  control: Control<ProductsMovementFormValues>
}

export interface ProductsMovementFormValues {
  fromStorageId: number
  toStorageId: number
  movementNumber: string
  description: string
  products: ProductDto[]
}

export const ProductsMovementDialog: FC<MovementDialogsProps> = observer(
  ({ closeParentDialog, closeDialog, productsForDefaultFormValues, storageId, movementNumber }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()

    const { control, handleSubmit, formState } = useForm<ProductsMovementFormValues>({
      defaultValues: {
        fromStorageId: storageId,
        products: productsForDefaultFormValues,
        movementNumber: movementNumber.toString(),
      },
    })

    const { confirmPopup, closePopups } = useShowPopupOnDirtyFormClose(formState, closeDialog)

    const [openSnackBar] = useSnackbar()

    const { mutateAsync: createProductsMovement } = useCreateProductsMovement()

    const mutateMovement: SubmitHandler<ProductsMovementFormValues> = async data => {
      if (!branchId) {
        openSnackBar(t('submitError'), 'error', 3000)
        closeDialog()
        if (closeParentDialog) closeParentDialog()

        return
      }

      const { fromStorageId, toStorageId, products, movementNumber, description } = data

      try {
        await createProductsMovement({
          type: MOVEMENT_TYPES.movement.id,
          fromStorageId,
          toStorageId,
          number: movementNumber,
          description,
          fulfilled: true,
          movementProducts: {
            data: products.map(p => ({
              productId: p.id,
              price: p.costPrice,
              quantity: convertUnitValueToServer(p.quantity, p.unit),
            })),
          },
          branchId,
        })
        openSnackBar(t('movement.success'), 'success', 3000)
      } catch (e) {
        openSnackBar(t('submitError'), 'error', 3000)
        reportError(new Error('Error while moving products'), 'error', { data })
      }

      closeDialog()
      if (closeParentDialog) closeParentDialog()
    }

    return (
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting && formState.isDirty) handleSubmit(mutateMovement)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('movement.name')}</Dialog.Title>

          <Dialog.Body className="w-246">
            <ProductsMovementInfo control={control} />
            <ProductsMovementProductsList control={control} />
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <Textarea
                  containerClassName="mt-2"
                  rows={2}
                  label={t('note')}
                  placeholder={t('placeholders.productsMovementReason')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              onClick={handleSubmit(mutateMovement)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
            >
              {t('move')}
            </Button>
            <CloseButton onClick={closePopups} />
          </Dialog.Footer>
        </Dialog>
        {confirmPopup}
      </Modal>
    )
  },
)
