import {
  MOVEMENT_TYPES,
  TRANSACTION_TYPES,
  useCreateArrivalMovement,
  useCreateTransactionFromAccount,
  useFetchAccounts,
} from '@expane/data'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { MOVEMENT_DISCOUNT_TYPES } from '@expane/logic/movement'
import { useAccountsWithoutCheckbox } from '@expane/logic/payment/checkbox'
import { convertUnitValueToServer } from '@expane/logic/product'
import { CloseButton, Dialog, Modal } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'
import { SubmitButton } from 'widgets/Buttons'
import { ArrivalMovementInfo } from 'widgets/MovementCreateDialogs/ArrivalMovementDialog/ArrivalMovementInfo'
import { ArrivalMovementPayment } from 'widgets/MovementCreateDialogs/ArrivalMovementDialog/ArrivalMovementPayment'
import { ArrivalMovementProductsList } from 'widgets/MovementCreateDialogs/ArrivalMovementDialog/ArrivalMovementProductsList'
import {
  MovementDialogsProps,
  ProductDto,
} from 'widgets/MovementCreateDialogs/ChooseMovementProductsDialog'

export interface ArrivalMovementProps {
  control: Control<ArrivalMovementFormValues>
}

export interface ArrivalMovementFormValues {
  supplierId: number
  toStorageId: number
  movementNumber: string
  discount: string
  description: string
  transportCosts: number
  fromAccountId: number
  amount: string
  fulfilled: boolean
  products: ProductDto[]
  payImmediately: boolean
}

export const ArrivalMovementDialog: FC<MovementDialogsProps> = observer(
  ({ closeParentDialog, closeDialog, productsForDefaultFormValues, storageId, movementNumber }) => {
    const branchId = store.branch.branchId
    const { t } = useTranslation()

    const { control, resetField, handleSubmit, formState, setValue } =
      useForm<ArrivalMovementFormValues>({
        defaultValues: {
          toStorageId: storageId,
          products: productsForDefaultFormValues,
          movementNumber: movementNumber.toString(),
          transportCosts: 0,
          amount: '0',
          discount: '',
          fulfilled: true,
        },
      })

    const { confirmPopup, closePopups } = useShowPopupOnDirtyFormClose(formState, closeDialog)

    const { data: accounts } = useFetchAccounts(branchId)

    const { accountsWithoutCheckbox } = useAccountsWithoutCheckbox(accounts ?? [])

    const [openSnackBar] = useSnackbar()

    const { mutateAsync: createTransactionFromAccount } = useCreateTransactionFromAccount()
    const { mutateAsync: createArrivalMovement } = useCreateArrivalMovement()

    const mutateMovement: SubmitHandler<ArrivalMovementFormValues> = async data => {
      if (!branchId) {
        openSnackBar(t('submitError'), 'error')
        closeDialog()
        if (closeParentDialog) closeParentDialog()

        return
      }

      const {
        fromAccountId,
        products,
        discount,
        movementNumber,
        toStorageId,
        supplierId,
        transportCosts,
        description,
        fulfilled,
        payImmediately,
        amount,
      } = data

      try {
        const {
          insertMovement: { id: movementId },
        } = await createArrivalMovement({
          type: MOVEMENT_TYPES.arrival.id,
          toStorageId,
          supplierId,
          fulfilled,
          transportCosts,
          discount: discount ? Number(discount) : null,
          discountType: discount ? MOVEMENT_DISCOUNT_TYPES.percent.id : null,
          movementProducts: {
            data: products.map(p => ({
              productId: p.id,
              price: p.costPrice,
              quantity: convertUnitValueToServer(p.quantity, p.unit),
            })),
          },
          number: movementNumber,
          description,
          branchId,
        })

        if (payImmediately) {
          await createTransactionFromAccount({
            fromAccountId,
            amount: Number(amount) - transportCosts,
            type: TRANSACTION_TYPES.productsPurchase.id,
            movementId,
            branchId,
          })

          if (transportCosts !== 0) {
            await createTransactionFromAccount({
              fromAccountId,
              amount: transportCosts,
              type: TRANSACTION_TYPES.transportCosts.id,
              movementId,
              branchId,
            })
          }
        }

        openSnackBar(t('arrival.success'), 'success', 3000)
      } catch (error) {
        if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
        else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
        else {
          openSnackBar(t('submitError'), 'error')
          reportError(error, 'error', { data })
        }
      }

      closeDialog()
      if (closeParentDialog) closeParentDialog()
    }

    return (
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting && formState.isDirty) handleSubmit(mutateMovement)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('arrival.name')}</Dialog.Title>

          <Dialog.Body className="w-246">
            <ArrivalMovementInfo control={control} setValue={setValue} />
            <ArrivalMovementProductsList
              control={control}
              products={productsForDefaultFormValues}
            />
            <ArrivalMovementPayment
              control={control}
              accounts={accountsWithoutCheckbox}
              branchId={branchId}
              setValue={setValue}
              resetField={resetField}
            />
          </Dialog.Body>

          <Dialog.Footer>
            <SubmitButton
              onClick={handleSubmit(mutateMovement)}
              disabled={formState.isSubmitting || !formState.isDirty}
              spinner={formState.isSubmitting}
            />
            <CloseButton onClick={closePopups} />
          </Dialog.Footer>
        </Dialog>
        {confirmPopup}
      </Modal>
    )
  },
)
