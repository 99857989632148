import {
  CloudFunctionResponseCodes,
  ServerArchiveTypes,
  ServerExtendedServiceType,
  useArchiveService,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { Input, OpenButton, Textarea, useShowConfirmationPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { Avatar } from 'ui/Avatar'
import { ArchiveButton, RestoreButton } from 'widgets/Buttons'

interface Props {
  editService: (id: number) => void
  service: ServerExtendedServiceType | undefined
  type?: PageDataType
}

export const ServiceQuickInfo: FC<Props> = ({ service, editService, type = 'page' }) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const { mutateAsync: archiveService } = useArchiveService()

  const [openSnackbar] = useSnackbar()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const isArchivingAllowed = myPermissions?.includes(permissions.service.archive)

  const treeMenuWidth = type === 'page' ? 'w-1/4' : 'w-2/3'

  if (service === undefined) return null

  const handleOnArchive = async () => {
    try {
      const checkingResult = await archiveService({
        serviceId: service.id,
        archiveType: ServerArchiveTypes.Check,
      })

      const code = checkingResult.archiveService.code

      if (code === CloudFunctionResponseCodes.insufficientPermissions) {
        openSnackbar(t('serviceError.noPermission'), 'error')
        return
      }

      if (code === CloudFunctionResponseCodes.cannotArchiveWithFutureBookings) {
        openSnackbar(t('serviceError.futureBookings', { name: service.name }), 'error')
        return
      }

      if (code === CloudFunctionResponseCodes.cannotArchiveWithNotArchivedCardTemplate) {
        openSnackbar(t('serviceError.cannotArchiveWithNotArchivedCardTemplate'), 'error')
        return
      }

      if (code === CloudFunctionResponseCodes.successful) {
        const result = await archiveService({
          serviceId: service.id,
          archiveType: ServerArchiveTypes.Archive,
        })

        if (result.archiveService.code === CloudFunctionResponseCodes.successful)
          openSnackbar(t('service.archivingSuccessful', { name: service?.name }), 'success')
        else openSnackbar(t('submitError'), 'error')

        return
      }

      // Всі інші коди то помилки
      openSnackbar(t('submitError'), 'error')
    } catch (e) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  const handleOnRestore = async () => {
    try {
      const checkingResult = await archiveService({
        serviceId: service.id,
        archiveType: ServerArchiveTypes.Check,
      })

      const code = checkingResult.archiveService.code

      if (code === CloudFunctionResponseCodes.insufficientPermissions) {
        openSnackbar(t('serviceError.noPermission'), 'error')
        return
      }

      const onSuccess = async () => {
        try {
          const result = await archiveService({
            serviceId: service.id,
            archiveType: ServerArchiveTypes.Restore,
          })

          if (result.archiveService.code === CloudFunctionResponseCodes.successful)
            openSnackbar(t('service.archivingSuccessful', { name: service?.name }), 'success')
          else openSnackbar(t('submitError'), 'error')
        } catch {
          openSnackbar(t('submitError'), 'error')
        }
      }

      if (code === CloudFunctionResponseCodes.inArchivedGroup) {
        showConfirmation({
          title: t('restore.name'),
          description: t('serviceError.restoreWithoutCategory'),
          onConfirm: onSuccess,
        })
        return
      }

      if (code === CloudFunctionResponseCodes.successful) {
        await onSuccess()
        return
      }

      // Всі інші коди то помилки
      openSnackbar(t('submitError'), 'error')
    } catch (e) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  return (
    <div className={'py-5 px-2 ' + treeMenuWidth}>
      <div className="w-full flex-centered mb-2">
        {type !== 'createBusiness' && (
          <Avatar
            name={service?.name ?? ''}
            type="big"
            url={service?.picture ?? ''}
            reportErrorHandlerFunction={reportError}
          />
        )}
      </div>
      <div className="flex">
        <Input
          label={t('title')}
          containerClassName="flex-1 mr-2"
          value={service?.name ?? ''}
          readOnly
          disabled
        />
        <Input
          label={t('price')}
          containerClassName="flex-1"
          value={service?.price.toString() ?? '0'}
          readOnly
          disabled
        />
      </div>
      <div className="flex mt-2">
        <Input
          label={t('costPrice')}
          value={service?.costPrice.toString() ?? '0'}
          readOnly
          disabled
          containerClassName="flex-1 mr-2"
        />
        <Input
          label={t('duration')}
          value={service?.defaultDuration.toString() ?? '0'}
          containerClassName="flex-1"
          disabled
          readOnly
        />
      </div>
      <Textarea
        placeholder=""
        containerClassName="mt-2"
        label={t('description')}
        value={service?.description ?? ''}
        readOnly
        disabled
        rows={type !== 'createBusiness' ? 4 : 2}
      />
      <div
        className={`flex ${
          service.archived || !isArchivingAllowed ? 'justify-end' : 'justify-between'
        } mt-5`}
      >
        {isArchivingAllowed &&
          !service.availableToAllBranches &&
          type !== 'createBusiness' &&
          (!service.archived ? (
            <ArchiveButton className="mr-auto" onClick={handleOnArchive} />
          ) : (
            <RestoreButton onClick={() => handleOnRestore()} />
          ))}

        {service.archived ? null : <OpenButton onClick={() => editService(service.id)} />}
      </div>

      {confirmationModal}
    </div>
  )
}
