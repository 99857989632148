import {
  EmployeeByIdExtended,
  EmployeeGroupWithSchedule,
  ServerClientBriefType,
  ServerExtendedServiceType,
  ServerServiceGroupType,
  ServerWaitingListType,
  useCreateWaitingList,
  useFetchEmployeesByServiceId,
  useUpdateWaitingList,
  WaitingListDate,
} from '@expane/data'
import { createCurrentDate } from '@expane/date'
import { checkArrayNotEmpty } from '@expane/logic/form'
import { permissions } from '@expane/logic/permission'
import {
  Button,
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  SelectDropDownItem,
  Textarea,
  useShowConfirmationPopup,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { transformPersonsForSelect } from 'logic/utils'
import React, { FC } from 'react'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCalendarNumberOutline, IoTrashBin } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import {
  extractItemsFromFolders,
  groupInitialTreeItems,
  transformDataForTreeMenu,
} from 'ui/TreeMenu/logic.common'
import {
  convertEmployeeGroupsToTreeItems,
  transformEmployeeIdToTreeItem,
} from 'ui/TreeMenu/logic.employee'
import { TreeSelect } from 'ui/TreeSelect'
import { SaveButton } from 'widgets/Buttons'
import { ClientDialog } from 'widgets/ClientDialog'
import {
  convertResponsibleEmployeesToTreeItems,
  transformWaitingListServiceDefaultValueTreeItem,
} from './logic'
import { useOpenWaitingListPeriodDialog } from 'widgets/WaitingListDialog/WaitingListPeriod'
import { WaitingListConvenientTimes } from './WaitingListConvenientTimes'
import {
  mergeWaitingListDatesPeriods,
  splitWaitingListDatesPeriods,
} from '@expane/logic/waitingList'

export type WaitingListFormValues = {
  clientId: number
  newClientFirstName: string
  newClientLastName: string
  newClientPhone: string
  service: TreeMenuItem
  employeeId: number | undefined
  description: string
  responsibleEmployees: TreeMenuItem[]
  dates: WaitingListDate[]
}

interface WaitingListDialogLogicProps {
  closeDialog: () => void
  isCreate: boolean
  myPermissions: string[]
  waitingListById: ServerWaitingListType | undefined
  services: ServerExtendedServiceType[]
  serviceGroups: ServerServiceGroupType[]
  clients: ServerClientBriefType[]
  employeeGroups: EmployeeGroupWithSchedule[]
  myEmployee: EmployeeByIdExtended
  timezone: string
}

export const WaitingListDialogLogic: FC<WaitingListDialogLogicProps> = ({
  closeDialog,
  isCreate,
  myPermissions,
  waitingListById,
  clients,
  employeeGroups,
  services,
  serviceGroups,
  myEmployee,
  timezone,
}) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(employeeGroups)

  const { formState, control, handleSubmit, setValue } = useForm<WaitingListFormValues>({
    defaultValues: {
      clientId: waitingListById?.client.id,
      service: transformWaitingListServiceDefaultValueTreeItem(services, waitingListById?.service),
      employeeId: waitingListById?.employee?.id,
      description: waitingListById?.description ?? undefined,
      responsibleEmployees: waitingListById
        ? groupInitialTreeItems(
            convertResponsibleEmployeesToTreeItems(waitingListById),
            employeeTreeMenuItems,
          )
        : transformEmployeeIdToTreeItem(myEmployee.id, employeeGroups),
      dates: waitingListById ? splitWaitingListDatesPeriods(waitingListById.dates) : [],
    },
  })

  const {
    dialog: clientDialog,
    openEditDialog: openEditClientDialog,
    openCreateDialog: openCreateClientDialog,
  } = useOpenDialog(ClientDialog)

  const { mutateAsync: updateWaitingList } = useUpdateWaitingList()
  const { mutateAsync: createWaitingList } = useCreateWaitingList()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('waitingList.datesWarning'),
  )

  const mutateWaitingList: SubmitHandler<WaitingListFormValues> = async data => {
    if (!branchId) {
      openSnackbar(t('submitError'), 'error')

      return
    }

    const responsibleEmployeesWithoutGroups = data.responsibleEmployees
      ? extractItemsFromFolders(data.responsibleEmployees as TreeMenuItem[])
      : []
    const responsibleEmployeesIds = responsibleEmployeesWithoutGroups.map(employee => ({
      employeeId: employee.id,
    }))

    if (data.dates.length === 0) {
      showWarningPopup()

      return
    }

    const mergedDates = mergeWaitingListDatesPeriods(data.dates)

    if (isCreate) {
      const result = await createWaitingList({
        waitingListInsertInput: {
          branchId,
          clientId: data.clientId,
          serviceId: data.service.id,
          employeeId: data.employeeId,
          description: data.description ? data.description : undefined,
          responsibleEmployees: { data: responsibleEmployeesIds },
          dates: mergedDates,
        },
        clientInsertInput: data.clientId
          ? undefined
          : {
              firstName: data.newClientFirstName,
              lastName: data.newClientLastName,
              phone: data.newClientPhone,
            },
      })

      if (result?.insertWaitingList?.id) {
        openSnackbar(t('waitingList.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else if (waitingListById) {
      const result = await updateWaitingList({
        id: waitingListById.id,
        waitingListSetInput: {
          clientId: data.clientId,
          serviceId: data.service.id,
          employeeId: data.employeeId,
          description: data.description ? data.description : undefined,
          dates: mergedDates,
        },
        serverWaitingListEmployeeInsertInput: responsibleEmployeesIds.map(({ employeeId }) => ({
          employeeId,
          waitingListId: waitingListById.id,
        })),
      })

      if (result?.updateWaitingListById?.id) {
        openSnackbar(t('waitingList.updatedSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const cancelExistingWaitingBooking = async () => {
    if (!waitingListById) return

    const result = await updateWaitingList({
      id: waitingListById.id,
      waitingListSetInput: { closedAt: createCurrentDate(timezone) },
    })

    if (result?.updateWaitingListById?.id) {
      openSnackbar(t('cancelBooking.success'), 'success')
    } else {
      openSnackbar(t('submitError'), 'error')
    }
    closeDialog()
  }

  const handleCancelWaitingBooking = () => {
    showConfirmation({
      title: t('cancelBooking.title'),
      description: t('cancelBooking.confirmation'),
      onConfirm: cancelExistingWaitingBooking,
    })
  }

  const clientsForSelect: SelectDropDownItem[] = transformPersonsForSelect(clients, true)

  const isEditingAllowed = myPermissions.includes(permissions.waitingList.set)

  const { waitingListPeriodDialog, openWaitingListPeriodDialog } = useOpenWaitingListPeriodDialog()

  const watchedService = useWatch({
    control,
    name: 'service',
  })

  const watchedDates = useWatch({
    control,
    name: 'dates',
  })

  const { data: employees } = useFetchEmployeesByServiceId(watchedService?.id, branchId)

  const serviceItems = transformDataForTreeMenu(serviceGroups, services, {
    keepEmptyFolders: true,
  })

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateWaitingList)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('waitingList.name')}</Dialog.Title>

          <Dialog.Body className="w-176">
            <div className={'flex gap-2'}>
              <Controller
                control={control}
                name="clientId"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    label={t('client.name')}
                    value={value}
                    items={clientsForSelect}
                    placeholder={t('placeholders.defaultSelect')}
                    required
                    noDataMessage={t('noClient')}
                    className="w-1/2"
                    onSelectChange={onChange}
                    disabled={!isEditingAllowed}
                    errorMessage={{
                      isShown: Boolean(formState.errors.clientId),
                      text: t('formError.required'),
                    }}
                    onEditClick={openEditClientDialog}
                    onPlusClick={() => openCreateClientDialog(id => setValue('clientId', id))}
                  />
                )}
              />

              <Controller
                control={control}
                name="service"
                rules={{ required: true }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TreeSelect
                    items={serviceItems}
                    type="SinglePickMode"
                    onSelected={onChange}
                    selected={value as TreeMenuItem}
                    placeholder={t('placeholders.defaultSelect')}
                    className="w-1/2"
                    label={t('service.name')}
                    errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                    disabled={!isEditingAllowed}
                    required
                  />
                )}
              />
            </div>

            <div className={'flex gap-2'}>
              <Controller
                control={control}
                name="employeeId"
                render={({ field: { value, onChange } }) => (
                  <SelectDropdown
                    label={t('employee.name')}
                    value={value}
                    items={employees ? transformPersonsForSelect(employees) : []}
                    placeholder={t('placeholders.defaultSelect')}
                    noDataMessage={t('noSuchEmployee')}
                    className="w-1/2"
                    onSelectChange={onChange}
                    disabled={!employees?.length || !isEditingAllowed}
                    hint={t('waitingList.desirableEmployee')}
                  />
                )}
              />

              <Controller
                control={control}
                name="responsibleEmployees"
                rules={{ validate: checkArrayNotEmpty }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TreeSelect
                    type="MultiPickMode"
                    errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                    required
                    label={t('responsibleEmployees.name')}
                    placeholder={t('choose')}
                    items={employeeTreeMenuItems as TreeMenuItem[]}
                    onSelected={onChange}
                    className="w-1/2"
                    selected={value as TreeMenuItem[]}
                    hint={t('responsibleEmployees.hint')}
                  />
                )}
              />
            </div>

            {watchedDates.length > 0 ? (
              <WaitingListConvenientTimes
                className="mb-2"
                dates={watchedDates}
                timezone={timezone}
              />
            ) : null}

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.serviceDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateWaitingList)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />

            {isEditingAllowed && !isCreate && (
              <Button
                type="danger"
                className="mr-auto"
                onClick={handleCancelWaitingBooking}
                disabled={formState.isSubmitting}
                Icon={IoTrashBin}
              >
                {t('cancelBooking.title')}
              </Button>
            )}

            <Button
              className={'mr-auto'}
              onClick={() => openWaitingListPeriodDialog(control)}
              Icon={IoCalendarNumberOutline}
            >
              {t('chooseConvenientTime')}
            </Button>
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {waitingListPeriodDialog}
      {clientDialog}
      {confirmPopup}
      {confirmationModal}
      {warningModal}
    </>
  )
}

export const WaitingListDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('waitingList.name')} className="w-176" closeDialog={closeDialog}>
      <PlaceholderInput label={t('client.name')} className="w-1/2 pr-2 pb-4" />

      <div className="flex w-full gap-2">
        <PlaceholderInput label={t('dateTitle')} className="w-1/2" />
        <PlaceholderInput label={t('service.name')} className="w-1/2" />
      </div>
      <div className="flex w-full gap-2">
        <PlaceholderInput label={t('employee.name')} className="w-1/2" />
        <PlaceholderInput label={t('responsibleEmployees.name')} className="mt-4 mb-4" />
      </div>

      <PlaceholderTextarea label={t('description')} />
    </PlaceholderDialog>
  )
}
