import { config } from 'config'
import { generateMerchantSignature } from './generateMerchantSignature'
import { useEffect, useState } from 'react'
import { reportError } from 'services/api'

const secret = config.WAYFORPAY_SECRET
const merchantAccount = config.WAYFORPAY_MERCHANT_ACCOUNT

const merchantDomainName = 'www.expane.pro'
const SCRIPT_ELEMENT_ID = 'widget-wfp-script'

export const useWayForPayScript = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    // https://stackoverflow.com/questions/44803944/can-i-run-a-js-script-from-another-using-fetch
    const scriptPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.id = SCRIPT_ELEMENT_ID
      script.onload = resolve
      script.onerror = reject
      script.async = true
      script.src = 'https://secure.wayforpay.com/server/pay-widget.js'
      document.body.appendChild(script)
    })
    scriptPromise
      .then(() => {
        setIsLoading(false)
      })
      .catch(error => {
        reportError(error)
        setIsError(true)
        setIsLoading(false)
      })

    return () => {
      const script = document.getElementById(SCRIPT_ELEMENT_ID)
      if (script) document.body.removeChild(script)
    }
  })

  return [isLoading, isError]
}

export function runWayForPay(
  dto: RunDto,
  callbacks?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onApprove?: (response: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDecline?: (response: any) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPending?: (response: any) => void
  },
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const wayForPay: WayForPay = new Wayforpay()

  const merchantSignature = generateMerchantSignature({
    ...dto,
    merchantAccount,
    merchantDomainName,
    secret,
  })
  wayForPay.run(
    {
      ...dto,
      merchantAccount,
      merchantDomainName,
      merchantSignature,
      authorizationType: 'SimpleSignature',
      serviceUrl: config.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT + '/billing/submit',
      currency: 'UAH',
      paymentSystems: 'card;googlePay;applePay',
    },
    callbacks?.onApprove,
    callbacks?.onDecline,
    callbacks?.onPending,
  )
}

interface APIRunDto {
  language: string
  merchantAccount: string
  merchantDomainName: string
  authorizationType: string
  merchantSignature: string
  orderReference: string
  orderDate: string
  amount: string
  currency: string
  productName: string[]
  productPrice: string[]
  productCount: string[]
  clientFirstName: string
  clientLastName: string
  clientEmail: string
  clientPhone: string
  serviceUrl: string
  paymentSystems: string
  clientAccountId?: string
}
interface WayForPay {
  run(
    dto: APIRunDto,
    onApprove?: (response: WFPResponse) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDecline?: (response: any) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPending?: (response: any) => void,
  ): void
}

type RunDto = Pick<
  APIRunDto,
  | 'amount'
  | 'orderReference'
  | 'orderDate'
  | 'currency'
  | 'productName'
  | 'productPrice'
  | 'productCount'
  | 'clientFirstName'
  | 'clientLastName'
  | 'clientEmail'
  | 'clientPhone'
  | 'language'
>

type WFPResponse = {
  amount: number
  authCode: string
  cardPan: string // masked card number
  cardType: string // Mastercard, Visa
  clientStartTime: string
  createdDate: number // new Date(createdDate)
  currency: 'UAH'
  email: string
  fee: number
  issuerBankCountry: string // 'Ukraine'
  issuerBankName: string // 'MONOBank'
  merchantAccount: string
  merchantSignature: string
  orderReference: string
  paymentSystem: string // 'card'
  phone: string // 380********* format
  processingDate: number
  reason: string // 'Ok'
  reasonCode: number
  recToken: string
  transactionStatus: string // 'Approved'
}

export const mapI18NLanguageToWFP = {
  en: 'EN',
  uk: 'UA',
  ru: 'RU',
}

export const AUTO_WFP_LANGUAGE = 'AUTO'
