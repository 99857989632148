import { FC } from 'react'
import {
  EmployeeGroupWithSchedule,
  ServerTimeOffReasonType,
  useMutateTimeOffReasons,
} from '@expane/data'
import { useSnackbar } from '@expane/widgets'
import {
  generateDefaultTimeOffReasonFormValues,
  generateTimeOffReasonsDataForMutation,
  TimeOffReasonFormValues,
} from './logic'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  Dialog,
  Hint,
  stopEnterPropagation,
  TableBody,
  TableContainer,
  TableHeader,
  TableHeaderCell,
} from '@expane/ui'
import { TimeOffReasonRow } from './TimeOffReasonRow'
import { SaveButton } from 'widgets/Buttons'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'

export const TimeOffReasonDialogLogic: FC<{
  employeeGroups: EmployeeGroupWithSchedule[]
  timeOffReasons: ServerTimeOffReasonType[]
  myEmployeeGroupId: number
  closeDialog: () => void
  myPermission: string[]
}> = ({ employeeGroups, timeOffReasons, myEmployeeGroupId, closeDialog, myPermission }) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { mutateAsync: mutateTimeOffReasons } = useMutateTimeOffReasons()

  const { control, handleSubmit, formState } = useForm<TimeOffReasonFormValues>({
    defaultValues: {
      items: generateDefaultTimeOffReasonFormValues(timeOffReasons),
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
    keyName: 'generatedId',
  })

  const onAdd = () => {
    append({
      name: '',
      employeeGroups: [],
      editable: true,
      updatable: false,
    })
  }

  const areEditTimeOffReasonEmployeeGroupsEnabled = myPermission.includes(
    permissions.rolePermission.set,
  )

  const mutate: SubmitHandler<TimeOffReasonFormValues> = async items => {
    const result = await mutateTimeOffReasons(
      generateTimeOffReasonsDataForMutation(items, areEditTimeOffReasonEmployeeGroupsEnabled),
    )

    let updatedInsertedQty =
      (result.deleteTimeOffReasonEmployeeGroups?.affectedRows || 0) +
      (result.insertTimeOffReasonEmployeeGroups?.affectedRows || 0) +
      (result.insertTimeOffReasons.affectedRows || 0)

    if (Array.isArray(result.updateManyTimeOffReasons))
      updatedInsertedQty += result.updateManyTimeOffReasons.reduce(
        (acc, cur) => acc + (cur.affectedRows || 0),
        0,
      )
    else updatedInsertedQty += result.updateManyTimeOffReasons.affectedRows || 0

    if (updatedInsertedQty > 0) {
      openSnackbar(t('updateSuccess'), 'success', 3000)
    } else {
      openSnackbar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  return (
    <>
      <Dialog.Title>
        <div className="flex items-center justify-between">
          {t('timeOffReason.name')}
          <button
            onKeyDown={stopEnterPropagation}
            onClick={onAdd}
            className="mr-2 text-primary-500 hover:text-primary-700 hover:dark:text-primary-600 rounded-full focus:ring-1 ring-primary-500"
          >
            <IoAddCircleOutline size="1.4rem" />
          </button>
        </div>
      </Dialog.Title>

      <Dialog.Body className="h-68">
        {fields?.length ? (
          <TableContainer className="max-h-64">
            <TableHeader>
              <tr>
                <TableHeaderCell className="w-100">
                  <div className="flex items-center justify-between">{t('name')}</div>
                </TableHeaderCell>
                <TableHeaderCell className="w-100">
                  <div className="flex items-center gap-2">
                    {t('employeeGroup.name')}
                    <Hint>{t('timeOffReason.employeeGroupsHint')}</Hint>
                  </div>
                </TableHeaderCell>
              </tr>
            </TableHeader>
            <TableBody>
              {fields.map((item, index) => (
                <TimeOffReasonRow
                  key={item.generatedId}
                  control={control}
                  index={index}
                  remove={remove}
                  employeeGroups={employeeGroups}
                  myEmployeeGroupId={myEmployeeGroupId}
                  areEditTimeOffReasonEmployeeGroupsEnabled={
                    areEditTimeOffReasonEmployeeGroupsEnabled
                  }
                />
              ))}
            </TableBody>
          </TableContainer>
        ) : (
          <div className="h-full w-200 flex items-center justify-center">
            <p className="text-lg text-secondary-color">{t('noItems')}</p>
          </div>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <SaveButton
          onClick={handleSubmit(mutate)}
          disabled={formState.isSubmitting || !formState.isDirty}
          spinner={formState.isSubmitting}
        />
        <CloseButton onClick={closeDialog} />
      </Dialog.Footer>
    </>
  )
}
