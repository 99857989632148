import { gql } from 'graphql-request'
import {
  queryClient,
  reportError,
  reportGqlError,
  request,
  requestAnonymously,
  useQuery,
} from '../../api'
import {
  ServerEmployeeBriefType,
  ServerEmployeeBriefWithBranchType,
  ServerEmployeeByIdWithServiceEmployeesType,
  ServerEmployeeExtendedType,
  ServerEmployeeType,
} from '../../generated/graphql-types'
import {
  ALL_EMPLOYEES_QUERY_KEY,
  ANONYMOUSLY_EMPLOYEES_QUERY_KEY,
  ARCHIVED_EMPLOYEES_QUERY_KEY,
  EMPLOYEE_BY_ID,
  EMPLOYEE_PERMISSIONS_QUERY_KEY,
  EMPLOYEES_BRIEF_QUERY_KEY,
  EMPLOYEES_EXTENDED_QUERY_KEY,
  EMPLOYEES_FOR_CALENDAR_QUERY_KEY,
  EMPLOYEES_FOR_IMPORT,
  EMPLOYEES_QUERY_KEY,
  EMPLOYEES_WITH_OWNER_QUERY_KEY,
} from './queryKeys'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'
import { SCHEDULES_QUERY_KEY } from '../schedule/queryKeys'
import { TIME_OFFS_QUERY_KEY } from '../timeOff/queryKeys'
import {
  anonymouslyEmployeeFragment,
  employeeBriefFragment,
  employeeBriefWithBranchFragment,
  employeeByIdExtendedFragment,
  employeeByIdWithServiceEmployeesFragment,
  employeeExtendedFragment,
  employeeForMobileCalendarFragment,
  employeeFragment,
} from './employee.fragments'
import {
  EmployeeByIdExtended,
  EmployeeForCalendar,
  EmployeeTypeWithReviews,
  EmployeeWithSalarySettingsType,
  ExtendedEmployee,
  parseDatesInEmployeeGqlResponse,
} from './logic'
import { AS_CLIENT_QUERY_KEY } from '../client/queryKeys'
import { employeeInEmployeeGroupFragment } from '../employeeGroup/employeeGroup.fragments'
import { DEFAULT_TIMEZONE } from '@expane/date'

export function useFetchEmployeePermission(myEmployeeId: number | null) {
  return useQuery(
    [EMPLOYEE_PERMISSIONS_QUERY_KEY, myEmployeeId],
    async (): Promise<string[]> => {
      const result = await request(
        gql`
          query ($id: Int!) {
            employeeById(id: $id) {
              employeeGroup {
                roleId
              }
            }
          }
        `,
        { id: myEmployeeId },
      )

      if (!result?.employeeById) return []

      const employeePermissions = await request(
        gql`
          query ($role: String!) {
            rolePermission(where: { roleId: { _eq: $role } }) {
              permissionId
            }
          }
        `,
        {
          role: result.employeeById?.employeeGroup?.roleId,
        },
      )

      if (Array.isArray(employeePermissions?.rolePermission))
        return employeePermissions.rolePermission.map(({ permissionId }) => permissionId)

      return []
    },
    {
      queryName: 'useFetchMyPermissions',
      enabled: Boolean(myEmployeeId),
      onError: reportGqlError,
      // Set the notifyOnChangeProps option to ['data', 'error'] to only re-render when the selected
      // data changes.
      notifyOnChangeProps: ['data', 'error'],
    },
  )
}

export function useFetchEmployeesByServices(
  serviceIds: Array<number> | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerEmployeeByIdWithServiceEmployeesType[] | undefined>(
    [EMPLOYEES_QUERY_KEY, { serviceIds, branchId }],
    async () => {
      const result = await request(
        gql`
          ${employeeByIdWithServiceEmployeesFragment}
          query ($serviceIds: [Int!]!, $branchId: Int!) {
            employees(
              where: {
                serviceEmployees: { serviceId: { _in: $serviceIds }, branchId: { _eq: $branchId } }
                archived: { _is_null: true }
              }
            ) {
              ...employeeByIdWithServiceEmployeesType
            }
          }
        `,
        { serviceIds, branchId },
      )

      if (Array.isArray(result?.employees)) return result.employees

      reportError(new Error('Error while trying to return employees by services'), 'error', {
        result,
      })
    },
    {
      queryName: 'useFetchEmployeesByServices',
      enabled: Boolean(serviceIds) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchEmployeeWithServiceEmployees(
  id: number | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerEmployeeByIdWithServiceEmployeesType | undefined>(
    [EMPLOYEES_QUERY_KEY, EMPLOYEE_BY_ID, AS_CLIENT_QUERY_KEY, { id, branchId }],
    async (): Promise<ServerEmployeeByIdWithServiceEmployeesType | undefined> => {
      if (id === undefined || id === 0) return undefined
      const dto = { id, branchId }
      let result

      try {
        result = await request(
          gql`
            ${employeeByIdWithServiceEmployeesFragment}
            query ($id: Int!, $branchId: Int!) {
              employeeById(id: $id) {
                ...employeeByIdWithServiceEmployeesType
              }
            }
          `,
          dto,
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (result?.employeeById) {
        return result.employeeById
      } else {
        reportError(new Error('error in useFetchEmployeeByIdWithServiceEmployees'), 'warning', dto)
        return undefined
      }
    },
    {
      queryName: 'useFetchEmployeeWithServiceEmployees',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(branchId),
    },
  )
}

export function useFetchEmployeeById(id: number | undefined | null, timezone: string | undefined) {
  return useQuery<ServerEmployeeType | undefined>(
    [EMPLOYEES_QUERY_KEY, EMPLOYEE_BY_ID, id],
    async (): Promise<ServerEmployeeType | undefined> => {
      if (id === undefined || id === 0) return undefined
      const dto = { id }
      let result

      try {
        result = await request(
          gql`
            ${employeeFragment}
            query ($id: Int!) {
              employeeById(id: $id) {
                ...employeeType
              }
            }
          `,
          dto,
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (result?.employeeById) {
        return parseDatesInEmployeeGqlResponse(result.employeeById, timezone ?? DEFAULT_TIMEZONE)
      } else {
        reportError(new Error('error in useFetchEmployeeById'), 'warning', { id })
        return undefined
      }
    },
    {
      queryName: 'useFetchEmployeeById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}

export function useFetchEmployeeByIdExtended(
  id: number | undefined | null,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [
      EMPLOYEES_QUERY_KEY,
      EMPLOYEES_EXTENDED_QUERY_KEY,
      SCHEDULES_QUERY_KEY,
      SERVICES_QUERY_KEY,
      { id, branchId },
    ],
    async (): Promise<EmployeeByIdExtended | undefined> => {
      const dto = { id, branchId }
      const result = await request(
        gql`
          ${employeeByIdExtendedFragment}
          query ($id: Int!, $branchId: Int!) {
            employeeById(id: $id) {
              ...employeeByIdExtendedType
            }
          }
        `,
        dto,
      )

      if (result?.employeeById) {
        return parseDatesInEmployeeGqlResponse(result?.employeeById, timezone ?? DEFAULT_TIMEZONE)
      } else {
        reportError(new Error('employeeById does not exist'), 'warning', { dto, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchEmployeeById',
      enabled: Boolean(id) && Boolean(timezone) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchEmployeesByServiceId(
  serviceId: number | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [EMPLOYEES_QUERY_KEY, { serviceId, branchId }],
    async (): Promise<ServerEmployeeType[]> => {
      const result = await request(
        gql`
          ${employeeFragment}
          query ($serviceId: Int!, $branchId: Int!) {
            employees(
              order_by: { lastName: asc }
              where: {
                _and: {
                  serviceEmployees: { serviceId: { _eq: $serviceId }, branchId: { _eq: $branchId } }
                  archived: { _is_null: true }
                  employeeSchedules: { branchId: { _eq: $branchId } }
                }
              }
            ) {
              ...employeeType
            }
          }
        `,
        { serviceId, branchId },
      )

      if (Array.isArray(result?.employees)) {
        return result.employees
      } else {
        reportError(new Error('error in useFetchEmployees'), 'warning', { serviceId, result })
        return []
      }
    },
    {
      queryName: 'useFetchEmployeesByServiceId',
      onError: reportGqlError,
      enabled: Boolean(serviceId),
    },
  )
}

export function useFetchEmployees(
  timezone: string | undefined,
  branchId: number | undefined,
  enabled = true,
) {
  return useQuery(
    [EMPLOYEES_QUERY_KEY, { branchId }],
    async (): Promise<ServerEmployeeType[]> => {
      let result

      try {
        result = await request(
          gql`
            ${employeeFragment}
            query ($branchId: Int!) {
              employees(
                order_by: { lastName: asc }
                where: {
                  archived: { _is_null: true }
                  employeeSchedules: { branchId: { _eq: $branchId } }
                }
              ) {
                ...employeeType
              }
            }
          `,
          { branchId },
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (Array.isArray(result?.employees)) {
        return result.employees.map(employee =>
          parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('employees is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: enabled && Boolean(timezone) && Boolean(branchId),
      queryName: 'useFetchEmployees',
      onError: reportGqlError,
    },
  )
}

export interface ServerEmployeeBriefWithPhonesType extends ServerEmployeeBriefType {
  phone: string
}

export function useFetchAllBranchesEmployeesBriefsWithPhones() {
  return useQuery<ServerEmployeeBriefWithPhonesType[] | undefined>(
    [EMPLOYEES_QUERY_KEY, EMPLOYEES_BRIEF_QUERY_KEY],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${employeeBriefFragment}
            query {
              employees(where: { archived: { _is_null: true } }) {
                ...employeeBriefType
                phone
              }
            }
          `,
        )
      } catch (error) {
        reportGqlError(error)
      }

      if (result.employees) {
        return result.employees
      }

      reportError(new Error('error while trying to fetch employees briefs'), 'error', { result })
    },
    { queryName: 'useFetchAllBranchesEmployeesBriefs' },
  )
}

export function useFetchAllEmployeesForImport(branchId: number | undefined) {
  return useQuery<ServerEmployeeBriefType[] | undefined>(
    [EMPLOYEES_QUERY_KEY, EMPLOYEES_BRIEF_QUERY_KEY, EMPLOYEES_FOR_IMPORT, { branchId }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${employeeBriefFragment}
            query ($branchId: Int!) {
              employees(
                where: {
                  archived: { _is_null: true }
                  _not: {
                    _or: [
                      { ownBusiness: {} }
                      { employeeSchedules: { branchId: { _eq: $branchId } } }
                    ]
                  }
                }
              ) {
                ...employeeBriefType
              }
            }
          `,
          { branchId },
        )
      } catch (error) {
        reportGqlError(error)
      }

      if (result.employees) {
        return result.employees
      }

      reportError(new Error('error while trying to fetch employees briefs'), 'error', { result })
    },
    { queryName: 'useFetchAllEmployeesForImport', enabled: Boolean(branchId) },
  )
}

export function useFetchEmployeesBriefs(branchId: number | undefined) {
  return useQuery<ServerEmployeeBriefType[] | undefined>(
    [EMPLOYEES_QUERY_KEY, EMPLOYEES_BRIEF_QUERY_KEY, { branchId }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${employeeBriefFragment}
            query ($branchId: Int!) {
              employees(
                where: {
                  archived: { _is_null: true }
                  employeeSchedules: { branchId: { _eq: $branchId } }
                }
              ) {
                ...employeeBriefType
              }
            }
          `,
          { branchId },
        )
      } catch (error) {
        reportGqlError(error)
      }

      if (result.employees) {
        return result.employees
      }

      reportError(new Error('error while trying to fetch employees briefs'), 'error', { result })
    },
    { queryName: 'useFetchEmployeesBriefs', enabled: Boolean(branchId) },
  )
}

export function useFetchEmployeesBriefsWithReviews(branchId: number | undefined) {
  return useQuery<EmployeeTypeWithReviews[] | undefined>(
    [EMPLOYEES_QUERY_KEY, EMPLOYEES_BRIEF_QUERY_KEY, { branchId }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${anonymouslyEmployeeFragment}
            query ($branchId: Int!) {
              employees(
                where: {
                  archived: { _is_null: true }
                  employeeSchedules: { branchId: { _eq: $branchId } }
                }
              ) {
                ...anonymouslyEmployeeType
              }
            }
          `,
          { branchId },
        )
      } catch (error) {
        reportGqlError(error)
      }

      if (result.employees) {
        return result.employees
      }

      reportError(new Error('error while trying to fetch employees briefs'), 'error', { result })
    },
    { queryName: 'useFetchEmployeesBriefs', enabled: Boolean(branchId) },
  )
}

/**
 * Потрібно для завантаження всіх співробітників (для посилань онлайн-записів)
 */
export function useFetchAllEmployeesForURLSBriefs() {
  return useQuery<ServerEmployeeBriefWithBranchType[] | undefined>(
    [EMPLOYEES_QUERY_KEY, ALL_EMPLOYEES_QUERY_KEY, EMPLOYEES_BRIEF_QUERY_KEY],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${employeeBriefWithBranchFragment}
            query {
              employees(
                where: {
                  archived: { _is_null: true }
                  serviceEmployees: { service: { availableForClient: { _eq: true } } }
                }
              ) {
                ...employeeBriefWithBranchType
              }
            }
          `,
        )
      } catch (error) {
        reportGqlError(error)
      }

      if (result.employees) {
        return result.employees
      }

      reportError(new Error('error while trying to fetch all employees briefs'), 'error', {
        result,
      })
    },
    { queryName: 'useFetchAllEmployeesBriefs' },
  )
}

export function useFetchEmployeesForCalendar(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [EMPLOYEES_QUERY_KEY, EMPLOYEES_FOR_CALENDAR_QUERY_KEY, { branchId }],
    async (): Promise<EmployeeForCalendar[]> => {
      const result = await request(
        gql`
          ${employeeForMobileCalendarFragment}
          query ($branchId: Int!) {
            employees(
              where: {
                archived: { _is_null: true }
                employeeSchedules: { branchId: { _eq: $branchId } }
              }
            ) {
              ...employeeForMobileCalendarType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employees)) {
        return result.employees.map(employee =>
          parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('employeesForCalendar is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchEmployeesForCalendar',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchExtendedEmployees(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [
      EMPLOYEES_QUERY_KEY,
      EMPLOYEES_EXTENDED_QUERY_KEY,
      SERVICES_QUERY_KEY,
      SCHEDULES_QUERY_KEY,
      TIME_OFFS_QUERY_KEY,
      { branchId },
    ],
    async (): Promise<ExtendedEmployee[]> => {
      const result = await request(
        gql`
          ${employeeExtendedFragment}
          query ($branchId: Int!) {
            employees(
              order_by: { lastName: asc }
              where: {
                archived: { _is_null: true }
                employeeSchedules: { branchId: { _eq: $branchId } }
              }
            ) {
              ...employeeExtendedType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employees)) {
        return result.employees.map(employee =>
          parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('employees is not an array'), 'warning', { result })
        return []
      }
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(
          [EMPLOYEES_QUERY_KEY],
          data.map(({ id, firstName, middleName, lastName, phone, photo, groupId, email }) => ({
            id,
            firstName,
            middleName,
            lastName,
            phone,
            photo,
            groupId,
            email,
          })),
        )
      },
      queryName: 'useFetchExtendedEmployees',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchExtendedEmployeesWithOwner(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [
      EMPLOYEES_QUERY_KEY,
      EMPLOYEES_EXTENDED_QUERY_KEY,
      SERVICES_QUERY_KEY,
      SCHEDULES_QUERY_KEY,
      TIME_OFFS_QUERY_KEY,
      EMPLOYEES_WITH_OWNER_QUERY_KEY,
      { branchId },
    ],
    async (): Promise<ExtendedEmployee[]> => {
      const result = await request(
        gql`
          ${employeeExtendedFragment}
          query ($branchId: Int!) {
            employees(
              order_by: { lastName: asc }
              where: {
                archived: { _is_null: true }
                _or: [{ employeeSchedules: { branchId: { _eq: $branchId } } }, { ownBusiness: {} }]
              }
            ) {
              ...employeeExtendedType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employees)) {
        return result.employees.map(employee =>
          parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('employees is not an array'), 'warning', { result })
        return []
      }
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(
          [EMPLOYEES_QUERY_KEY],
          data.map(({ id, firstName, middleName, lastName, phone, photo, groupId, email }) => ({
            id,
            firstName,
            middleName,
            lastName,
            phone,
            photo,
            groupId,
            email,
          })),
        )
      },
      queryName: 'useFetchExtendedEmployees',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchArchivedEmployees(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [
      EMPLOYEES_QUERY_KEY,
      ARCHIVED_EMPLOYEES_QUERY_KEY,
      SCHEDULES_QUERY_KEY,
      SERVICES_QUERY_KEY,
      { branchId },
    ],

    async (): Promise<ServerEmployeeExtendedType[]> => {
      const result = await request(
        gql`
          ${employeeExtendedFragment}
          query ($branchId: Int!) {
            employees(
              order_by: { lastName: asc }
              where: {
                archived: { _is_null: false }
                employeeSchedules: { branchId: { _eq: $branchId } }
              }
            ) {
              ...employeeExtendedType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employees)) {
        return result.employees.map(employee =>
          parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
        )
      }
      reportError(new Error('employees is not an array'), 'warning', { result })
      return []
    },
    {
      queryName: 'useFetchArchivedEmployees',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchEmployeesWithSalarySettings(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [
      EMPLOYEES_QUERY_KEY,
      SERVICES_QUERY_KEY,
      SCHEDULES_QUERY_KEY,
      TIME_OFFS_QUERY_KEY,
      { branchId },
    ],
    async (): Promise<EmployeeWithSalarySettingsType[]> => {
      const result = await request(
        gql`
          ${employeeInEmployeeGroupFragment}
          query ($branchId: Int!) {
            employees(
              order_by: { lastName: asc }
              where: {
                archived: { _is_null: true }
                employeeSchedules: { branchId: { _eq: $branchId } }
              }
            ) {
              ...employeeInEmployeeGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.employees)) {
        return result.employees.map(employee =>
          parseDatesInEmployeeGqlResponse(employee, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('employees with salary settings is not an array'), 'warning', {
          result,
        })
        return []
      }
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(
          [EMPLOYEES_QUERY_KEY],
          data.map(({ id, firstName, lastName }) => ({
            id,
            firstName,
            lastName,
          })),
        )
      },
      queryName: 'useFetchEmployeesWithSalarySettings',
      onError: reportGqlError,
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchAnonymouslyEmployeesByBranch(branchId: number | undefined | null) {
  return useQuery(
    [ANONYMOUSLY_EMPLOYEES_QUERY_KEY, { branchId }],
    async () => {
      const result = await requestAnonymously<{ employees: EmployeeTypeWithReviews[] }>(
        gql`
          ${anonymouslyEmployeeFragment}
          query ($branchId: Int!) {
            employees(
              where: {
                serviceEmployees: { branchId: { _eq: $branchId } }
                employeeSchedules: { branchId: { _eq: $branchId } }
              }
            ) {
              ...anonymouslyEmployeeType
            }
          }
        `,
        { branchId },
      )

      return result.employees
    },
    {
      queryName: 'useFetchAnonymouslyEmployeesByBranch',
      enabled: Boolean(branchId),
      onError: reportGqlError,
    },
  )
}
