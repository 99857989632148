import { Paper } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const ChangelogPage: FC = () => {
  const { t } = useTranslation()

  return (
    <Paper className="mx-4 my-6 px-9 py-9 text-main-color">
      <h1 className="text-2xl">{t('changeLog')}</h1>
      <h2 className="text-lg mt-5">[0.82.0] 2024-12-19</h2>
      <p>- Виправлена помилка продажу на сумму 0</p>
      <p>- Додано нову логіку листа очікування</p>
      <p>- Додана можливість створювати записи з повідомлення листа очікування</p>

      <h2 className="text-lg mt-5">[0.80.1] 2024-11-21</h2>
      <p>- Додано доступ на перегляд email, та покращена взаємодія з email клієнту</p>

      <h2 className="text-lg mt-5">[0.80.0] 2024-10-07</h2>
      <p>- Додано діалог інструкцій налаштувань лендінгу та пояснення для посилань на лендінгу</p>
      <p>- Додана окрема вкладка налаштувань створення записів клієнтами</p>
      <p>- Додано розʼяснення до налаштувань онлайн записів</p>
      <p>
        - Додана можливість налаштовувати повідомлення для клієнтів після успішного створення запису
      </p>
      <p>
        - Додане відображення коментарів клієнтів в діалозі запису, в сповіщеннях, календарі та
        діалозі клієнта
      </p>
      <p>- Додали можливість оплати на 6міс/рік з бонусами в виді додаткових місяців</p>
      <p>- Додали врахування промо коду на знижку при оплаті</p>
      <p>- Покращено UI/UX сторінки оплати за застосунок</p>

      <h2 className="text-lg mt-5">[0.79.0] 2024-08-14</h2>
      <p>- Додано можливість підтверджувати та архівувати відгуки клієнтів</p>
      <p>- Виправлені помилки та покращено UI/UX</p>

      <h2 className="text-lg mt-5">[0.78.0] 2024-07-11</h2>
      <p>
        - Додано можливість додати адрес та телефон філії при створенні бізнесу або при створенні
        або редагуванні філії
      </p>
      <p>- Додано можливість додати запит на видалення аккаунту</p>

      <h2 className="text-lg mt-5">[0.77.0] 2024-05-06</h2>
      <p>- Додана можливість під'єднати інтеграцію Checkbox</p>
      <p>- Додані повторювальні записи та повторювальні групові записи та взаємодія з ними</p>
      <p>- Покращено UI/UX створення шаблонів розсилок нагадування про записи</p>
      <p>- Додано обмеження на завантаження файлів (до 10 МБ) та покращено UX</p>
      <p>- Додана можливість ввести промокод при створенні бізнесу</p>

      <h2 className="text-lg mt-5">[0.76.0] 2024-04-01</h2>
      <p>
        - Додано інтеграцію Checkbox. Авторизація РРО, відкриття та закриття зміни. Швидкий продаж.
      </p>
      <p>
        - Покращено UI/UX - можливість розширяти блок інформації в фінансах та списків на сторінках
        послуг, співробітників та товарів.
      </p>
      <p>- Додано можливість видаляти робочі дні динамічного графіку з сторінки графіку.</p>
      <p>- Виправлені помилки та покращено UI/UX</p>

      <h2 className="text-lg mt-5">[0.75.0] 2024-02-26</h2>
      <p>- Додано налаштування модулів бізнесу</p>
      <p>- Додана можливість створювати та архівувати масові відсутності</p>
      <p>- Додано обмеження на співробітників згідно з планом</p>

      <h2 className="text-lg mt-5">[0.74.0] 2024-02-02</h2>
      <p>- Додано вибір дати початку графіку співробітника при створенні</p>
      <p>- Виправлені помилки та покращено UI/UX</p>

      <h2 className="text-lg mt-5">[0.73.0] 2024-01-22</h2>
      <p>- Додана можливість додати працівника Expane у будь-який бізнес</p>
      <p>- Кнопки авторизації Apple та Google переробили згідно стандарту</p>
      <p>
        - Покращено логіку взаємодії з сертифікатами (неможливо активувати якщо термін дії сплив,
        неможливо активувати якщо він був повернений)
      </p>
      <p>
        - Додано перевірка при зміні закінчення абонемента - не можна змінити на менше ніж термін
        дії
      </p>

      <h2 className="text-lg mt-5">[0.72.0] 2024-01-08</h2>
      <p>- Виправлені помилки та покращено UI/UX</p>

      <h2 className="text-lg mt-5">[0.71.0] 2023-12-26</h2>
      <p>- Виправили валідацію email в Safari (в деяких версіях)</p>

      <h2 className="text-lg mt-5">[0.70.0] 2023-12-18</h2>
      <p>- Покращена валідація емейлу</p>
      <p>- Додано можливість редагувати/архівувати коментарі клієнта</p>

      <h2 className="text-lg mt-5">[0.69.0] 2023-11-29</h2>
      <p>- Виправлено помилку налаштовування ЗП з 1-го місяця для співробітника</p>

      <h2 className="text-lg mt-5">[0.68.0] 2023-11-08</h2>
      <p>- Додана можливість архівувати локації</p>
      <p>- Додана можливість авторизуватися через Google та Apple</p>

      <h2 className="text-lg mt-5">[0.67.0] 2023-10-23</h2>
      <p>- Виправлено помилку при авторизації</p>

      <h2 className="text-lg mt-5">[0.66.0] 2023-10-17</h2>
      <p>- Виправлено баг з селектом «Хто може продавати послугу»</p>
      <p>- Подовжено пробний період до 2х місяців</p>

      <h2 className="text-lg mt-5">[0.65.0] - 2023-10-09</h2>
      <p> - Покращена швидкість роботи додатку</p>

      <h2 className="text-lg mt-5">[0.64.0] - 2023-09-25</h2>
      <p> - Видалено крок верифікації телефону при створені бізнесу</p>

      <h2 className="text-lg mt-5">[0.63.0] - 2023-09-18</h2>
      <p> - Переробрено діалог причин відсутності (таблиця з можливістю редагування, додавання)</p>
      <p> - Додана можливість подовжити план підписки</p>

      <h2 className="text-lg mt-5">[0.62.0] - 2023-09-04</h2>
      <p> - Додано розподіл співробітників, локації і послуг по філіалам</p>
      <p> - Покращено імпорт товарів</p>
      <p> - Додана можливість імпортувати співробітників</p>
      <p> - Додано можливість імпорту локацій</p>
      <p> - Додано можливість імпорту послуг</p>
      <p> - Додано розподіл переміщень по філіям</p>
      <p> - Додано розподіл сертифікатів і абонементів по філіям</p>
      <p> - Додано розподіл листа очікування по філіям</p>
      <p> - Додано розподіл зарплат по філіям</p>
      <p> - Додана фільтрація клієнтів по філіям</p>
      <p> - Додана перевірка на існуючого клієнта при створенні (може бути в іншому філіалі)</p>
      <p> - Додано розподіл по філіям інвентарізації та контрагентів</p>
      <p> - Додано розподіл по філіям доходів/витрат</p>
      <p> - Перероблено діалоги причин доходу і витрат</p>
      <p>
        {' '}
        - Додано швидке створення співробітника в діалог послуг та швидке створення послуги в діалог
        співробітника
      </p>
      <p> - Покращено UI/UX створення бізнесу</p>
      <p> - Додано діалог вибору типу створення бізнесу</p>
      <p> - Покращено UI/UX на сторінці режиму клієтну</p>
      <p> - Покращено налаштування бізнесу</p>
      <p> - Покращено поведінку блоку навігації по застосунку</p>
      <p> - Улучшен UX доступів (текст UI + ворнинг)</p>
      <p> - Додано попередження про не налаштовану зарплатню на сторінці співробітників</p>
      <p> - При налаштуванні графіка додано відображення сьогоднішнього дня</p>
      <p> - Покращено UI кнопок і попапів</p>
      <p> - Перенесено налаштування валюти в кожний окремий філіал</p>
      <p> - Додана можливість призупиняти графік роботи співробітників</p>
      <p> - Додана можливість обирати валюту, переключати тему при створені бізнесу</p>
      <p> - Додана можливість додавання премії або штрафу з діалогу співробітника</p>
      <p> - Працюємо над логікою загальних абонементів (для всіх філій)</p>
      <p> - Добавлена возможность создать межфилиальную услугу</p>
      <p> - Додано можливість редагування міжфіліальних послуг</p>
      <p> - Додано можливість створення міжфіліальних абонементів</p>
      <p> - Додано можливість додавати міжфіліальні послуги в записи</p>

      <h2 className="text-lg mt-5">[0.61.0] - 2023-05-22</h2>
      <p> - Додано попередження в груповому записі, якщо клієнт зайнятий</p>
      <p> - Покращено відображення стану завантаження</p>
      <p> - Відображення усіх блоків бізнесу за часовим поясом бізнесу</p>
      <p> - Додані налаштування телеграм бота в налаштування бізнесу</p>
      <p> - Додано налаштування сповіщень для співробітників</p>
      <p> - Додана можливість відправляти сповіщення співробітникам у Telegram і Viber</p>
      <p> - При створенні складських операцій додана можливість створення товарів</p>
      <p> - В складських операціях склад за замовчуванням відображається в списках першим</p>
      <p> - Додана перевірка графіку філії при створенні редагуванні графіків</p>

      <h2 className="text-lg mt-5">[0.60.0] - 2023-03-27</h2>
      <p> - Покращено користування графіками співробітників, зберігається історія змін</p>
      <p> - Додано попередження про незбережені дозволи</p>
      <p> - Додані відображення останніх пошукових запитів в швидкому пошуку</p>
      <p> - Додана темна тема</p>
      <p> - Покращен UI календаря</p>
      <p> - Покращен діалог повідомлень</p>

      <h2 className="text-lg mt-5">[0.59.0] - 2023-02-28</h2>
      <p> - Додана прив'язка до аккаунту wayForPay</p>
      <p> - Додана сторінка для меленьких єкранів</p>
      <p> - При створенні бізнесу додано вибір тарифного плану</p>
      <p> - Подращено UX/UI динамічного графіку</p>
      <p> - Додані лоадери при завантажуванні записів на сторінці записів</p>
      <p> - Покращена логіка повернення абонементів</p>
      <p> - Нове відображення графіків співробітників</p>
      <p> - Видалена можливість назначати співробітникам роль "Власник"</p>
      <p> - Додана можливість додати співробітнику робочий день зі сторінки календаря</p>
      <p> - Додана можливість давати окремий доступ на номер телефону клієнтів</p>
      <p> - Додані кольорові теми</p>

      <h2 className="text-lg mt-5">[0.58.0] - 2023-01-31</h2>
      <p> - Покращена сторінка доступів</p>
      <p> - Додана можливість додавати динамічний графік співробітникам</p>

      <h2 className="text-lg mt-5">[0.57.0] - 2022-12-27</h2>
      <p>- Додано налаштування дозволу скасування оплат</p>
      <p>- Додана можливість створення тега с діалогу кліенту</p>
      <p>- Додана можливість створнення росхідника з діалогу запису</p>

      <h2 className="text-lg mt-5">[0.56.0] - 2022-11-28</h2>
      <p>- Додана можливість подивись на мультисервісний запис на календарі під час створення</p>
      <p>- При створенні абонементу тепер відображається стандартна ціна послуги</p>

      <h2 className="text-lg mt-5">[0.55.1] - 2022-11-03</h2>
      <p>- Виправлена помилка при створенні мультисервісів</p>

      <h2 className="text-lg mt-5">[0.55.0] - 2022-11-01</h2>
      <p>- Додана можливість оплатити мультисервіс</p>
      <p>- Додана можливість змінювати статуси мультисервісів</p>
      <p>- Покращення інтерфейсу та виправлення помилок у віджеті онлайн оплати</p>
      <p>- Покращене предзаповнення данних при створенні групових записів</p>

      <h2 className="text-lg mt-5">[0.54.0] - 2022-10-17</h2>
      <p>- Додана можливість подивитись на код сертифікату вже після його покупки</p>
      <p>- Додана можливість групувати локації</p>
      <p>- Календар тепер має за замовчуванням вид "Співробітники"</p>
      <p>- Додано пошук товарів за артикулом</p>
      <p>- Додано контекстне меню до календаря</p>
      <p>- Виправлена мова листа авторизації</p>
      <p>- Додана можливість створювати мультипослуги</p>
      <p>- Додано налаштування дозволу на створювання записів через віджет</p>
      <p>- Додана можливість створювати записи через віджет</p>

      <h2 className="text-lg mt-5">[0.53.0] - 2022-09-26</h2>
      <p>- Додана можливість виділити всі повідомлення і зробити їх прочитаними</p>
      <p>- Додані нові параметри при створенні бізнесу</p>
      <p>- Додана можливість ввести телефон любої країни (додані телефонні коди країн)</p>
      <p>- Додана можливість вибрати часовий пояс при створенні бізнесу або в налаштуваннях</p>
      <p>- Додана можливість фільтрувати товар по артикулу</p>
      <p>- Додана можливість виставити довільний час в послузі</p>
      <p>- Додано можливість вибрати євро як валюту для бізнесу</p>
      <p>- Покращена взаємодія з календарем</p>

      <h2 className="text-lg mt-5">[0.52.0] - 2022-09-12</h2>
      <p>- Покращена логіка, пов'язана з заробітною платою, виправлені помилки</p>
      <p>- Додані сповіщення про підписку, що закінчується</p>

      <h2 className="text-lg mt-5">[0.51.0] - 2022-09-02</h2>
      <p>
        - Покращені налаштування бізнесу зв'язані з маніпуляціями клієнтами записами в мобільному
        застосунку
      </p>
      <p>- Додана можливість купляти тарифний план</p>
      <p>- Додані обмеження при закінченні тарифного плану</p>
      <p>- Додана сторінка зарплат (Нова логіка)</p>
      <p>- Додане налаштування зарплат (Нова логіка)</p>

      <h2 className="text-lg mt-5">[0.50.0] - 2022-08-01</h2>
      <p>- Додано обмеження на зміну співробітників та локацій для послуг</p>
      <p>- Поліпшено взаємодію з уже використовуваними абонементами/сертифікатами</p>
      <p>
        - У вибірці записів з'явилася можливість поставити статус виконано декільком записам
        одночасно
      </p>
      <p>- Поліпшено валідацію при створенні декількох записів одночасно</p>
      <p>- Додано обмеження на скасування групового запису, якщо є оплата від клієнта</p>
      <p>- Покращено обробку помилок у вибірці записів</p>

      <h2 className="text-lg mt-5">[0.49.1] - 2022-07-20</h2>
      <p>- Виправлена помилка з відкриттям діалогу групового запису</p>

      <h2 className="text-lg mt-5">[0.49.0] - 2022-07-15</h2>
      <p>- Додано обмеження на скасування оплати запису якщо статус «виконано»</p>
      <p>- Додано заборону на зміну статусу запису на «виконано» раніше ніж початок запису</p>
      <p>- Додана можливість оплатити запис та одразу змінити статус запису на «виконано»</p>
      <p>- Додано зміну статусу на «виконано» в груповому записі</p>
      <p>- Додана можливість вибору повернення розхідників</p>
      <p>- Додано оприбуткування на сторінці складу</p>
      <p>- Додано попередження про закінчення розхідників для послуг у швидкому продажу</p>
      <p>- Додано коментар до групового запису</p>
      <p>- Додана можливість створити мультизапис у груповому записі</p>
      <p>- Додані підказки до статусу запису</p>
      <p>- Поліпшено повідомлення про помилку</p>

      <h2 className="text-lg mt-5">[0.48.0] - 2022-07-04</h2>
      <p>- Добавлен тип услуги</p>
      <p>- Улучшена таблица с редактированием</p>
      <p>- Улучшено взаимодействие с ботами - при отписке меняются предпочтительные каналы связи</p>
      <p>- Добавлены настройки уведомлений</p>
      <p>- Добавлена в информации про сотрудника возможность форматирования(списки)</p>
      <p>- Новый тип перемещения - списание расходников</p>
      <p>- Поддержка номеров телефонов для Польши и Германии</p>

      <h2 className="text-lg mt-5">[0.47.0] - 2022-06-17</h2>
      <p>- Добавлен статус букинга "проведено"</p>
      <p>- Добавлено полное создание бизнеса (новые шаги)</p>
      <p>- Добавлены оповещения о создании/редактировании/отмене записей клиентам/сотрудникам</p>
      <p>- Название транзакций заменено на дополнительную информацию</p>
      <p>- В быстрой продажи добавлены ед. измерения в товарах</p>
      <p>- Добавлено разрешение на редактирование статуса букинга</p>
      <p>
        - Добавлено определение «проведено» в диалоге начислений зп + поправлена логика подсчета
      </p>
      <p>- В диалоге начислений зп добавлена возможность просмотреть транзакцию</p>
      <p>- Добавлена логика запрета изменения статуса товара «для продажи»</p>

      <h2 className="text-lg mt-5">[0.46.0] - 2022-06-03</h2>
      <p>- При рассылке сообщения учитывается язык клиента</p>
      <p>- Добавлена настройка используемых денежных единиц бизнеса</p>
      <p>- Добавлена настройка бизнеса: за сколько часов позволять отменять запись клиенту</p>
      <p>- Добавлена возможность выбрать короткий или полный путь создания бизнеса</p>

      <h2 className="text-lg mt-5">[0.45.0] - 2022-05-13</h2>
      <p>- Добавлены опциональные расходники в записи</p>
      <p>- Добавлена возможность выбирать, какие услуги доступны для клиента</p>
      <p>- Добавлены уведомления о созданных записях клиентом</p>
      <p>- Добавлена возможность убрать направляющего специалиста в настройках бизнеса</p>
      <p>- Добавлена возможность добавлять к сотрудникам описание</p>
      <p>- Улучшена работа списка ожидания</p>

      <h2 className="text-lg mt-5">[0.44.0] - 2022-05-06</h2>
      <p>- Добавлена возможность сделать приход товара который еще не на складе</p>
      <p>- Добавлена возможность выплатить задолженность сотруднику</p>
      <p>- Добавлена возможность ввести количество услуг при быстрой продаже</p>
      <p>- В диалоге инвентаризации отображается список связанных списаний/оприходований</p>
      <p>- Возможность прятать транзакции пополнения счёта клиента</p>
      <p>- Добавлены оповещения при освобождении списков ожидания</p>
      <p>- Подправлен перевод</p>

      <h2 className="text-lg mt-5">[0.43.0] - 2022-04-22</h2>
      <p>- Добавлен перевод приложения на украинский и английский язык</p>
      <p>- Добавлено округление зарплат до 50</p>
      <p>- Добавлена возможность редактировать документ инвентаризации (количество)</p>
      <p>- Добавлены отчеты по входящим и исходящим платежам</p>
      <p>- Добавлена возможность менять язык приложения на этапе создания бизнеса</p>
      <p>- Улучшена настройка вида календаря в сжатом виде</p>
      <p>- Добавлена валидация в групповой букинг при удалении клиента который уже оплатил</p>
      <p>- Добавлена возможность отмены записи листа ожидания</p>
      <p>- Добавлена возможность перехода на диалог графика с диалога сотрудника</p>

      <h2 className="text-lg mt-5">[0.42.0] - 2022-04-08</h2>
      <p>- Добавлен направляющий специалист в документе и диалоге клиента</p>
      <p>- Добавлена возможность настроить реферальный процент сотруднику</p>
      <p>- Добавлена возможность выплатить реферальный процент</p>
      <p>- Добавлена интеграция с Бинотел и возможность открыть диалог клиента при звонке</p>
      <p>- Добавлен перевод главного меню и вкладки информации в диалоге клиента</p>

      <h2 className="text-lg mt-5">[0.41.0] - 2022-02-23</h2>
      <p>- Добавлена возможность сохранить дополнительный номер телефона клиента</p>
      <p>- Добавлена возможность фильтровать записи по локациям</p>
      <p>
        - Улучшен внешний вид календаря (добавлено разграничение между локациями, добавлена тень
        карточкам)
      </p>
      <p>- Улучшен процесс назначения зарплат для групп сотрудников</p>
      <p>- Улучшен выбор времени при создании записи</p>
      <p>- Отображается локация, в которую попадают услуги для быстрой продажи</p>

      <h2 className="text-lg mt-5">[0.40.0] - 2022-02-11</h2>
      <p>- В диалоге записи добавлено отображения склада для расходников.</p>
      <p>- Улучшен диалог выборки записей.</p>
      <p>- Добавлено отображение сотрудника в комментариях клиента, даже если он архивный.</p>
      <p>- В историю клиента добавлены групповые записи.</p>
      <p>- Добавлена возможность редактировать время при создании нескольких записей.</p>

      <h2 className="text-lg mt-5">[0.39.0] - 2022-02-04</h2>
      <p>- Добавлена возможность копирования товара</p>
      <p>
        - В диалогах "положить на счет клиента" и "снять с счета клиента" на странице "Финансов"
        добавлена возможность искать клиента по номеру телефона
      </p>
      <p>- На странице "Финансов" добавлена возможность сбросить все фильтры</p>
      <p>
        В настройке зарплат сотруднику добавлена возможность применить формулу и сумму начисления
        заработной платы ко всем услугам
      </p>
      <p>
        - На странице "Список записей" добавилась возможность фильтровать записи по оплате, кнопка
        сбросить все фильтры, так же можно открыть запись из таблицы
      </p>
      <p>
        - При выборе услуги при создании записи добавилась возможность выбрать быстро услугу на
        подсказку которой был осуществлен клик
      </p>
      <p>- Улучшен выбор дат в приложении</p>
      <p>
        - При приходе товара добавлена возможность ввести общую сумму позиции и рассчитать цену
        закупки исходя из нее
      </p>

      <h2 className="text-lg mt-5">[0.38.0] - 2022-01-28</h2>
      <p>- Добавлено уведомление с кодом сертификата при продаже</p>
      <p>- Улучшена страница контрагенты</p>
      <p>- Добавлен блок с чеками в настройки</p>
      <p>- Добавлена возможность оправлять уведомления через viber </p>
      <p>- Улучшена отправка уведомлений клиенту</p>
      <p>- Добавлено уведомление об окончании срока действия документа клиента</p>
      <p>- Добавлена авторизация в checkbox</p>
      <p>- Добавлено закрытие контракта сотрудника при его архивации</p>
      <p>- Добавлена возможность частично выдать ставку сотрудника</p>

      <h2 className="text-lg mt-5">[0.37.0] - 2022-01-21</h2>
      <p>- Комиссия со счетов взымается только при начислении на этот счёт</p>
      <p>- Актуальные остатки при изменении даты в инвентаризации</p>
      <p>- Поставщики были переименованы в контрагентов</p>
      <p>- Добавлена страница с взаиморасчётами с контрагентами</p>
      <p>- В отчет финансового оборота добавлены остатки на счетах клиентов</p>
      <p>- Вложенность групп товаров была ограничена до 3 уровней</p>
      <p>- Добавлена возможность оплатить несколько накладных сразу</p>
      <p>- Возможность просмотреть остатки товаров по всем складам</p>
      <p>- Добавлено отображение ставки в диалоге начисления зарплаты</p>
      <p>- Улучшена логика подсчёта невыплаченной зарплаты</p>
      <p>- Добавлена возможность распечатать таблицу с остатками на складе</p>
      <p>
        - Сотрудники с отгулами на весь день и без записей не будут отображаться в календаре по
        сотрудникам
      </p>
      <p>- Исправлены ошибки, повышена стабильность приложения</p>

      <h2 className="text-lg mt-5">[0.36.0] - 2022-01-14</h2>
      <p>- Улучшен подсчет выплаченной зп и отображение общей суммы к оплате</p>
      <p>- Добавлен автор создания отгулов и улучшен внешний вид</p>
      <p>- Добавлено отображение скидки для позиций при приходе товара</p>
      <p>- Добавлена возможность неполной оплаты поставщику</p>
      <p>- Улучшена навигация по выпадающим меню</p>
      <p>- Добавлена себестоимость расходников в диалоге услуг</p>
      <p>- Улучшено отображений транзакций оплат по записи</p>

      <h2 className="text-lg mt-5">[0.35.0] - 2021-12-31</h2>
      <p>- Добавлена возможность настроить зп группам сотрудников</p>
      <p>- Добавлена сумма сертификата</p>
      <p>- Добавлена возможность создать клиента при создании групповой записи</p>
      <p>- Улучшена проверка при изменениях услуг</p>
      <p>- Улучшено создание нескольких записей</p>
      <p>- Улучшено отображение календаря при наведении</p>
      <p>- Добавлена вкладка с документами клиента в диалоге клиента</p>
      <p>- Добавлены интерактивные подсказки в диалоге записи</p>
      <p>- Улучшен блок склада</p>

      <h2 className="text-lg mt-5">[0.34.0] - 2021-12-17</h2>
      <p>- Добавлена страница список записей</p>
      <p>- Добавлена возможность создать несколько записей</p>
      <p>- Добавлен список транзакций по записи клиента</p>
      <p>- Улучшено состояние приложении если сервер не доступен</p>
      <p>- Улучшена карточка записи в календаре</p>
      <p>- Улучшена архивация по проекту</p>

      <h2 className="text-lg mt-5">[0.33.0] - 2021-12-10</h2>
      <p>- Добавлена возможность быстрого добавления отгула в календаре</p>
      <p>- Добавлена возможность быстрой продажи услуг без привязки к локации</p>
      <p>- Более подробная информация о записях и транзакциях клиента</p>
      <p>- Добавлена возможность создавать свои причины отгулов</p>
      <p>- Добавлена возможность указывать статус сотрудника</p>
      <p>- При возврате абонемента добавлен выбор возврата средств на счёт или на руки</p>

      <h2 className="text-lg mt-5">[0.32.0] - 2021-12-03</h2>
      <p>- Улучшена страница с перемещения товаров</p>
      <p>- Добавлен предпочтительный канал связи для клиента</p>
      <p>- Добавлен шаблон сообщения рассылки для настройки бизнеса</p>
      <p>- Добавлена история уведомлений клиента</p>
      <p>
        - Добавлено предупреждение при создании клиента если уже есть клиент с таким номером
        телефона
      </p>
      <p>- Добавлены быстрые клавиши для масштабирования календаря</p>
      <p>- Улучшен календарь (сжатом виде расширение столбцов, добавлено время в столбики)</p>

      <h2 className="text-lg mt-5">[0.31.0] - 2021-11-22</h2>
      <p>- Добавлена возможность выдавать ЗП нескольким сотрудникам</p>
      <p>- Добавлена возможность выдавать ЗП с диалога сотрудника</p>
      <p>- Добавлена настройка предпочтительных каналов связи для клиента</p>
      <p>- Добавлено автоматическое оповещение клиентов о предстоящих записях в telegram</p>

      <h2 className="text-lg mt-5">[0.30.0] - 2021-11-12</h2>
      <p>- Добавлена возможность масштабировать размер календаря</p>
      <p>- Добавлена возможность активировать абонемент с диалога клиента</p>
      <p>- Улучшены возможности фильтрации в выборке записей</p>
      <p>- Расширен функционал шаблонов фильтров</p>
      <p>- Добавлена возможность ввести дату в "выборе даты"</p>
      <p>- Добавлена возможность отменить запись когда она оплачена</p>
      <p>- Добавлена возможность создать поставщика при создании прихода товара</p>
      <p>- Улучшена страница зарплат</p>
      <p>- В отчет финансового расхода добавлено списание товара</p>
      <p>
        - При создании клиента добавлена возможность сразу положить деньги на счет или продать
        что-либо
      </p>

      <h2 className="text-lg mt-5">[0.29.0] - 2021-10-22</h2>
      <p>- Добавлены пресеты фильтров календаря</p>
      <p>- Добавлена возможность создать и сразу оплатить запись</p>
      <p>- Улучшен внешний вид диалога записей</p>
      <p>- Добавлены сертификаты в блок позиций для продажи диалога записей</p>
      <p>- Добавлена возможность изменить количество товаров в диалоге записей</p>
      <p>- Добавлена возможность перехода на все записи клиента с диалога конкретной записи</p>
      <p>- Добавлена возможность оплаты нескольких записей</p>
      <p>- Добавлено сообщение о последнем посещении в диалоге записи и диалоге клиента</p>
      <p>
        - Добавлена возможность положить деньги на счет клиента и продать ему позиции с диалога
        клиента
      </p>
      <p>- Добавлена возможность вернуть, продлить, переоформить абонемент клиента</p>
      <p>- Добавлены новые роли доступа: "директора/помощника директора"</p>
      <p>- Улучшено отображение истории записей клиента в диалоге клиента</p>
      <p>- Добавлена возможность создать транзакцию возврата с диалога записей клиента</p>
      <p>- Добавлена вкладка опции в диалоге клиента</p>
      <p>- Добавлена возможность вернуть активированный абонемент</p>

      <h2 className="text-lg mt-5">[0.28.0] - 2021-10-08</h2>
      <p>- Добавлена навигация клавиатурой на странице Сотрудников, Товаров, Услуг</p>
      <p>- Добавлено предупреждение при создании записи если не хвататет расходников</p>
      <p>- Добавлена возможность выбора типа скидки при оплате(процент или фиксированная сумма)</p>
      <p>- В транзакциях в кассе за день отображаются скидки</p>

      <h2 className="text-lg mt-5">[0.27.0] - 2021-10-01</h2>
      <p>- Добавлена отправка сообщения в телеграмм</p>
      <p>- Добавлена возможность отменить гупповую запись</p>
      <p>- Добавлена возможность выбрать склад по умолчанию</p>
      <p>- Добавлена возможность изменения цены товара при приходе и при возврате поставщику</p>

      <h2 className="text-lg mt-5">[0.26.0] - 2021-09-24</h2>
      <p>- Улучшена работа выдачи зарплаты</p>
      <p>- При оплате записи учитываются только что внесённые изменения</p>

      <h2 className="text-lg mt-5">[0.25.0] - 2021-09-17</h2>
      <p>- В единицы измерения были добавлены миллилитры</p>
      <p>- Добавлена возможность указывать отношения между клиентами</p>
      <p>- Добавлена возможность оплачивать групповые занятия</p>
      <p>- Добавлены подарочные карты</p>
      <p>- Услуги разделяются на групповые и негрупповые</p>
      <p>- Добавлена возможность указывать дату рождения клиента при быстром создании</p>
      <p>- Добавлен расчёт задолженности поставщику</p>
      <p>- Улучшена навигация по накладным</p>
      <p>- Добавлен отчёт по продажам сертификатов</p>

      <h2 className="text-lg mt-5">[0.24.0] - 2021-09-10</h2>
      <p>- Добавлена фильтрация сотрудников при выборе локации в календаре</p>
      <p>- Добавлено отображение занятых клеток при нескольких локациях</p>
      <p>- Добавлена возможность перемещения букинга по горизонтали в календаре</p>
      <p>- В кассе за день добавлен фильтр по типу и по позициям</p>
      <p>- Добавлена возможность в счетах выставить автоматическое снятия со счета</p>
      <p>- Добавлен счетчик номера накладных</p>
      <p>- Добавлена возможность распечатать список клиентов</p>
      <p>- Добавлен фильтр по потенциальным клиентам</p>

      <h2 className="text-lg mt-5">[0.23.0] - 2021-09-03</h2>
      <p>- Добавлена возможность архивации услуг, категорий товаров, категорий сотрудников</p>
      <p>- Накладные можно оплачивать после создания</p>
      <p>- В записях можно продавать абонементы</p>
      <p>- В диалоге записи улучшена информация о клиенте</p>
      <p>- Добавлена возможность создавать групповые записи</p>
      <p>- Улучшена информация о накладных</p>
      <p>- Добавлена возможность отменить несколько записей за раз</p>
      <p>- Расширены возможности быстрого поиска</p>
      <p>- Добавлены новые отчёты</p>
      <p>- Улучшена работа списка ожидания</p>
      <p>- Добавлен фильтр клиентов по вовлечённости</p>
      <p>- Добавлена возможность распечатать документ инвентаризации</p>

      <h2 className="text-lg mt-5">[0.22.0] - 2021-08-27</h2>
      <p> - Добавлена проверка расходников для услуги в букинге</p>
      <p> - Добавлен доступ к группам товаров, группам сотрудников</p>
      <p> - Добавлен доступ к складам группам сотрудников</p>

      <h2 className="text-lg mt-5">[0.21.0] - 2021-08-20</h2>
      <p>
        - Добавлена возможность перехода в диалог клиента и выбор склада для товаров в диалоге
        записи
      </p>
      <p> - Добавлены единицы измерения и изображения к товару</p>
      <p> - Добавлен отчет о посещениях клиентов</p>
      <p> - Добавлено ограничение по проведению возвратов по транзакции</p>
      <p> - Добавлен цвет для услуги и выбор количества товара для услуг</p>
      <p>
        - Добавлен выбор склада для продажи и отображение критического количества товаров в диалоге
        быстрой продажи
      </p>
      <p> - Добавлен фильтр по источнику привлечения на странице клиентов</p>
      <p> - Изменены страницы услуг, сотрудников, продуктов</p>
      <p>
        - Добавлена страница с инвентаризациями, добавлено списание и оприходование по
        инвентаризации
      </p>
      <p> - Добавлен диалог с подробной информацией в списке накладных</p>

      <h2 className="text-lg mt-5">[0.20.0] - 2021-08-13</h2>
      <p>- Добавлена возможность создавать источники привлечений</p>
      <p>- Добавлен параметр постоянного или внутреннего использования счета</p>
      <p>- Добавлено поле критическое значение товару</p>
      <p>- Добавлена информация об остатках товаров на складах </p>
      <p>- Добавлены фильтры в списке накладных</p>
      <p>- Добавлено списание товаров, перемещение между складами</p>
      <p>- Добавлена инвентаризация</p>
      <p>- Добавлена возможность продажи товаров и добавления в запись</p>
      <p>
        - Добавлено уведомление почему сотрудник в диалоге оплаты не может сделать скидку больше чем
        допустимо для его категории
      </p>
      <p>- В отчете продаж услуг добавлено отображение услуг,которые приобретены в кредит</p>
      <p>- Добавлен отчет продаж абонементов и клиентов по источнику привлечений</p>
      <p>- Добавлен блок товаров на страницу аналитики</p>
      <p>- Добавлен расчет себестоимости в услуге в зависимости от расходников</p>
      <p>- Улучшен диалог возвратов по транзакциям</p>
      <p>- На странице кассы за день добавлен фильтр транзакций по клиентам</p>

      <h2 className="text-lg mt-5">[0.19.0] - 2021-08-06</h2>
      <p>- Добавлен диалог поставщика</p>
      <p>- Добавлен диалог прихода товара</p>
      <p>- Расширена информаци о клиенте в диалоге записи</p>
      <p>- Добавлена проверка максимальной скидки</p>
      <p>- Добавлены товары в диалог букинга</p>
      <p>- Добавлен список накладных по поставщикам</p>
      <p>- Добавлен список записей по клиенту</p>

      <h2 className="text-lg mt-5">[0.18.0] - 2021-07-30</h2>
      <p>- Добавлена страница отчётов</p>
      <p>- Добавлены склады, товары и категории товаров</p>
      <p>- Улучшена логика оплаты</p>
      <p>- На странице кассы отображаются транзакции с использованием абонемента</p>

      <h2 className="text-lg mt-5">[0.17.0] - 2021-07-23</h2>
      <p>- Добавлена возможность выдачи зарплаты за определенный период</p>
      <p>- Добавлена возможность добавить скидку на услугу в диалоге оплаты</p>
      <p>- В диалоге оплаты при активном абонементе услуга списывается с абонемента</p>
      <p>- В диалоге клиента улучшена вкладка абонементов</p>
      <p>- Добавлен дашбоард на странице аналитика</p>
      <p>- Добавлены "причины" в диалоге затрат</p>

      <h2 className="text-lg mt-5">[0.16.0] - 2021-07-16</h2>
      <p>
        - Добавлена страница зарплат с возможностью выдать премию, зарплату или оштрафовать
        сотрудника
      </p>
      <p>- Добавлен подсчет доходов, расходов, прибыли за день на странице кассы</p>
      <p>- Добавлена история покупок клиентов</p>
      <p>- Добавлена история реализации услуг сотрудником</p>
      <p>- Добавлена возможность оплаты счетом клиента или оплатить на несколько счетов</p>

      <h2 className="text-lg mt-5">[0.15.0] - 2021-07-09</h2>
      <p>- Добавлена страница "Права доступа"</p>
      <p>- Изменен диалог оплаты записи</p>
      <p>- При оплате записи можно убирать услуги</p>
      <p>- Добавлен диалог "Внесение дохода"</p>
      <p>- Изменена таблица транзакций на странице "Касса за день"</p>
      <p>- Добавлен диалог "Создание транзакции возврата"</p>
      <p>- Добавлен диалог "Быстрая продажа"</p>
      <p>- Добавлен диалог "Транзакция"</p>
      <p>- На странице "Расписание" добавлена возможность выбора категорий сотрудников</p>
      <p>- Добавлена коммиссия для счетов</p>
      <p>- Добавлен диалог "Внесение затрат"</p>
      <p>- Изменен подчет цены абонемента</p>

      <h2 className="text-lg mt-5">[0.14.0] - 2021-07-02</h2>
      <p>- Добавлены категории услуг</p>
      <p>- Изменён внешний вид страницы сотрудников</p>
      <p>- В календаре отображаются только те сотрудники, которые работают в выбранный день</p>
      <p>- Добавлено поле себестоимость в услугах</p>
      <p>- Добавлена возможность добавить несколько услуг в одну запись</p>
      <p>- Добавлена вкладка "Абонемент" и История посещений в диалоге клиентов</p>
      <p>- Добавлен диалог оплаты записи</p>
      <p>- Добавлена возможность при создании записи можно искать по номеру телефона</p>
      <p>- Добавлена страница Касса</p>
      <p>- Добавлена возможность архивировать сотрудника</p>
      <p>- Добавлена возможность постоянно отображать панель выбора даты в календаре</p>
      <p>- Добавлена возможность архивировать запись и фиксировать причину отмены</p>
      <p>- Добавлен Лист Ожидания</p>

      <h2 className="text-lg mt-5">[0.13.0] - 2021-06-19</h2>
      <p>- Изменен UI настроек бизнеса</p>
      <p>- Добавлено отображение отгулов в календаре</p>
      <p>- Улучшения внешнего вида выбора даты</p>
      <p>- Добавлена страница счета</p>
      <p>- Улучшения внешнего вида селектов</p>
      <p>- Улучшения внешнего вида карточек записей</p>
      <p>- Добавлен диалог добавления/редактирования счета</p>
      <p>- Добавлены кнопка удаления к фото</p>
      <p>- Добавлена вкладка зарплата в диалог сотрудника</p>
      <p>- Добавлено отображение иконки в источник в диалоге клиента</p>
      <p>- В диалог сотрудника добавлено поле email</p>

      <h2 className="text-lg mt-5">[0.12.0] - 2021-06-11</h2>
      <p>- Улучшен дизайн выпадающего меню</p>
      <p>- Улучшен дизайн отображения и добавления комментариев во вкладке клиентов</p>
      <p>- Календарь отображает временной промежуток рабочего времени филиала</p>
      <p>- Добавлена страница с сообщением если на сервере неполадки</p>
      <p>
        - В календаре сотрудников отображаются только те сотрудники которые предоставляют услугу
      </p>
      <p>- Улучшен дизайн во вкладке графики работы сотрудников</p>
      <p>- Добавлен быстрый поиск по подкатегориям меню</p>
      <p>- Добавлена возможность переключения между бизнесами</p>
      <p>- Добавлено поле услуги в диалог сотрудника</p>
      <p>- Добавлена возможность удалять фотографию клиенту/сотруднику</p>
      <p>- Добавлен архив услуг</p>
      <p>- Улучшена навигация по годам при выборе даты</p>
      <p>- Добавлена возможность изменять вид карточки записи</p>

      <h2 className="text-lg mt-5">[0.11.0] - 2021-06-04</h2>
      <p>- Улучшен scrollbar</p>
      <p>- Добавлена страница абонементов</p>
      <p>- Увеличена высота ячеек календаря</p>
      <p>- Изменено отображение не рабочего времени в календаре</p>
      <p>- При скролле столбец времени и тайтл в календаре остаются фиксированными</p>
      <p>- Улучшено расположение записей (если одна запись) в календаре</p>
      <p>- Изменен формат отображения номеров телефона</p>
      <p>
        - Добавлена иконка к номеру телефону и возможность переключиться на связанный андроид
        телефон
      </p>
      <p>- Добавлен компонент для выбора Тегов</p>
      <p>- Добавлен аватар пользователя вместо бургер меню</p>
      <p>- Календарь разделен на дата и время</p>

      <h2 className="text-lg mt-5">[0.10.0] - 2021-05-28</h2>
      <p>- Улучшено отображение карточек записей на календаре</p>
      <p>- Можно создавать/обновлять записи не смотря на ограничения</p>
      <p>- В диалоге услуги добавлено поле кто может её продавать</p>
      <p>- Нельзя редактировать прошедшие записи</p>
      <p>- При наведении на серую клетку календаря можно увидеть причину</p>
      <p>- Улучшен внешний вид главного меню</p>
      <p>- Улучшена навигация по календарю</p>
      <p>- Появилась возможность добавлять теги клиентам</p>
      <p>- Изменён вид страницы авторизации</p>

      <h2 className="text-lg mt-5">[0.9.0] - 2021-05-21</h2>
      <p>- Визуальные улучшения календаря: размер календаря и стиль карточек</p>
      <p>
        - Улучшен функционал календаря - отображение занятых часов во вкладке сотрудник если локация
        занята
      </p>
      <p>
        - Расширен функционал блока услуг. Добавление изображения, возможность копировать и
        архивировать услугу
      </p>
      <p>- Добавлен статус букингу и возможность его менять</p>
      <p>- Добавлена возможность оставлять комментарии к букингу</p>
      <p>- Добавлены новые поля во всплывающей подсказке букинга</p>
      <p>- Добавлена возможность добавлять и просматривать комментарии у клиентов</p>
      <p>- Добавлен поле “источник” для клиентов</p>
      <p>- Добавлена возможность добавлять и менять отгулы у сотрудника</p>
      <p>- Добавлены категории сотрудников и возможность добавления категории к сотруднику.</p>
      <p>- Изменен вид меню, вкладка “Сотрудники” перенесена в бургер меню</p>
      <p>- Добавлена страница 404</p>

      <h2 className="text-lg mt-5">[0.8.0] - 2021-05-15</h2>
      <p>- Добавлена возможность быстрого поиска по телефонам и клиентам.</p>
      <p>- Добавлена возможность выбора кода телефона и его проверка на валидность</p>
      <p>
        - Улучшен визуальный вид кнопки фильтров и добавлена возможность пользоваться фильтром
        клавиатурой
      </p>
      <p>- Добавлена возможность архивировать клиента</p>
      <p>- Добавлена информация о первом и последнем посещении клиента во вкладке клиента</p>
      <p>- Улучшен вид карточки полной информации букинга</p>

      <h2 className="text-lg mt-5">[0.7.0] - 2021-05-08</h2>
      <p>- Добавлен для всех вкладок календаря и улучшен таймлайн</p>
      <p>- В каледарь "сотрудник" добавлено отображение графика работы сотрудника</p>
      <p>- Полностью рабочий фильтр по сотрудникам</p>
      <p>- На странице создания бизнеса добавлены шаги с привязкой к номеру телефона</p>
      <p>- Изменен вид главного меню</p>
      <p>- Для клиентов добавлена в историю дата первого и последнего посещения</p>
      <p>- Закрытие диалогов по нажатию Esc</p>
      <p>- Улучшен QuickfindInput, поиск по страницам и сотрудникам</p>
      <p>
        - Для сотрудников добавлена вкладна график с выбором шаблона графика и визуальным
        отображением
      </p>
      <p>- Шорткат для QuickfindInput</p>
      <p>- Добавлен график работы в настройках филиала (только недельный)</p>
      <p>- На страницу "Аналитика" добавлен график по количеству клиентов</p>

      <h2 className="text-lg mt-5">[0.6.0] - 2021-05-02</h2>
      <p>- Во вкладке календаря "Сотрудник" сегодняшний столбик выделяется зелёным цветом</p>
      <p>- Во вкладке календаря "Сотрудник" расписание зависит от выбраного сотрудника</p>
      <p>- Новые фильтры по сотрудникам и локациям в календаре</p>
      <p>- Визуальные улучшения карточки записи</p>
      <p>- Добавлена страница филиалов</p>
      <p>- Визуальные улучшения слайдера</p>
      <p>- Добавлена страница добавления/редактирования графиков</p>
      <p>- Добавлены плейсхолдеры для медленного соединения</p>
      <p>- Визуальные улучшения страницы настроек</p>

      <h2 className="text-lg mt-5">[0.5.0] - 2021-04-23</h2>
      <p>
        - Добавлена возможность поиска по ключевому слову по всему приложению + keyboard shortcut
      </p>
      <p>- В рассписании (по локациям) добавлена возможность фильтра по сотрудникам</p>
      <p>- В страницах настроек добавлен заглавие</p>
      <p>- Добавлена возможность редактировать бизнес в странице настроек</p>
      <p>- Добавлено визуальное приобразование номера телефона</p>
      <p>- Добавлен слайдер во время масштабирования/уменьшения фотографии</p>
      <p>- Добавлен редирект если ссылка для авторизации устарела</p>

      <h2 className="text-lg mt-5">[0.4.0] - 2021-04-16</h2>
      <p>- Была убрана возможность создавать записи перетягиванием</p>
      <p>- Время по умолчанию теперь зависит от выбранной услуги</p>
      <p>- Записи теперь можно перетаскивать по вертикали, меняя этим время начала записи</p>
      <p>- Добавлена возможность менять начало и конец рабочего дня для дней недели</p>
      <p>- В поле выбора времени начала записи нельзя выбрать время вне рабочего времени</p>
      <p>
        - Прошедшие записи теперь визуально отличаются от тех, которым только предстоит произойти
      </p>
      <p>- Переход на аутентификацию без пароля</p>
      <p>- Улучшен вид создания формы нового клиента</p>
      <p>
        - В расписании в сотрудниках отображаются только те сотрудники которые относятся к услуге
      </p>
      <p>- Создан прогресс бар</p>
      <p>- Улучшен визуальный вид дейтпикера при фокусе</p>
      <p>- Авторизация возможна со страницы создания бизнеса</p>
      <p>- Добавлена возможность создавать новый бизнес</p>
      <p>- Улучшено отображение времени в календаре</p>
      <p>- Добавлена возможность загрузки фото (клиенты/сотрудники)</p>
      <p>- Добавлено поле с датой дня рождения (клиенты/сотрудники)</p>
      <p>- Создание бизнеса происходит в 5 шагов (роуты)</p>
      <p>- Добавлена карта для определения месторасположения бизнеса</p>
      <p>- Добавлен переключатель языка интерфейса</p>
      <p>- Улушено поле для ввода телефона</p>
      <p>- Добавлена анимация для попап, меню, селектов + улучшение стилей лейаута</p>
      <p>- Добавлена логика создания записей</p>

      <h2 className="text-lg mt-5">[0.3.0] - 2021-04-05</h2>
      <p>- Визуальные улучшения форм</p>
      <p>- Реализован полностью функционал обновления услуг</p>
      <p>
        - В форме услуг добавлена возможность выбора длительности по умолчанию только из выбранных
        длительностей
      </p>
      <p>- К выбранным полям форм (расписания, локаций, услуг) добавлены подсказки</p>
      <p>- Добавлены указатели обязательных полей</p>
      <p>- Добавлена проверка полей на пустые поля</p>
      <p>- Добавлены новые поля и изменены стили в форме создания клиента</p>
      <p>- В расписании улучшена визуальная реализация формы если услуга с сотрудником или без</p>
      <p>- Реализована возможность залогиниться через "Enter"</p>
      <p>- Визуальные улучшения в форме выбора даты</p>

      <h2 className="text-lg mt-5">[0.2.0] - 2021-03-29</h2>
      <p>- Расширен функционал редактирования и отображении услуг</p>
      <p>- Расширен функционал редактирования и отображении локаций</p>
      <p>- Добавлены 3 режима в расписании - по локациям, по специалистам, специалист неделя</p>
      <p>- Визуальные улучшения и исправления в расписании</p>
      <p>- Улучшены модальные диалоги подтверждения</p>

      <h2 className="text-lg mt-5">[0.1.1] - 2021-03-23</h2>
      <p>- Первый релиз 🎉🎉🎉</p>
    </Paper>
  )
}
