import {
  useArchiveProduct,
  useFetchDeactivatedProductGroups,
  useFetchProductById,
  useGetLeftovers,
  useResetProductProductGroup,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { convertUnitValueFromServer } from '@expane/logic/product'
import { findById, translateItemsName } from '@expane/logic/utils'
import {
  Input,
  OpenButton,
  RadioGroupButtons,
  useShowConfirmationPopup,
  useShowWarningPopup,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/api'
import { store } from 'store'
import { Avatar } from 'ui/Avatar'
import { ArchiveButton, RestoreButton } from 'widgets/Buttons'
import {
  productTypeOptions,
  transformServerForSaleToProductTypeOption,
} from 'widgets/ProductDialog/logic'

interface ProductQuickInfoProps {
  onClick: () => void
  productId: number | undefined
}

export const ProductQuickInfo: FC<ProductQuickInfoProps> = observer(({ productId, onClick }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: productById } = useFetchProductById(productId, branchId)

  const { mutateAsync: archiveProduct } = useArchiveProduct()

  const { data: productLeftovers } = useGetLeftovers(branchId)

  const { data: deactivatedProductGroups } = useFetchDeactivatedProductGroups(branchId)
  const { mutateAsync: resetProductProductGroup } = useResetProductProductGroup()

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('product.archiveWarning'),
  )

  const mutateArchiveProduct = async (archived: boolean) => {
    if (productById) await archiveProduct({ id: productById.id, archived })
  }

  const handleArchiveProduct = () => {
    if (!productById) return

    const leftover = (productLeftovers ?? {})[productById.id]

    if (leftover) showWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const { archiveConfirmationModal, showArchiveConfirmationPopup } =
    useShowArchiveConfirmationPopup(productById?.name ?? '', () => mutateArchiveProduct(true))

  const handleOnRestore = async () => {
    const groupId = productById?.groupId

    const isGroupDeactivated = Boolean(groupId && findById(groupId, deactivatedProductGroups))

    if (isGroupDeactivated)
      showConfirmation({
        title: t('restore.name'),
        description: t('product.restoreWarning'),
        onConfirm: async () => {
          if (productById?.id) await resetProductProductGroup(productById.id)
          await mutateArchiveProduct(false)
        },
      })
    else {
      await mutateArchiveProduct(false)
    }
  }

  const isArchivingAllowed = myPermissions?.includes(permissions.product.archive)

  if (!productById) return null

  return (
    <div className="py-5 px-2">
      <div className="flex-centered">
        <Avatar
          url={productById?.photo ?? ''}
          name={productById?.name ?? ''}
          type="big"
          reportErrorHandlerFunction={reportError}
        />
      </div>

      <div>
        <Input
          label={t('title')}
          containerClassName="mb-2"
          value={productById?.name ?? ''}
          disabled
        />

        <div className="flex">
          <div>
            <Input
              label={t('vendorCode')}
              value={productById?.vendorCode?.toString() ?? ''}
              disabled
            />
            <Input
              label={t('criticalQuantity.name')}
              containerClassName="mt-2"
              value={
                productById
                  ? convertUnitValueFromServer(
                      productById.criticalQuantity,
                      productById.unit,
                    ).toString()
                  : ''
              }
              disabled
            />
          </div>
          <div className="ml-2">
            <Input
              label={t('costPrice')}
              value={productById?.costPrice.toString() ?? ''}
              disabled
            />
            <Input
              label={t('price')}
              containerClassName="mt-2"
              value={productById?.price.toString() ?? ''}
              disabled
            />
          </div>
        </div>

        <RadioGroupButtons
          className="mt-2"
          label={t('productType.name')}
          options={translateItemsName(productTypeOptions, t)}
          onChange={() => {
            return
          }}
          value={transformServerForSaleToProductTypeOption(productById?.forSale)}
          disabled
        />
        <div
          className={`flex ${
            productById?.archived || !isArchivingAllowed ? 'justify-end' : 'justify-between'
          } mt-5`}
        >
          {isArchivingAllowed &&
            (productById?.archived ? (
              <RestoreButton onClick={handleOnRestore} />
            ) : (
              <ArchiveButton className="mr-auto" onClick={handleArchiveProduct} />
            ))}

          {productById?.archived ? null : <OpenButton onClick={onClick} />}
        </div>
        {archiveConfirmationModal}
        {confirmationModal}
        {warningModal}
      </div>
    </div>
  )
})
