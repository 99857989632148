import { safeWebLocalStorage } from '@expane/web-logic/safeWebLocalStorage'
import { reportError } from 'services/api'

const MOVEMENT_PRESET_VERSION = 'v1'
const MOVEMENT_PRESETS = 'movementPresets'

const getLocalStorageKey = (branchId: number) =>
  `${MOVEMENT_PRESET_VERSION}/${MOVEMENT_PRESETS}/${branchId}`

export const getMovementPresetsFromLocalStorage = (branchId: number): MovementPreset[] | null => {
  const localStorageKey = getLocalStorageKey(branchId)

  const presets = safeWebLocalStorage.getItem(localStorageKey)
  if (presets == null) return null

  let parsedPresets
  try {
    parsedPresets = JSON.parse(presets)
  } catch (error) {
    reportError(error as Error, 'error', { presets, parsedPresets, branchId })
  }

  if (
    Array.isArray(parsedPresets) &&
    parsedPresets.every(preset => {
      // check that preset is an object
      if (typeof preset !== 'object' || Array.isArray(preset) || preset === null) return false
      if (!preset.id || !preset.name) return false

      return true
    })
  )
    return parsedPresets

  safeWebLocalStorage.removeItem(localStorageKey)
  return null
}

export const setNewMovementPresetsToLocalStorage = (
  newPresets: MovementPreset[],
  branchId: number,
) => {
  const localStorageKey = getLocalStorageKey(branchId)
  safeWebLocalStorage.setItem(localStorageKey, JSON.stringify(newPresets))
}

export type MovementPreset = {
  id: number
  name: string
  typeId: number | null
  supplierId: number | null
  fromStorageId: number | null
  toStorageId: number | null
  products: { id: number; isFolder: boolean }[]
  statusId: number | null
}
