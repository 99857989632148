import { GraphQLClient, Variables } from 'graphql-request'
import {
  QueryClient,
  QueryClientProvider,
  QueryFunction,
  QueryKey,
  useMutation,
  useQuery as useQueryOriginal,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import {
  CustomSamplingContext,
  Hub,
  SeverityLevel,
  Transaction,
  TransactionContext,
} from '@sentry/types'

export { useMutation, useQuery as useQueryOriginal, useInfiniteQuery } from '@tanstack/react-query'

const MINUTE_IN_MS = 60 * 1000
const HOUR_IN_MS = 60 * MINUTE_IN_MS
export const DEFAULT_STALE_TIME = 4 * MINUTE_IN_MS // 4 minutes
export const LONG_STALE_TIME = HOUR_IN_MS // 1 hour
export const SHORT_STALE_TIME = MINUTE_IN_MS // 1 minute

interface InitParams {
  endpoint: string
  queryClient: QueryClient
  reportError: (e: unknown, level: SeverityLevel, extra?: Record<string, unknown>) => void
  reportGqlError: (e: unknown, variables?: unknown) => void
  getAuthToken?: (forceRefresh?: boolean) => Promise<string | undefined>
  sentry?: {
    env: string
    getCurrentHub: () => Hub
    startTransaction: (
      context: TransactionContext,
      customSamplingContext?: CustomSamplingContext | undefined,
    ) => Transaction
  }
}

export let queryClient: QueryClient

export let useQuery: <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'> & {
    queryName: string
  },
) => UseQueryResult<TData, TError>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let request: <T = any>(document: string, variables?: Variables) => Promise<T>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let requestAnonymously: <T = any>(document: string, variables?: Variables) => Promise<T>
export let updateAuthorizationToken: () => Promise<void>
export let reportError: (e: unknown, level: SeverityLevel, extra?: Record<string, unknown>) => void
export let reportGqlError: (e: unknown, variables?: unknown) => void

export const initGraphQLClient = (params: InitParams) => {
  queryClient = params.queryClient
  reportError = params.reportError
  reportGqlError = params.reportGqlError
  const client = new GraphQLClient(params.endpoint)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request = async <T = any>(document: string, variables?: Variables): Promise<T> => {
    const token = await params.getAuthToken?.()
    client.setHeader('Authorization', 'Bearer ' + token)
    return client.request(document, variables)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestAnonymously = async <T = any>(document: string, variables?: Variables): Promise<T> =>
    client.request(document, variables)

  updateAuthorizationToken = async () => {
    const token = await params.getAuthToken?.(true)
    client.setHeader('Authorization', 'Bearer ' + token)
  }

  useQuery = (queryKey, queryFn, options) => {
    const state = params.queryClient.getQueryState(queryKey)

    if (
      !params.sentry ||
      params.sentry.env === 'development' ||
      (state && state.status === 'success' && state.fetchStatus !== 'fetching')
    )
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useQueryOriginal(queryKey, queryFn, options)

    const transaction = params.sentry.startTransaction({
      op: 'useQuery',
      name: options.queryName,
      data: {
        queryKey,
      },
    })
    const span = transaction.startChild({ op: 'useQuery', description: options.queryName })
    params.sentry.getCurrentHub().configureScope(scope => scope.setSpan(span))

    const opts = {
      ...options,
      onError: err => {
        options.onError?.(err)
        transaction.setStatus('unknown_error').setHttpStatus(500)
        span.setStatus('unknown_error').setHttpStatus(500)
      },
      onSuccess: data => {
        options.onSuccess?.(data)
        transaction.setStatus('ok').setHttpStatus(200)
        span.setStatus('ok').setHttpStatus(200)
      },
      onSettled: (data, error) => {
        options.onSettled?.(data, error)
        span.finish()
        transaction.finish()
      },
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQueryOriginal(queryKey, queryFn, opts)
  }

  return {
    queryClient,
    request,
    requestAnonymously,
    updateAuthorizationToken,
    useMutation,
    useQuery,
    useQueryOriginal,
    QueryClientProvider,
  }
}
