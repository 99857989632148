import { FC } from 'react'
import {
  useFetchCurrentBranchTimezone,
  useFetchEmployeesGroups,
  useFetchTimeOffReasons,
} from '@expane/data'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { Dialog, Modal } from '@expane/ui'
import { store } from 'store'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { TimeOffReasonDialogLogic } from './TimeOffReasonDialogLogic'
import { TimeOffReasonDialogPlaceholder } from './TimeOffReasonDialogPlaceholder'

export const TimeOffReasonDialog: FC<DialogProps> = ({ closeDialog }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { data: employeeGroups, isLoading: isLoadingEmployeeGroups } = useFetchEmployeesGroups(
    timezone,
    branchId,
  )
  const { data: myEmployee, isLoading: isLoadingMyEmployee } = useFetchMyEmployee(
    timezone,
    branchId,
  )
  const { data: myPermission, isLoading: isLoadingMyPermission } = useFetchMyPermissions()
  const { data: timeOffReasons, isLoading: isLoadingTimeOffReasons } = useFetchTimeOffReasons()

  const isNoData = !timezone || !myEmployee || !employeeGroups || !timeOffReasons || !myPermission
  const isLoading =
    isLoadingEmployeeGroups ||
    isLoadingMyEmployee ||
    isLoadingTimeOffReasons ||
    isLoadingMyPermission

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={closeDialog}>
      <Dialog>
        {isLoading ? (
          <TimeOffReasonDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <TimeOffReasonDialogLogic
            closeDialog={closeDialog}
            employeeGroups={employeeGroups || []}
            timeOffReasons={timeOffReasons || []}
            myEmployeeGroupId={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myEmployee!.groupId!
            }
            myPermission={myPermission || []}
          />
        )}
      </Dialog>
    </Modal>
  )
}
