import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Modal,
  Paper,
  PlaceholderInput,
  PlaceholderTextarea,
} from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SaveButton } from 'widgets/Buttons'

export const SupplierDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <Modal animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>{t('supplier.name')}</Dialog.Title>

        <Dialog.Body className="w-192 min-h-120">
          <div className="flex mb-4">
            <div className="w-1/2 mr-3">
              <PlaceholderInput label={t('title')} className="mb-4" />
              <PlaceholderInput label={t('phone')} />
            </div>

            <div className="grow">
              <PlaceholderInput label={t('website')} className="mb-4" />
              <PlaceholderInput label={t('email')} />
            </div>
          </div>

          <Paper className="p-2 ">
            <div className="flex mb-4">
              <PlaceholderInput label={t('country')} className="w-1/3 mr-3" />
              <PlaceholderInput label={t('city')} className="w-1/3" />
            </div>

            <div className="flex mb-4">
              <PlaceholderInput label={t('postcode')} className="w-24 mr-2" width="small" />
              <PlaceholderInput label={t('street')} className="w-1/3 mr-2" />
              <PlaceholderInput label={t('house')} className="w-24 mr-2" width="small" />
              <PlaceholderInput label={t('office')} className="w-24" width="small" />
            </div>
          </Paper>

          <PlaceholderTextarea label={t('note')} className="w-1/2 mt-3" />
        </Dialog.Body>

        <Dialog.Footer>
          <SaveButton disabled />
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
